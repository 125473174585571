import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import TinderCard from 'react-tinder-card';

import { openModal } from '../actions/index';

import Button from '../../stories/components/Button/Button';
import Modal from '../../stories/components/Modal/Modal';

import '../../styles/css/Games/Tinder.css';

const contentText = [
  {
    text: 'Je sens que quelque chose de terrible va se passer',
    type: 'left',
  },
  {
    text: "J'ai peur",
    type: 'right',
  },
  {
    text: "J'ai l'impression que rien ne va jamais bien",
    type: 'left',
  },
  {
    text: 'Je me sens tellement coupable',
    type: 'right',
  },
  {
    text: "J'ai l'impression de m'effondrer",
    type: 'left',
  },
  {
    text: 'Je me sens très en colère',
    type: 'right',
  },
  {
    text: "J'ai l'impression que ma vie ne s'améliorera jamais",
    type: 'left',
  },
  {
    text: 'Je me sens seul',
    type: 'right',
  },
  {
    text: 'Je me sens paniqué',
    type: 'right',
  },
  {
    text: 'Je me sens tellement inutile',
    type: 'left',
  },
  {
    text: 'Je sens que je suis un fardeau',
    type: 'left',
  },
  {
    text: 'Je me sens honteux',
    type: 'right',
  },
  {
    text: "J'ai l'impression d'être déchiré",
    type: 'left',
  },
  {
    text: 'Je me sens lourd',
    type: 'right',
  },
  {
    text: 'Je me sens déprimée',
    type: 'right',
  },
  {
    text: 'Je sens que je suis une personne dégoûtante',
    type: 'left',
  },
];

const alreadyRemoved = [];
let wordsState = contentText;

const Tinder = ({
  checkGameAndChallenge,
  currentGame,
  modal,
  openModal,
  currentEmotion,
  buttonTextModal,
  actionModal,
  imageModal,
}) => {
  const [words, setWords] = useState(contentText);
  const [lastDirection, setLastDirection] = useState();
  const [numberError, setNumberError] = useState(0);
  const [numberGoodResponse, setNumberGoodResponse] = useState(0);
  const [points, setPoints] = useState(0);
  const [finish, setFinish] = useState(false);

  const childRefs = useMemo(
    () =>
      Array(contentText.length)
        .fill(0)
        .map((i) => React.createRef()),
    []
  );

  const swiped = (direction, word) => {
    if (word.type !== direction) {
      setNumberError(numberError + 1);
    } else {
      setNumberGoodResponse(numberGoodResponse + 1);
    }
    setLastDirection(direction);
    alreadyRemoved.push(word);
  };

  const outOfFrame = (direction, word) => {
    if (word.type !== direction) {
      setNumberError(numberError + 1);
    } else {
      setNumberGoodResponse(numberGoodResponse + 1);
    }
    wordsState = wordsState.filter((wordState) => wordState.text !== wordState);
    setWords(wordsState);
  };

  const swipe = (dir) => {
    const cardsLeft = words.filter(
      (person) => !alreadyRemoved.includes(person.text)
    );
    if (cardsLeft.length) {
      const toBeRemoved = cardsLeft[cardsLeft.length - 1].text; // Find the card object to be removed
      const index = contentText
        .map((person) => person.text)
        .indexOf(toBeRemoved); // Find the index of which to make the reference to
      alreadyRemoved.push(toBeRemoved); // Make sure the next card gets removed next time if this card do not have time to exit the screen
      childRefs[index].current.swipe(dir); // Swipe the card!
    }

    if (cardsLeft.length === 1) {
      setTimeout(() => {
        setFinish(true);
        checkGameAndChallenge();
      }, 1000);
    }
  };

  return (
    <div className="Tinder">
      {!finish && (
        <div>
          <div className="Tinder_header">
            <p>
              {numberError} Erreur{numberError > 1 ? 's' : ''}
            </p>
            <p>
              {numberGoodResponse} Bon{numberGoodResponse > 1 ? 's' : ''}
            </p>
          </div>
          <div className="cardContainer">
            {words.map((word, index) => (
              <TinderCard
                ref={childRefs[index]}
                className="swipe"
                key={word.text}
                onSwipe={(dir) => swiped(dir, word)}
                onCardLeftScreen={(dir) => outOfFrame(dir, word)}
              >
                <div className="card">
                  <h3>{word.text}</h3>
                </div>
              </TinderCard>
            ))}
          </div>
          <div className="buttons">
            <button onClick={() => swipe('left')}>Pensées</button>
            <button onClick={() => swipe('right')}>Sentiments</button>
          </div>
        </div>
      )}
      {modal.isOpen && (
        <Modal
          buttonText={buttonTextModal}
          actionModal={actionModal}
          image={imageModal}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentGame: state.games.currentGame,
    modal: state.modal,
    buttonTextModal: state.modal.buttonText,
    actionModal: state.modal.buttonAction,
    imageModal: state.modal.image,
  };
};

const mapDispatchProps = (dispatch) => {
  return {
    openModal: (data) => {
      dispatch(openModal(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Tinder);
