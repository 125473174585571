import React from 'react';

import NavBar from '../NavBar';
import ListPetals from './ListPetals';

import '../../styles/css/BoxPetals/BoxPetals.css';

const BoxPetals = () => {
  return (
    <div className="BoxPetals">
      <NavBar />
      <ListPetals />
    </div>
  );
};

export default BoxPetals;
