import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Parser from 'html-react-parser';

import { addResponsesGame, openModal, closeModal } from '../actions/index';
import Input from '../../stories/components/Input/Input';
import Button from '../../stories/components/Button/Button';
import Box from '../../stories/components/Content/Box';
import Modal from '../../stories/components/Modal/Modal';

import iconWin from '../../styles/images/modal-win.svg';

import '../../styles/css/Games/List.css';

const List = ({
  checkGameAndChallenge,
  currentGame,
  addResponsesGame,
  userId,
  currentEmotion,
  modal,
  openModal,
  buttonTextModal,
  actionModal,
  imageModal,
  closeModal,
}) => {
  const [responses, setResponses] = useState([
    {
      text: null,
    },
  ]);
  const [input, setInput] = useState('');
  const [canCreateTask, setCanCreateTask] = useState(false);
  const [title, setTitle] = useState('Listes des événenements');
  const history = useHistory();

  useEffect(() => {
    if (!currentEmotion) {
      history.goBack();
    } else {
      switch (currentEmotion.slug) {
        case 'joy':
          setTitle(
            'Listes des événements ou des choses que tu as faite qui te rends fier '
          );
          break;
        case 'anticipation':
          setTitle(
            'Listes d’objectifs ou d’aspirations – des choses que vous aimeriez réaliser  '
          );
          break;
        default:
          break;
      }
    }
  }, []);

  const handleResponse = (index) => {
    const newResponses = [...responses];
    newResponses[index].text = input;
    setResponses(newResponses);
    setInput(null);
    setCanCreateTask(!canCreateTask);
  };

  const handleCanCreateTask = () => {
    setCanCreateTask(!canCreateTask);
    setResponses([...responses, { text: null }]);
  };

  const handleList = () => {
    responses.map((response) =>
      axios
        .post(
          `${process.env.REACT_APP_URL_SERVER}/api/users/${userId}/games/${currentGame.id}/responses`,
          { text: response.text }
        )
        .then(() => checkGameAndChallenge())
    );
    addResponsesGame(responses);
  };

  const stopGame = () => {
    handleList();

    const textModal = Parser(currentGame.title_win);
    const textModalButton = 'Suivant';
    const type = 'win';
    const action = finishGame;
    const icon = iconWin;
    const descriptionModal = Parser(currentGame.text_win);

    const content = {
      icon,
      textModal,
      textModalButton,
      type,
      action,
      descriptionModal,
    };

    openModal(content);
  };

  const finishGame = () => {
    closeModal();
    checkGameAndChallenge();
  };

  return (
    <div className="List">
      <div className="List_form">
        <Box
          type="question"
          text={title}
          primary
          style={{ marginBottom: '50px' }}
        />
        {responses.map((response, index) => {
          return (
            <div className="List_input_add">
              <Input
                primary
                type="text"
                required
                onChange={(e) => setInput(e.target.value)}
              />
              {response.text === null && (
                <Button
                  label="+"
                  primary
                  onClick={() => handleResponse(index)}
                  style={{ margin: '10px' }}
                />
              )}
            </div>
          );
        })}
        {canCreateTask && (
          <Button
            label="Ajouter un événement"
            primary
            onClick={() => handleCanCreateTask()}
          />
        )}
        {modal.isOpen && (
          <Modal
            buttonText={buttonTextModal}
            actionModal={actionModal}
            image={imageModal}
          />
        )}
      </div>
      <Button label="Valider ma liste" primary onClick={() => stopGame()} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentGame: state.games.currentGame,
    userId: state.user.id,
    currentEmotion: state.emotions.currentEmotion,
    modal: state.modal,
    buttonTextModal: state.modal.buttonText,
    actionModal: state.modal.buttonAction,
    imageModal: state.modal.image,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addResponsesGame: (data) => {
      dispatch(addResponsesGame(data));
    },
    openModal: (data) => {
      dispatch(openModal(data));
    },
    closeModal: (data) => {
      dispatch(closeModal(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
