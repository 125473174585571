import React from 'react';
import joieLogo from '../../styles/images/joie2.svg';
import '../../styles/css/Auth/Intro.css';

const Intro = () => {
  return (
    <div className="Intro">
      <h1 className="Intro-title">Bienvenue sur Quokka</h1>
      <div className="Intro-bar"></div>
      <p className="Intro-description">
        La plateforme de jeu pédagogique dédiée aux personnes en situation de
        proche aidant
      </p>
      <img className="Intro-logo" src={joieLogo} alt="Logo Joie" />
    </div>
  );
};

export default Intro;
