import React from 'react';

import { createChatBotMessage } from 'react-chatbot-kit';
import BotAvatar from './BotAvatar';
import Todos from './Todos';
import Options from './Options';
import Quiz from './Quiz';
import QuestionSituations from './widget/QuestionSituations';
import QuestionTime from './widget/QuestionTime';
import StepAnger from './widget/StepAnger';
import StepPain from './widget/StepPain';
import StepGuilty from './widget/StepGuilty';
import QuestionLose from './widget/QuestionLose';
import ExplainLose from './widget/ExplainLose';
import QuestionDepression from './widget/QuestionDepression';
import QuestionLesson from './widget/QuestionLesson';
import QuestionAcceptation from './widget/QuestionAcceptation';

const botName = 'Super Bot';
const config = {
  botName: botName,
  initialMessages: [
    createChatBotMessage(
      `Bonjour, je m'appelle ${botName} nous allons travailler sur la notion de tristesse. Pour cela, parmi ces situations, laquelle te rend actuellement le plus triste ?`,
      {
        widget: 'questionSituations',
      }
    ),
  ],
  customComponents: {
    // Replaces the default header
    header: () => null,
    botAvatar: (props) => <BotAvatar {...props} />,
  },
  // Defines an object of custom styles if you want to override styles
  customStyles: {
    // Overrides the chat button styles
    chatButton: {
      backgroundColor: '#da2d64',
    },
  },
  state: {
    todos: [],
    responses: {},
  },
  widgets: [
    {
      widgetName: 'todos',
      widgetFunc: (props) => <Todos {...props} />,
      mapStateToProps: ['todos'],
    },
    {
      widgetName: 'options',
      widgetFunc: (props) => <Options {...props} />,
    },
    {
      widgetName: 'questionSituations',
      widgetFunc: (props) => <QuestionSituations {...props} />,
    },
    ,
    {
      widgetName: 'questionTime',
      widgetFunc: (props) => <QuestionTime {...props} />,
    },
    {
      widgetName: 'stepAnger',
      widgetFunc: (props) => <StepAnger {...props} />,
      mapStateToProps: ['responses'],
    },
    {
      widgetName: 'stepPain',
      widgetFunc: (props) => <StepPain {...props} />,
      mapStateToProps: ['responses'],
    },
    {
      widgetName: 'stepGuilty',
      widgetFunc: (props) => <StepGuilty {...props} />,
      mapStateToProps: ['responses'],
    },
    {
      widgetName: 'questionLose',
      widgetFunc: (props) => <QuestionLose {...props} />,
      mapStateToProps: ['responses'],
    },
    {
      widgetName: 'explainLose',
      widgetFunc: (props) => <ExplainLose {...props} />,
      mapStateToProps: ['responses'],
    },
    {
      widgetName: 'questionDepression',
      widgetFunc: (props) => <QuestionDepression {...props} />,
      mapStateToProps: ['responses'],
    },
    {
      widgetName: 'questionAcceptation',
      widgetFunc: (props) => <QuestionAcceptation {...props} />,
      mapStateToProps: ['responses'],
    },
    {
      widgetName: 'handleLesson',
      widgetFunc: (props) => <QuestionLesson {...props} />,
      mapStateToProps: ['responses'],
    },
    {
      widgetName: 'js',
      widgetFunc: (props) => <Quiz {...props} />,
      props: {
        questions: [
          {
            question: 'Question 1 ?',
            answer: 'Réponse 1',
            id: 1,
          },
          {
            question: 'Question 2?',
            answer: 'Réponse 2',
            id: 2,
          },
        ],
      },
    },
  ],
};

export default config;
