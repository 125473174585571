import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, NavLink } from 'react-router-dom';

import logoQuokka from '../styles/images/joie2.svg';

import '../styles/css/NavBar.css';
import logoChallengesActive from '../styles/images/logo-challenges-active.svg';
import logoToolsActive from '../styles/images/logo-tools-active.svg';
import logoAccountActive from '../styles/images/logo-account-active.svg';
import logoTools from '../styles/images/logo-tools.svg';
import logoAccount from '../styles/images/logo-account.svg';
import logoChallenges from '../styles/images/logo-challenges.svg';
import imageQuestion from '../styles/images/imageQuestion.svg';
import decoImage from '../styles/images/deco.svg';

const NavBar = () => {
  const history = useHistory();

  const handleDeconnexion = () => {
    localStorage.setItem('token', null);
    history.push('/');
  };

  return (
    <div className="NavBar">
      {/* <Button
        primary
        onClick={() => history.goBack()}
        label="Retour"
        size="small"
      /> */}
      <button
        className="NavBar_logo"
        type="button"
        onClick={() => history.push('/challenges')}
      >
        <img src={logoQuokka} alt="logo quokka" />
      </button>
      <div className="NavBar_menu">
        <NavLink
          className="NavBar_menu-link"
          to="/boite-a-outils"
          activeClassName="active"
        >
          <div className="NavBar_menu-link-content">
            <img
              className="NavBar_menu-link-content-image"
              src={logoTools}
              alt="Logo Boite à outils"
            />
            <img
              className="NavBar_menu-link-content-image-active"
              src={logoToolsActive}
              alt="Logo Boite à outils"
            />
            <div className="NavBar_menu-link-text">Inventaire</div>
          </div>
        </NavLink>
        <NavLink
          to="/challenges"
          className="NavBar_menu-link"
          activeClassName="active"
        >
          <div className="NavBar_menu-link-content">
            <img
              className="NavBar_menu-link-content-image"
              src={logoChallenges}
              alt="Logo Challenges"
            />
            <img
              className="NavBar_menu-link-content-image-active"
              src={logoChallengesActive}
              alt="Logo Challenges"
            />
            <div className="NavBar_menu-link-text">Mes challenges</div>
          </div>
        </NavLink>
        <NavLink
          to="/mon-compte"
          className="NavBar_menu-link"
          activeClassName="active"
        >
          <div className="NavBar_menu-link-content">
            <img
              className="NavBar_menu-link-content-image"
              src={logoAccount}
              alt="Logo mon compte"
            />
            <img
              className="NavBar_menu-link-content-image-active"
              src={logoAccountActive}
              alt="Logo mon compte"
            />
            <div className="NavBar_menu-link-text">Mon compte</div>
          </div>
        </NavLink>
      </div>
      {/* <div className="NavBar_menu-link-question">
        <img src={imageQuestion} alt="Qu'est ce que Qokka" />
        <p>Qokka..?</p>
      </div> */}
      <button
        className="NavBar_menu-link-question"
        onClick={() => handleDeconnexion()}
      >
        <img src={decoImage} alt="logo quokka" />
        <div className="NavBar_menu-link-text">Déconnexion</div>
      </button>
    </div>
  );
};

NavBar.propTypes = {
  title: PropTypes.string,
};

NavBar.defaultProps = {
  title: '',
};

export default NavBar;
