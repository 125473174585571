import React from 'react';
import { connect } from 'react-redux';

import joie from '../../styles/images/joie.svg';
import temperature from '../../styles/images/emotionsDebrief/fear/temperature.svg';
import eye from '../../styles/images/emotionsDebrief/fear/eye.svg';
import zen from '../../styles/images/emotionsDebrief/fear/zen.svg';
import friend from '../../styles/images/emotionsDebrief/fear/friend.svg';
import environment from '../../styles/images/emotionsDebrief/fear/environment.svg';
import mouse from '../../styles/images/emotionsDebrief/fear/mouse.svg';
import elio from '../../styles/images/emotionsDebrief/fear/elio.png';
import stomac from '../../styles/images/emotionsDebrief/fear/stomac.svg';
import muscle from '../../styles/images/emotionsDebrief/fear/muscle.svg';
import heart from '../../styles/images/emotionsDebrief/fear/heart.svg';
import water from '../../styles/images/emotionsDebrief/fear/water.svg';

import '../../styles/css/Emotions/Fear.css';

const Fear = ({ currentEmotion }) => {
  //   const peur = '#6fa83f';
  //   const surprise = '#478c69';
  //   const dégout = '#8851a9';
  //   const colere = '#ef60a5';
  //   const anticipation = '##ff7d35';
  return (
    <div className="Fear">
      <div className="Fear_left">
        <img src={joie} alt="logoTricky" className="Fear_left-tricky" />
        <div className="Fear_left-description">
          <h3>LA PEUR</h3>
          <p>{currentEmotion.what_first} </p>
        </div>
        <img className="Fear_left-image" src={elio} alt="Elio tristesse" />
      </div>
      <div className="Fear_right">
        <h3 className="Fear_right-title">GERER SA PEUR</h3>
        <div className="Fear_right-temperature">
          <img
            className="Fear_right-temperature-image"
            src={temperature}
            alt="temperature"
          />
          <div className="Fear_right-temperature-right">
            <p>Quelle est l'intensité de ta peur ?</p>
            <p className="Fear_right-temperature-numbers">0 1 2 3 4 5</p>
          </div>
        </div>
        <div className="Fear_right-zen">
          <p className="Fear_right-zen-text">
            Fermer les yeux et s'imaginer dans un endroit apaisant. Se
            concentrer uniquement sur sa respiration pour en reprendre le
            contrôle
          </p>
          <img src={zen} alt="Zen" />
        </div>
        <p className="Fear_right-song">
          Ecouter une musique afin d'apport un nouveau stimulus qui va modifier
          le circuit neuronal. Cela te permettra de porter ton attention sur une
          autre chose que la peur
        </p>
        <div className="Fear_right-friend">
          <p className="Fear_right-friend-text">
            Visualiser une personne rassurante à votre côté lors d'une situation
            stressante ou inquiétant. Ensuite, se demander: qu'est ce qu'elle me
            dirait ?
          </p>
          <img src={friend} alt="Rassurer" />
        </div>
        <div className="Fear_right-environment">
          <p className="Fear_right-environment-text">
            Changer de pièce pour laisser derrière soi cette émotion.
          </p>
          <img
            className="Fear_right-environment-image"
            src={environment}
            alt="Quit"
          />
        </div>
        <div className="Fear_right-freeze">
          <p className="Fear_right-freeze-title">Fight, flight or freeze ?</p>
          <p>Réaction en situation de stress :</p>
          <div className="Fear_right-reactions">
            <div className="Fear_right-reactions-left">
              <div className="Fear_right-reactions-left-reaction">
                <img src={eye} alt="dilatation" />
                <p className="Fear_right-reactions-left-reaction-text">
                  Dilatation des pupilles
                </p>
              </div>
              <div className="Fear_right-reactions-left-reaction">
                <img src={mouse} alt="mouse" />
                <p className="Fear_right-reactions-left-reaction-text">
                  Bouche sèche
                </p>
              </div>
              <div className="Fear_right-reactions-left-reaction">
                <img src={stomac} alt="Estomac" />
                <p className="Fear_right-reactions-left-reaction-text">
                  Vessie et estomac serrés
                </p>
              </div>
            </div>
            <div className="Fear_right-reactions-right">
              <div className="Fear_right-reactions-right-reaction">
                <img src={muscle} alt="Poils dressés" />
                <p className="Fear_right-reactions-left-reaction-text">
                  Poils Dressés
                </p>
              </div>
              <div className="Fear_right-reactions-right-reaction">
                <img src={water} alt="Transpiration" />
                <p className="Fear_right-reactions-left-reaction-text">
                  Transpiration
                </p>
              </div>
              <div className="Fear_right-reactions-right-reaction">
                <img src={heart} alt="Coeur" />
                <p className="Fear_right-reactions-left-reaction-text">
                  Accélération du rythme cardiaque
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentEmotion: state.emotions.currentEmotion,
  };
};

export default connect(mapStateToProps, null)(Fear);
