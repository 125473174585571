import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactLoading from 'react-loading';
import axios from 'axios';

import { currentChallenge, currentEmotion, changeStep } from '../actions/index';

import QuestionFeeling from './QuestionFeeling';
import QuestionChoice from './QuestionChoice';
import QuestionSolution from './QuestionSolution';
import QuestionIntroGame from './QuestionIntroGame';
import NavBar from '../NavBar';
import Games from '../Games/Games';
import QuestionDebrief from './QuestionDebrief';

import Modal from '../../stories/components/Modal/Modal';
import Button from '../../stories/components/Button/Button';

import '../../styles/css/Questions/QuestionsContent.css';

import heartEmptyImage from '../../styles/images/heart-empty.svg';
import heartImage from '../../styles/images/heart.svg';
import logoHat from '../../styles/images/logoHat.svg';
import logoClever from '../../styles/images/logoClever.svg';
import logoGame from '../../styles/images/logoGame.svg';
import logoLight from '../../styles/images/logoLight.png';
import logoBook from '../../styles/images/logoBook.svg';
import logoValid from '../../styles/images/valid.svg';

const QuestionsContent = ({
  location,
  modal,
  textModal,
  actionModal,
  imageModal,
  iconModal,
  buttonTextModal,
  currentChallenge,
  addCurrentChallenge,
  descriptionModal,
  currentEmotion,
  addCurrentEmotion,
  changeStep,
  user,
}) => {
  const { challenge } = location.state;
  const history = useHistory();

  useEffect(() => {
    if (!currentChallenge) {
      axios
        .get(
          `${process.env.REACT_APP_URL_SERVER}/api/users/challenges/${challenge.id}`
        )
        .then((response) => addCurrentChallenge(response.data));
    }
    if (!currentEmotion) {
      axios
        .get(
          `${process.env.REACT_APP_URL_SERVER}/api/emotions/${challenge.response_feeling}`
        )
        .then((response) => addCurrentEmotion(response.data));
    }
  }, []);

  const arrayOfHearts = [1, 2, 3, 4];

  const handleHeartsImage = (heart) => {
    if (currentChallenge.number_try_choice === null) {
      return heartImage;
    }
    const numberHeartLeft = currentChallenge.number_try_choice + 1;
    if (heart <= numberHeartLeft) {
      return heartImage;
    } else {
      return heartEmptyImage;
    }
  };
  const [goToGame, setGoToGame] = useState(false);

  const [steps, setSteps] = useState([
    {
      title: 'Vie de merde',
      logo: logoBook,
      checked: false,
    },
    {
      title: 'Réaction',
      logo: logoClever,
      checked: false,
    },
    {
      title: 'Solution',
      logo: logoLight,
      checked: false,
    },
    {
      title: 'Mini-jeu',
      logo: logoGame,
      checked: false,
    },
    {
      title: 'Conclusion',
      logo: logoHat,
      checked: false,
    },
  ]);
  
  const handlePanel = () => {
    switch (currentChallenge.step) {
      case 0:
        return <QuestionFeeling challenge={challenge} />;
        break;
      case 1:
        return <QuestionChoice challenge={challenge} />;
        break;
      case 2:
        return <QuestionSolution challenge={challenge} />;
        break;
      case 3:
        if (goToGame) {
          return <Games />;
        }
        return <QuestionIntroGame setGoToGame={setGoToGame} />;
        break;
      case 4:
        return <QuestionDebrief />;
      default:
        break;
    }
  };

  const handleStepClass = (index) => {
    if (currentChallenge.step === index) {
      return '-step-current';
    } else {
      if (currentChallenge.step > index) {
        return '-step-valid';
      } else {
        return '-step';
      }
    }
  };

  return (
    <div className="QuestionsContent">
      <div
        className={`QuestionsContent-${
          modal.isOpen ? 'modalIsOpen' : 'modalIsClose'
        }`}
      />
      <NavBar />
      {currentChallenge ? (
        <div className="QuestionsContent_content">
          <div className="QuestionsContent_header">
            <Button
              primary
              onClick={() => history.push('/challenges')}
              label="Retour"
              size="small"
            />
            <p>{currentChallenge.name}</p>
            <div>
              {arrayOfHearts.map((heart) => (
                <img
                  className="Challenge_heart"
                  src={handleHeartsImage(heart)}
                  alt="score"
                />
              ))}
            </div>
          </div>
          <div className="QuestionsContent_content-panel">
            <div className="QuestionsContent_content-panel-navigation">
              <div className="QuestionsContent_content-panel-navigation-titles">
                {steps.map((step, index) => (
                  <button
                    onClick={() =>
                      currentChallenge.step >= index && changeStep(index)
                    }
                    className="QuestionsContent_content-panel-navigation-content"
                  >
                    <p
                      className={`QuestionsContent_content-panel-navigation-content-${handleStepClass(
                        index
                      )}`}
                    >
                      {currentChallenge.step > index ? (
                        <img src={logoValid} />
                      ) : (
                        index + 1
                      )}
                    </p>
                    <p
                      className={`QuestionsContent_content-panel-navigation-content-${
                        currentChallenge.step === index
                          ? '-title-current'
                          : '-title'
                      }`}
                    >
                      {step.title}
                    </p>
                  </button>
                ))}
              </div>
              <img
                className="QuestionsContent_content-panel-navigation-image"
                src={steps[currentChallenge.step].logo}
                alt={steps[currentChallenge.step].name}
              />
            </div>
            <div className="QuestionsContent_content_panel-right">
              {handlePanel()}
            </div>
          </div>
        </div>
      ) : (
        <ReactLoading
          type="bubbles"
          color="white"
          height={'10%'}
          width={'10%'}
        />
      )}

      {modal.isOpen && (
        <Modal
          textModal={textModal}
          buttonText={buttonTextModal}
          actionModal={actionModal}
          iconModal={iconModal}
          image={imageModal}
          descriptionModal={descriptionModal}
        />
      )}
    </div>
  );
};

QuestionsContent.propTypes = {
  location: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
    textModal: state.modal.text,
    buttonTextModal: state.modal.buttonText,
    actionModal: state.modal.buttonAction,
    iconModal: state.modal.icon,
    imageModal: state.modal.image,
    currentChallenge: state.challenges.currentChallenge,
    user: state.user,
    descriptionModal: state.modal.descriptionModal,
    currentEmotion: state.emotions.currentEmotion,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCurrentChallenge: (data) => {
      dispatch(currentChallenge(data));
    },
    addCurrentEmotion: (data) => {
      dispatch(currentEmotion(data));
    },
    changeStep: (data) => {
      dispatch(changeStep(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsContent);
