import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import Button from '../../../stories/components/Button/Button';

import '../../../styles/css/Admin/Users/ViewUser.css';

import heartEmptyImage from '../../../styles/images/heart-empty.svg';
import heartImage from '../../../styles/images/heart.svg';
import heartGreyImage from '../../../styles/images/heart-grey.svg';

const ViewUser = ({ match }) => {
  const [userDetails, setUserDetails] = useState(null);
  const [userChallenges, setUserChallenges] = useState([]);
  const [allChallenges, setAllChallenges] = useState([]);
  const history = useHistory();
  useEffect(() => {
    const userId = match.params.id;
    axios
      .get(`${process.env.REACT_APP_URL_SERVER}/api/users/${userId}`)
      .then((results) => results.data)
      .then((data) => setUserDetails(data));

    axios
      .get(`${process.env.REACT_APP_URL_SERVER}/api/users/${userId}/challenges`)
      .then((results) => results.data)
      .then((data) => setUserChallenges(data));

    axios
      .get(`${process.env.REACT_APP_URL_SERVER}/api/challenges`)
      .then((results) => results.data)
      .then((data) => setAllChallenges(data));
  }, []);

  const handleChallengeInProgress = () => {
    const listChallengeInProgress = userChallenges.filter(
      (challenge) => !challenge.validate
    );

    if (listChallengeInProgress.length === 0) {
      return <p>Pas de challenge en cours</p>;
    }

    return listChallengeInProgress.map((challenge) => (
      <button
        className="Challenge"
        style={{
          backgroundColor: challenge.color,
        }}
      >
        <p>{challenge.name}</p>
      </button>
    ));
  };
  const arrayOfHearts = [1, 2, 3, 4];

  const handleHeartsImage = (heart, number_try_choice) => {
    if (number_try_choice !== null) {
      if (heart <= number_try_choice + 1) {
        return heartImage;
      } else {
        return heartEmptyImage;
      }
    } else {
      return heartGreyImage;
    }
  };

  const handleChallengeValidate = () => {
    const listChallengeValidate = userChallenges.filter(
      (challenge) => challenge.validate
    );

    if (listChallengeValidate.length === 0) {
      return <p>Pas de challenge validé</p>;
    }

    return listChallengeValidate.map((challenge) => (
      <button
        className="ViewUser_challenge"
        style={{
          backgroundColor: challenge.color,
        }}
      >
        <p>{challenge.name}</p>
        <div style={{ opacity: 1 }} className="Challenge_hearts">
          {arrayOfHearts.map((heart) => (
            <img
              className="ViewUser_challenge-heart"
              src={handleHeartsImage(heart, challenge.number_try_choice)}
              alt="score"
            />
          ))}
        </div>
        <div className="ViewUser_bar" />
        <div className="ViewUser_responses">
          <div className="ViewUser_emotion">
            <img
              className="ViewUser_emotion-image"
              src={challenge.emotion_image}
              alt="Emotion"
            />
            {challenge.emotion_name}
          </div>
          <p>{challenge.choice_response}</p>
        </div>
      </button>
    ));
  };

  return (
    <div className="ViewUser">
      <Button label="Retour" onClick={() => history.push('/utilisateurs')} />
      {userDetails && (
        <div className="ViewUser_details">
          <p>
            {userDetails.firstname} {userDetails.lastname}
          </p>

          <div className="ViewUser_content">
            <p className="ViewUser_title">En cours </p>

            <div className="ViewUser_challenges">
              {handleChallengeInProgress()}
            </div>
          </div>

          <div className="ViewUser_content">
            <p className="ViewUser_title">Validé </p>
            <div className="ViewUser_challenges">
              {handleChallengeValidate()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewUser;
