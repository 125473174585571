import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';

import NavBar from '../NavBar';
import User from './User';
import Pagination from './Pagination';

import '../../../styles/css/Admin/Users/Users.css';

let PageSize = 10;

const Users = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL_SERVER}/api/users`)
      .then((results) => results.data)
      .then((data) => setUsers(data));
  }, []);

  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    if (users) {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      return users.slice(firstPageIndex, lastPageIndex);
    }
  }, [currentPage, users]);

  return (
    <div className="Users">
      <NavBar />
      <div className="Users_lists">
        {currentTableData.map((item) => {
          return <User {...item} />;
        })}
      </div>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={users.length}
        pageSize={PageSize}
        onPageChange={(page) => {
          setCurrentPage(page);
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }}
      />
    </div>
  );
};

export default Users;
