import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import Intro from './Intro';
import Input from '../../stories/components/Input/Input';
import Button from '../../stories/components/Button/Button';
import Link from '../../stories/components/Link/Link';
import Alert from '../../stories/components/Alert/Alert';

import '../../styles/css/Auth/Signin.css';

const Signin = ({ location }) => {
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };

  const query = useQuery();
  const [inputs, setInputs] = useState({
    email: '',
    password: '',
    token: query.get('token'),
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  const handleConnexion = () => {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
    }
    axios
      .post(`${process.env.REACT_APP_URL_SERVER}/api/users/login`, inputs)
      .then((response) => response.data)
      .then((data) => localStorage.setItem('token', data.token))
      .then(() => history.push('/challenges'))
      .catch((error) => {
        const errors = Object.assign({}, error).response.data.errors;
        if (errors) {
          errors.map((error) => setErrorMessage(error.msg));
        } else {
          setErrorMessage('Email ou mot de passe incorrect.');
        }
      });
  };

  return (
    <div className="Signin">
      <Intro />
      <div className="Signin_content">
        <div className="Signin_content-top">
          <h2 className="Signin_content-title">Connectez-vous</h2>
          <p className="Signin_content-description">
            Vous ne possédez pas de compte ?
          </p>
          <Link url="/inscription" label="Inscrivez-vous" />
        </div>
        <div className="Signin_form">
          <div className="Signin_form-inputs">
            <Input
              style={{
                borderColor: errorMessage && '#C02514',
                color: errorMessage && '#C02514',
              }}
              type="email"
              label="Email"
              value={inputs.email}
              name="email"
              required
              placeholder="exemple@email.com"
              onChange={(e) =>
                setInputs({ ...inputs, [e.target.name]: e.target.value })
              }
            />
            <Input
              style={{
                borderColor: errorMessage && '#C02514',
                color: errorMessage && '#C02514',
              }}
              type={showPassword ? 'text' : 'password'}
              label="Mot de passe"
              value={inputs.password}
              name="password"
              required
              placeholder="*****"
              colorEye="grey"
              setShowPassword={() => setShowPassword(!showPassword)}
              onChange={(e) =>
                setInputs({ ...inputs, [e.target.name]: e.target.value })
              }
            />
            {errorMessage && <Alert message={errorMessage} primary />}
          </div>
          <div className="Signin_links">
            <Button
              onClick={() => handleConnexion()}
              primary
              label="Me connecter"
            />
            <Link url="/mot-de-passe-oublie" label="Mot de passe oublié ?" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
