import React from 'react';
import Button from '../../../../stories/components/Button/Button';

const QuestionAcceptation = (props) => {
  const responsesAcceptation = [
    {
      id: 1,
      text: 'Oui',
      handler: props.actionProvider.handleBoolAcceptation,
    },
    {
      id: 2,
      text: 'Non',
      handler: props.actionProvider.handleBoolAcceptation,
    },
  ];
  return (
    <div>
      {responsesAcceptation.map((response) => (
        <Button
          key={response.id}
          label={response.text}
          primary
          onClick={() => response.handler(response.id, 9)}
        />
      ))}
    </div>
  );
};

export default QuestionAcceptation;
