import React from 'react';
import { connect } from 'react-redux';

import joie from '../../styles/images/joie.svg';
import temperature from '../../styles/images/emotionsDebrief/sadness/temperature.svg';
import eye from '../../styles/images/emotionsDebrief/sadness/eye.svg';
import dance from '../../styles/images/emotionsDebrief/sadness/dance.svg';
import write from '../../styles/images/emotionsDebrief/sadness/write.svg';
import environment from '../../styles/images/emotionsDebrief/sadness/environment.svg';
import family from '../../styles/images/emotionsDebrief/sadness/family.svg';
import elio from '../../styles/images/emotionsDebrief/sadness/elio.png';

import '../../styles/css/Emotions/Sadness.css';

const Sadness = ({ currentEmotion }) => {
  return (
    <div className="Sadness">
      <div className="Sadness_left">
        <img src={joie} alt="logoTricky" className="Sadness_left-tricky" />
        <div className="Sadness_left-description">
          <h3>LA TRISTESSE</h3>
          <p>{currentEmotion.what_first} </p>
        </div>
        <img className="Sadness_left-image" src={elio} alt="Elio tristesse" />
      </div>
      <div className="Sadness_right">
        <h3 className="Sadness_right-title">GERER SA TRISTESSE</h3>
        <div className="Sadness_right-temperature">
          <img
            className="Sadness_right-temperature-image"
            src={temperature}
            alt="temperature"
          />
          <div className="Sadness_right-temperature-right">
            <p>Quelle est l'intensité de ta tristesse ?</p>
            <p className="Sadness_right-temperature-numbers">0 1 2 3 4 5</p>
          </div>
        </div>
        <div className="Sadness_right-eyes">
          <div className="Sadness_right-eyes-left">
            <p>1. Fermer ses yeux</p>
            <p>
              2. Visualiser la cause de sa tristesse dans un objet du quotidien
            </p>
            <p>3. Emmener cette objet loin de soi pour lui faire ses adieux</p>
          </div>
          <img className="Sadness_right-eyes-left-image" src={eye} alt="eyes" />
        </div>
        <div className="Sadness_right-music">
          <img src={dance} alt="dance" />
          <p className="Sadness_right-music-text">
            Ecouter une musique énergétique et se laisser porter par le rythme
          </p>
        </div>
        <div className="Sadness_right-write">
          <p className="Sadness_right-write-text">
            Ecrire ou dessiner sur sa douleur, puis brûler le document afin de
            limiter sa présence dans son esprit
          </p>
          <img className="Sadness_right-write-image" src={write} alt="write" />
        </div>
        <div className="Sadness_right-environment">
          <p className="Sadness_right-environment-text">
            Changer d'environnement pour laisser derrière soi cette émotion
          </p>
          <img
            className="Sadness_right-environment-image"
            src={environment}
            alt=""
          />
        </div>
        <div className="Sadness_right-family">
          <img
            className="Sadness_right-family-image"
            src={family}
            alt="family"
          />
          <p className="Sadness_right-family-text">
            Fermer les yeux et revivre un moment de grand bonheur. Le laisser
            infuser dans tout son corps. Retrouver les sensations physiques que
            le bonheur peut apporter.
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentEmotion: state.emotions.currentEmotion,
  };
};

export default connect(mapStateToProps, null)(Sadness);
