import React from 'react';

import Button from '../../../../stories/components/Button/Button';

const QuestionLose = (props) => {
  const responsesLose = [
    {
      id: 1,
      text: 'Oui',
      handler: props.actionProvider.handleExplainLose,
    },
    {
      id: 2,
      text: 'Non',
      handler: props.actionProvider.handleExplainLose,
    },
  ];
  return (
    <div className="QuestionLose">
      {responsesLose.map((response) => (
        <Button
          key={response.id}
          label={response.text}
          primary
          onClick={() => response.handler(response.id, 5)}
        />
      ))}
    </div>
  );
};

export default QuestionLose;
