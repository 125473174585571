import axios from 'axios';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';

import Challenge from './Challenge';

import {
  fetchAllChallenges,
  addAllChallenges,
  errorAllChallenges,
} from '../actions/index';

import '../../styles/css/Challenges/TypeOfChallenges.css';

const TypeOfChallenges = ({
  name,
  id,
  user,
  allChallenges,
  fetchAllChallenges,
  addAllChallenges,
  errorAllChallenges,
}) => {
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL_SERVER}/api/types/${id}/challenges`)
      .then((response) => {
        axios
          .get(
            `${process.env.REACT_APP_URL_SERVER}/api/usersChallenges/${user.id}`
          )
          .then((response2) => {
            const newData = response.data.map((challenge) => {
              const haveEqualId = (userChallenge) =>
                userChallenge.challenge_id === challenge.id;
              const userWithEqualId = response2.data.find(haveEqualId);
              return Object.assign({}, challenge, userWithEqualId);
            });
            fetchAllChallenges();
            addAllChallenges(id, newData);
          })
          .catch((error) => errorAllChallenges(error));
      });
  }, []);

  return (
    <div key={id} className="TypeOfChallenges">
      <p className="TypeOfChallenges_name">
        En tant que <strong>{name}</strong>
      </p>
      <div className="TypeOfChallenges_challenges">
        {allChallenges && allChallenges[id] ? (
          allChallenges[id].map((challenge, index) => (
            <Challenge challenge={challenge} type={id} index={index} />
          ))
        ) : (
          <ReactLoading
            type="bubbles"
            color="white"
            height={'10%'}
            width={'10%'}
          />
        )}
      </div>
    </div>
  );
};

TypeOfChallenges.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    allChallenges: state.challenges.allChallenges,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllChallenges: () => {
      dispatch(fetchAllChallenges());
    },
    addAllChallenges: (type, data) => {
      dispatch(addAllChallenges(type, data));
    },
    errorAllChallenges: (type, data) => {
      dispatch(errorAllChallenges(type, data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TypeOfChallenges);
