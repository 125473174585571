import React from 'react';

import Button from '../../../../stories/components/Button/Button';

import '../../../../styles/css/Games/Bot2/QuestionSituations.css';
const QuestionSituations = (props) => {
  const situations = [
    {
      id: 1,
      text: "la perte d'un proche",
      handler: props.actionProvider.handleLose,
    },
    {
      id: 2,
      text: "la perte de facultés physique de la personne que j'aide",
      handler: props.actionProvider.handleLosePhysical,
    },
    {
      id: 3,
      text: "la perte financière liée à mon rôle d'aidant",
      handler: props.actionProvider.handleLose,
    },
    {
      id: 4,
      text: "la perte de ma vie sociale liée à mon rôle d'aidant",
      handler: props.actionProvider.handleLose,
    },
  ];
  return (
    <div className="QuestionSituations">
      {situations.map((situation) => (
        <Button
          key={situation.id}
          label={situation.text}
          primary
          onClick={() => situation.handler(situation.id, 1)}
        />
      ))}
    </div>
  );
};

export default QuestionSituations;
