import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import Intro from './Intro';
import Input from '../../stories/components/Input/Input';
import Button from '../../stories/components/Button/Button';
import Alert from '../../stories/components/Alert/Alert';

import '../../styles/css/Auth/ForgetPassword.css';

const ForgetPassword = () => {
  const [inputs, setInputs] = useState({
    email: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  const handleForgetPassword = () => {
    axios
      .post(`${process.env.REACT_APP_URL_SERVER}/api/users/forget`, inputs)
      .then((response) => {
        history.push('/mot-de-passe-oublie-valide');
      })
      .catch(() =>
        setErrorMessage(
          "Une erreur est survenu lors de l'envoi, veuillez réessayer"
        )
      );
  };

  return (
    <div className="ForgetPassword">
      <Intro />
      <div className="ForgetPassword_content">
        <div className="ForgetPassword_content-top">
          <h2 className="ForgetPassword_content-title">Mot de passe oublié</h2>
          <p className="ForgetPassword_content-description">
            Pas d'inquiétude, c'est facile. Il faut juste ajouter votre email
            ci-dessous :
          </p>
        </div>
        <div className="ForgetPassword_form">
          <Input
            style={{
              borderColor: errorMessage && '#C02514',
              color: errorMessage && '#C02514',
            }}
            type="email"
            label="Email"
            value={inputs.email}
            name="email"
            required
            placeholder="exemple@email.com"
            onChange={(e) =>
              setInputs({ ...inputs, [e.target.name]: e.target.value })
            }
          />
          {errorMessage && <Alert message={errorMessage} primary />}
          <Button
            onClick={() => handleForgetPassword()}
            primary
            label="Envoyer"
          />
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
