import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';

import Button from '../../stories/components/Button/Button';
import Parser from 'html-react-parser';

import {
  currentChallenge as currentChallengeAction,
  currentEmotion as currentEmotionAction,
} from '../actions/index';

import '../../styles/css/Questions/QuestionIntroGame.css';
import {
  fetchGamesOfEmotion,
  addCurrentGame,
  addGamesUser,
  fetchGamesOfUser,
  changeStep,
} from '../actions';

const QuestionIntroGame = ({
  currentChallenge,
  fetchGamesOfEmotion,
  addCurrentGame,
  currentGame,
  addGamesUser,
  user,
  fetchGamesOfUser,
  gamesOfUser,
  addCurrentChallenge,
  addCurrentEmotion,
  currentEmotion,
  changeStep,
  setGoToGame,
}) => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const idEmotion = history.location.state.challenge.response_feeling;

    if (!currentEmotion) {
      axios
        .get(`${process.env.REACT_APP_URL_SERVER}/api/emotions/${idEmotion}`)
        .then((response) => addCurrentEmotion(response.data));
    }

    if (!currentEmotion && idEmotion === null && currentChallenge) {
      axios
        .get(
          `${process.env.REACT_APP_URL_SERVER}/api/emotions/${currentChallenge.response_feeling}`
        )
        .then((response) => addCurrentEmotion(response.data));
    }
    if (!currentChallenge) {
      axios
        .get(
          `${process.env.REACT_APP_URL_SERVER}/api/users/{user.id}/challenges/${history.location.state.challenge.id}`
        )
        .then((response) => addCurrentChallenge(response.data));
      axios
        .get(
          `${process.env.REACT_APP_URL_SERVER}/api/emotions/${idEmotion}/games`
        )
        .then((response) => response.data)
        .then((data) =>
          axios
            .get(
              `${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}/games`
            )
            .then((response) => response.data)
            .then((data2) => handleGame(data, data2))
        );
    } else {
      axios
        .get(
          `${process.env.REACT_APP_URL_SERVER}/api/emotions/${currentChallenge.response_feeling}/games`
        )
        .then((response) => response.data)
        .then((data) =>
          axios
            .get(
              `${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}/games`
            )
            .then((response) => response.data)
            .then((data2) => handleGame(data, data2))
        );
    }
  }, []);

  const handleGame = (data, data2) => {
    fetchGamesOfEmotion(data);
    fetchGamesOfUser(data2);

    let filterGames = [];

    if (data2.length > 0) {
      const newData = data.map((allGame) => {
        var haveEqualId = (userGame) => userGame.id === allGame.id_game;
        var userWithEqualId = data2.find(haveEqualId);
        return Object.assign({}, allGame, userWithEqualId);
      });

      newData
        .filter((game) => game.validate !== 1)
        .map((game) => filterGames.push(game));

      filterGames = newData;
    } else {
      filterGames = data;
    }

    const randomNumberGame = Math.floor(Math.random() * filterGames.length);

    const slugGame = filterGames[randomNumberGame];
    addCurrentGame(null);
    addCurrentGame(slugGame);
  };

  const handleRandomGame = () => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}/games/${currentGame.id}`,
        {
          id_game: currentGame.id,
          id_user: user.id,
          id_user_challenge: currentChallenge.id,
        }
      )
      .then(() => {
        setLoading(false);
        setGoToGame(true);
      });
  };

  const handleQuestionIntro = () => {
    switch (currentEmotion.question) {
      case 'maîtriser':
        return `Et si on maîtrisait notre ${currentEmotion.name}`;
        break;
      case 'cultiver':
        return `Et si on cultivait notre ${currentEmotion.name}`;
        break;
      default:
        break;
    }
  };

  const handleGoBack = () => {
    if (currentChallenge.step === 0) {
      history.push('/challenges');
    } else {
      changeStep(currentChallenge.step - 1);
    }
  };

  return (
    <div className="QuestionIntroGame">
      {currentEmotion && currentGame && (
        <div>
          <p className="QuestionIntroGame_text">Tu as ressenti l'émotion :</p>
          <div className="QuestionIntroGame_emotion">
            <div className="QuestionIntroGame_emotion-icon">
              <img src={currentEmotion.imageUrl} alt="information" />
              <p className="QuestionIntroGame_emotion-icon-name">
                {currentEmotion.name}
              </p>
            </div>
            <div className="QuestionIntroGame_emotion-content">
              <div className="QuestionIntroGame_emotion-content-left">
                <p className="QuestionIntroGame_emotion-content-left-title">
                  Qu'est ce que la {currentEmotion.name} ?
                </p>
                <p className="QuestionIntroGame_emotion-content-left-description">
                  {currentEmotion.what_first}
                </p>
              </div>
              <div className="QuestionIntroGame_emotion-content-right">
                <p className="QuestionIntroGame_emotion-content-right-title">
                  A quoi ça sert ?
                </p>
                <p className="QuestionIntroGame_emotion-content-right-description">
                  {currentEmotion.what_second}
                </p>
              </div>
            </div>
          </div>
          <div className="QuestionIntroGame_intro">
            <p className="QuestionIntroGame_intro-text">
              {handleQuestionIntro()} ?
            </p>
            <p className="QuestionIntroGame_intro-title">
              Nous te proposons un jeu pour {currentEmotion.question}{' '}
              {currentEmotion.slug === 'anticipation' ||
              currentEmotion.slug === 'disgust'
                ? 'ton '
                : 'ta '}
              {currentEmotion.name} : {currentGame.title_deposit}
            </p>
            {currentGame.description_deposit && (
              <div className="QuestionIntroGame_description">
                {Parser(currentGame.description_deposit)}
              </div>
            )}
          </div>
        </div>
      )}
      {!loading ? (
        <div className="QuestionIntroGame-buttons">
          <Button primary onClick={() => handleGoBack()} label="Précédent" />
          <Button label="Valider" primary onClick={() => handleRandomGame()} />
        </div>
      ) : (
        <ReactLoading
          type="bubbles"
          color="white"
          height={'10%'}
          width={'10%'}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentChallenge: state.challenges.currentChallenge,
    currentGame: state.games.currentGame,
    user: state.user,
    gamesOfUser: state.games.gamesOfUser,
    currentEmotion: state.emotions.currentEmotion,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGamesOfEmotion: (data) => {
      dispatch(fetchGamesOfEmotion(data));
    },
    addCurrentGame: (data) => {
      dispatch(addCurrentGame(data));
    },
    addGamesUser: (data) => {
      dispatch(addGamesUser(data));
    },
    fetchGamesOfUser: (data) => {
      dispatch(fetchGamesOfUser(data));
    },
    addCurrentChallenge: (data) => {
      dispatch(currentChallengeAction(data));
    },
    addCurrentEmotion: (data) => {
      dispatch(currentEmotionAction(data));
    },
    changeStep: (data) => {
      dispatch(changeStep(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionIntroGame);
