import React from 'react';

import Button from '../../../../stories/components/Button/Button';

const StepPain = (props) => {
  const responsesPain = [
    {
      id: 1,
      text: 'Oui',
      handler: props.actionProvider.handleBoolPain,
    },
    {
      id: 2,
      text: 'Non',
      handler: props.actionProvider.handleBoolPain,
    },
  ];

  return (
    <div className="StepPain">
      {responsesPain.map((response) => (
        <Button
          key={response.id}
          label={response.text}
          primary
          onClick={() => response.handler(response.id, 3)}
        />
      ))}
    </div>
  );
};

export default StepPain;
