import React, { useEffect } from 'react';
import axios from 'axios';

import '../../../styles/css/Games/Bot2/Todos.css';

const Todos = (props) => {
  const { setState } = props;

  useEffect(() => {
    axios.get('http://jsonplaceholder.typicode.com/todos').then((response) => {
      const fiveFirstTodos = response.data.slice(0, 6);
      setState((state) => ({ ...state, todos: fiveFirstTodos }));
    });
  }, []);

  return (
    <div className="todos-widget">
      <ul className="todos-widget-list">
        {props.todos.map((todo) => (
          <li className="todos-widget-list-item">{todo.title}</li>
        ))}
      </ul>
    </div>
  );
};

export default Todos;
