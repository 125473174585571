import React from 'react';
import PropTypes from 'prop-types';

import '../../styles/css/input.css';

/**
 * Primary UI component for user interaction
 */
export const InputRadio = ({
  primary,
  label,
  name,
  required,
  checked,
  ...props
}) => {
  const mode = primary
    ? 'storybook-input-radio--primary'
    : 'storybook-input-radio--secondary';

  return (
    <button
      name={name}
      required
      className={`storybook-input-radio ${
        checked ? 'isChecked' : 'isNotChecked'
      } ${mode}`}
      {...props}
    >
      <div>{label}</div>
      {/* <div className="storybook-input-radio-circle" /> */}
    </button>
  );
};

InputRadio.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  primary: PropTypes.bool,
  /**
   * What background color to use
   */
  backgroundColor: PropTypes.string,
  /**
   * How large should the input be?
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Input contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Input identification
   */
  name: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Input types
   */
  type: PropTypes.oneOf(['text', 'number', 'email', 'password']),
  /**
   * Input is required or not
   */
  required: PropTypes.bool,
};

InputRadio.defaultProps = {
  backgroundColor: null,
  primary: false,
  size: 'medium',
  onClick: undefined,
  onChange: undefined,
  type: 'text',
  required: false,
};

export default InputRadio;
