import React from 'react';
import PropTypes from 'prop-types';

import '../../styles/css/box/box.css';
import logoQuote from '../../assets/quotes.svg';

/**
 * Primary UI component for user interaction
 */
export const Box = ({
  text,
  secondText,
  thirdText,
  primary,
  imageUrl,
  type,
  ...props
}) => {
  const mode = primary ? 'storybook-box--primary' : 'storybook-box--secondary';
  if (type === 'question') {
    return (
      <div
        style={{
          backgroundColor: props.backgroundColor,
          borderColor: props.backgroundColor,
        }}
        className={`storybook-box-question ${mode}`}
      >
        <p className="storybook-box-question-text">{text}</p>
        {thirdText && (
          <p className="storybook-box-question-text">{thirdText}</p>
        )}
        {secondText && (
          <div className="storybook-box-question-quotes">
            <p className="storybook-box-question-secondText"> - {secondText}</p>
            <img src={logoQuote} />
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: props.backgroundColor,
        borderColor: props.backgroundColor,
      }}
      className={`storybook-box ${mode}`}
      {...props}
    >
      {text}
    </div>
  );
};

Box.propTypes = {
  /**
   * Button contents
   */
  text: PropTypes.string,
  type: PropTypes.string,
  primary: PropTypes.bool,
  secondText: PropTypes.string,
};

Box.defaultProps = {
  primary: false,
  type: 'box',
  text: 'Lorem Ipsum...',
};

export default Box;
