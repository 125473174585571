import React from 'react';
import { connect } from 'react-redux';

import joie from '../../styles/images/joie.svg';
import temperature from '../../styles/images/emotionsDebrief/angry/temperature.svg';
import eat from '../../styles/images/emotionsDebrief/angry/eat.svg';
import women from '../../styles/images/emotionsDebrief/angry/women.svg';
import trash from '../../styles/images/emotionsDebrief/angry/trash.svg';
import volcan from '../../styles/images/emotionsDebrief/angry/volcan.svg';
import elio from '../../styles/images/emotionsDebrief/angry/elio.png';

import '../../styles/css/Emotions/Angry.css';

const Angry = ({ currentEmotion }) => {
  //   const anticipation = '##ff7d35';
  return (
    <div className="Angry">
      <div className="Angry_left">
        <img src={joie} alt="logoTricky" className="Angry_left-tricky" />
        <div className="Angry_left-description">
          <h3>LA COLERE</h3>
          <p>{currentEmotion.what_first} </p>
        </div>
        <img className="Angry_left-image" src={elio} alt="Elio Angry" />
      </div>
      <div className="Angry_right">
        <h3 className="Angry_right-title">GERER SA COLERE</h3>
        <div className="Angry_right-temperature">
          <img
            className="Angry_right-temperature-image"
            src={temperature}
            alt="temperature"
          />
          <div className="Angry_right-temperature-right">
            <p>Quelle est l'intensité de ton dégoût ?</p>
            <p className="Angry_right-temperature-numbers">0 1 2 3 4 5</p>
          </div>
        </div>
        <div className="Angry_right-air">
          <p className="Angry_right-air-title">Prendre un bol d'air !</p>
          <p className="Angry_right-air-text">
            Marcher à l'extérieur et regarder attentivement autour de soi.
          </p>
        </div>
        <div className="Angry_right-water">
          <img src={eat} alt="eau apaisant" />
          <div className="Angry_right-water-content">
            <p className="Angry_right-water-content-title">L'eau apaisante</p>
            <p className="Angry_right-water-content-text">
              Pour éteindre sa colère, boire un verre d'eau en prenant son
              temps.
            </p>
          </div>
        </div>
        <div className="Angry_right-anger">
          <p className="Angry_right-anger-text">
            Accueilir sa colère en prenant une grande inspiration.
          </p>
          <img
            className="Angry_right-anger-image"
            src={women}
            alt="Accueillir sa colère"
          />
        </div>
        <div className="Angry_right-trash">
          <p className="Angry_right-trash-title">
            Dessiner ou griblouiller sa colère. En faire une boule de papier et
            la jeter à la poubelle.
          </p>
          <img className="Angry_right-trash-image" src={trash} alt="Trier" />
        </div>
        <div className="Angry_right-volcan">
          <div className="Angry_right-volcan-content">
            <p className="Angry_right-volcan-content-title">
              C'est l'explosion du volcan !
            </p>
            <p>
              1. Se tenir debout, les jambes resserrées. Joindre les mains
              devant la poitrine. Inspirer profondément par le nez en laissant
              lentement cheminer les mains vers le haut.
            </p>
            <p>
              2. Quand les bras sont arrivés au-dessus de la tête, sauter les
              jambes écartées tout en ramenant les bras le long du corps par les
              côtés. En même temps, expirer fortement par la bouche
            </p>
          </div>
          <img src={volcan} alt="Gérer sa colère" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentEmotion: state.emotions.currentEmotion,
  };
};

export default connect(mapStateToProps, null)(Angry);
