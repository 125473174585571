import React from 'react';

import '../../../../styles/css/Games/Bot2/StepAnger.css';

const StepAnger = (props) => {
  let message;

  if (props.responses[1] === 1) {
    message =
      "La perte d'un proche même ancienne soit-elle génère, quand on y pense, de la douleur. La douleur est une explication à la colère. Mais il ne s'agit là que d'une étape que nous surmontons tous.";
  }
  if (props.responses[1] === 2) {
    message =
      "Voir un proche perdre son autonomie et ses facultés physiques et/ou cognitives peut susciter de la tristesse. La douleur ressentie est l'explication à la colère. Mail il ne s'agit làque d'une étape que nous surmontons tous.";
  }
  if (props.responses[1] === 3 || props.responses[1] === 4) {
    message =
      "La perte même ancienne soit-elle génère quand on y pense de la douleur. La douleur est l'explication à la colère. Mais il ne s'agit là que d'une étape que nous surmontons tous.";
  }

  return <div className="StepAnger">{message}</div>;
};

export default StepAnger;
