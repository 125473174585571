import React, { useState } from 'react';
import { connect } from 'react-redux';

import Button from '../../stories/components/Button/Button';

import '../../styles/css/Games/Recycle.css';

const Recycle = ({ checkGameAndChallenge, currentGame }) => {
  return (
    <div className="Recycle">
      <div className="startScreen">
        <div className="rules rules1">
          <div className="rulesCont">Consigne cliquer sur la bonne émotion</div>
        </div>
      </div>
      <div className="endScreen">
        <div className="rules rules1">
          <div className="rulesCont">test</div>
        </div>
      </div>

      <div className="game">
        <div id="goodObjects">
          <input type="checkbox" className="goodObject object" />
          <input type="checkbox" className="goodObject object" />
          <input type="checkbox" className="goodObject object" />
          <input type="checkbox" className="goodObject object" />
          <input type="checkbox" className="goodObject object" />
          <input type="checkbox" className="goodObject object" />
          <input type="checkbox" className="goodObject object" />
          <input type="checkbox" className="goodObject object" />
          <input type="checkbox" className="goodObject object" />
          <input type="checkbox" className="goodObject object" />
        </div>

        <div id="badObjects">
          <input type="checkbox" className="badObject object" />
          <input type="checkbox" className="badObject object" />
          <input type="checkbox" className="badObject object" />
          <input type="checkbox" className="badObject object" />
          <input type="checkbox" className="badObject object" />
          <input type="checkbox" className="badObject object" />
          <input type="checkbox" className="badObject object" />
          <input type="checkbox" className="badObject object" />
          <input type="checkbox" className="badObject object" />
          <input type="checkbox" className="badObject object" />
        </div>
        <h3 className="score"></h3>
        <div className="countdownBar"></div>
      </div>
      <Button
        onClick={() => checkGameAndChallenge()}
        label="Terminer"
        primary
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentGame: state.games.currentGame,
  };
};

export default connect(mapStateToProps)(Recycle);
