import React from 'react';
import logoQuokka from '../../../styles/images/pat.svg';

const Card = ({ click, close, complete, emotion, openedEmotions }) => {
  return (
    <div
      className={
        'Card' + (!close ? ' opened' : '') + (complete ? ' matched' : '')
      }
      onClick={() =>
        (openedEmotions.length === 1 || openedEmotions.length === 0) &&
        click(emotion)
      }
    >
      <div className="Card_front">
        <img className="Card_front_image" src={logoQuokka} alt="img-quokka" />
      </div>
      <div className="Card_back">{emotion}</div>
    </div>
  );
};

export default Card;
