import React from 'react';
import { Route, Switch } from 'react-router-dom';
import 'regenerator-runtime/runtime.js';

import './App.css';

import PrivateRoute from './components/PrivateRoute';
import Signin from './components/Auth/Signin';
import Signup from './components/Auth/Signup';
import Home from './components/Home';
import FirstConnexion from './components/FirstConnexion';
import QuestionsContent from './components/Questions/QuestionsContent';
import Profil from './components/Profil/Profil';
import UpdateProfil from './components/Profil/UpdateProfil';
import Games from './components/Games/Games';
import ForgetPassword from './components/Auth/ForgetPassword';
import ResetPassword from './components/Auth/ResetPassword';
import SignupSuccess from './components/Auth/SignupSuccess';
import ForgetPasswordSuccess from './components/Auth/ForgetPasswordSuccess';
import ResetPasswordSuccess from './components/Auth/ResetPasswordSuccess';
import BoxPetals from './components/BoxPetals/BoxPetals';
import Admin from './components/Admin/Admin';
import Users from './components/Admin/Users/Users';
import ViewUser from './components/Admin/Users/ViewUser';

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={Signin} />
        <Route exact path="/inscription" component={Signup} />
        <Route exact path="/inscription-valide" component={SignupSuccess} />
        <Route exact path="/premiere-connexion" component={FirstConnexion} />
        <Route
          exact
          path="/mot-de-passe-reinitialiser"
          component={ResetPassword}
        />
        <Route
          exact
          path="/mot-de-passe-reinitialiser-valide"
          component={ResetPasswordSuccess}
        />
        <Route exact path="/mot-de-passe-oublie" component={ForgetPassword} />
        <Route
          exact
          path="/mot-de-passe-oublie-valide"
          component={ForgetPasswordSuccess}
        />
        <PrivateRoute
          path="/challenges/:idChallenge/questions"
          component={QuestionsContent}
        />
        <PrivateRoute exact path="/challenges" component={Home} />
        <PrivateRoute exact path="/mon-compte" component={Profil} />
        <PrivateRoute
          exact
          path="/modifier-mon-compte"
          component={UpdateProfil}
        />
        <PrivateRoute path="/games/:slug/:id" component={Games} />
        <PrivateRoute exact path="/boite-a-outils" component={BoxPetals} />
        <PrivateRoute exact path="/admin" component={Admin} />
        <PrivateRoute exact path="/utilisateurs" component={Users} />
        <PrivateRoute path="/utilisateurs/:id" component={ViewUser} />
      </Switch>
    </div>
  );
}

export default App;
