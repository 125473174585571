import React from 'react';

import Button from '../../../../stories/components/Button/Button';

import '../../../../styles/css/Games/Bot2/QuestionSituations.css';
const QuestionTime = (props) => {
  const times = [
    {
      id: 1,
      text: 'Quelques jours',
      handler: props.actionProvider.handleSomeTime,
    },
    {
      id: 2,
      text: 'Quelques mois',
      handler: props.actionProvider.handleSomeTime,
    },
    {
      id: 3,
      text: 'Quelques années',
      handler: props.actionProvider.handleSomeTime,
    },
  ];

  return (
    <div className="QuestionTime">
      {times.map((time) => (
        <Button
          key={time.id}
          label={time.text}
          primary
          onClick={() => time.handler(time.id, 2)}
        />
      ))}
    </div>
  );
};

export default QuestionTime;
