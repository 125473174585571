import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/css/alertMessage/alert.css';
import { AiFillWarning } from 'react-icons/ai';
import { IconContext } from 'react-icons';

/**
 * Primary UI component for user interaction
 */
export const Alert = ({ primary, backgroundColor, size, message }) => {
  const mode = primary
    ? 'storybook-alert--primary'
    : 'storybook-alert--secondary';
  return (
    <div
      className={`storybook-alert ${mode} storybook-alert--${size}`}
      style={{ backgroundColor }}
    >
      {primary && (
        <IconContext.Provider value={{ color: '#c02514' }}>
          <AiFillWarning />
        </IconContext.Provider>
      )}
      {message}
    </div>
  );
};

Alert.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  primary: PropTypes.bool,
  /**
   * What background color to use
   */
  backgroundColor: PropTypes.string,
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Alert contents
   */
  message: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

Alert.defaultProps = {
  backgroundColor: null,
  primary: false,
  size: 'medium',
  onClick: undefined,
};

export default Alert;
