import React from 'react';

const Options = (props) => {
  const options = [
    {
      text: 'Javascript',
      handler: props.actionProvider.handleJavascriptQuiz,
      id: 1,
    },
    {
      text: 'Python',
      handler: () => {},
      id: 2,
    },
    {
      text: 'Go',
      handler: () => {},
      id: 3,
    },
  ];

  return (
    <div className="options-container">
      {options.map((option) => (
        <button
          key={option.id}
          onClick={() => option.handler()}
          className="option-button"
        >
          {option.text}
        </button>
      ))}
    </div>
  );
};

export default Options;
