import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/css/button.css';

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  primary,
  backgroundColor,
  size,
  label,
  type,
  imageUrl,
  ...props
}) => {
  const mode = primary
    ? 'storybook-button--primary'
    : 'storybook-button--secondary';
  if (type === 'image') {
    return (
      <button
        {...props}
        className={`storybook-button-image ${
          props.isChecked ? 'isChecked' : 'isNotChecked'
        }`}
      >
        {imageUrl && <img src={imageUrl} alt={label} />}
        {label && <p>{label}</p>}
      </button>
    );
  }
  return (
    <button
      type="button"
      className={`storybook-button storybook-button--${size} ${mode}`}
      style={backgroundColor && { backgroundColor }}
      {...props}
    >
      {label}
    </button>
  );
};

Button.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  primary: PropTypes.bool,
  /**
   * What background color to use
   */
  backgroundColor: PropTypes.string,
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Button contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  imageUrl: PropTypes.string,
  type: PropTypes.string,
};

Button.defaultProps = {
  type: 'button',
  backgroundColor: null,
  primary: false,
  size: 'medium',
  onClick: undefined,
  imageUrl:
    'https://res.cloudinary.com/hu2cixefb/image/upload/v1618596079/images/depositphotos_264383222-stock-illustration-a-vector-quokka-illustration-style_1_s4y4fz.jpg',
};

export default Button;
