import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Parser from 'html-react-parser';

import { closeModal, openModal } from '../actions/index';

import Modal from '../../stories/components/Modal/Modal';

import iconWin from '../../styles/images/modal-win.svg';

import '../../styles/css/Games/PlutchickWheel.css';

const PlutchickWheel = ({
  currentGame,
  modal,
  closeModal,
  openModal,
  buttonTextModal,
  actionModal,
  imageModal,
  checkGameAndChallenge,
}) => {
  const [emotionsSelected, setEmotionsSelected] = useState(null);
  const [displayedEmotion, setDisplayedEmotion] = useState(null);

  const [testCombinaison, setTestCombinaison] = useState(null);
  const [emotionSecondary, setEmotionSecondary] = useState(null);

  const [count, setCount] = useState(0);

  useEffect(() => {
    if (currentGame.id_emotion) {
      let randomTextEmotion;
      switch (currentGame.id_emotion) {
        case 1:
          randomTextEmotion = Math.floor(
            Math.random() * handleEmotionsSecondarySadness.length
          );

          setEmotionSecondary(
            handleEmotionsSecondarySadness[randomTextEmotion]
          );
          break;
        case 21:
          randomTextEmotion = Math.floor(
            Math.random() * handleEmotionsSecondaryJoy.length
          );

          setEmotionSecondary(handleEmotionsSecondaryJoy[randomTextEmotion]);
          break;
        case 41:
          randomTextEmotion = Math.floor(
            Math.random() * handleEmotionsSecondarySurprise.length
          );

          setEmotionSecondary(
            handleEmotionsSecondarySurprise[randomTextEmotion]
          );
          break;
        case 81:
          randomTextEmotion = Math.floor(
            Math.random() * handleEmotionsSecondaryTrust.length
          );

          setEmotionSecondary(handleEmotionsSecondaryTrust[randomTextEmotion]);
          break;
        case 51:
          randomTextEmotion = Math.floor(
            Math.random() * handleEmotionsSecondaryDisgust.length
          );

          setEmotionSecondary(
            handleEmotionsSecondaryDisgust[randomTextEmotion]
          );
          break;
        case 71:
          randomTextEmotion = Math.floor(
            Math.random() * handleEmotionsSecondaryAnticipation.length
          );

          setEmotionSecondary(
            handleEmotionsSecondaryAnticipation[randomTextEmotion]
          );
          break;
        case 31:
          randomTextEmotion = Math.floor(
            Math.random() * handleEmotionsSecondaryFear.length
          );

          setEmotionSecondary(handleEmotionsSecondaryFear[randomTextEmotion]);
          break;
        case 61:
          randomTextEmotion = Math.floor(
            Math.random() * handleEmotionsSecondaryAnger.length
          );

          setEmotionSecondary(handleEmotionsSecondaryAnger[randomTextEmotion]);
          break;
        default:
          break;
      }
    }
  }, [emotionSecondary]);

  const handlePath = (id, path, emotionPosition, color, text) => {
    if (
      !testCombinaison ||
      (testCombinaison && id === emotionsSelected.emotion1) ||
      (testCombinaison && id === emotionsSelected.emotion2)
    ) {
      return (
        <g
          role="button"
          className="PlutchickWheel_petals"
          onClick={() => {
            if (
              id !== 2 ||
              id !== 3 ||
              id !== 4 ||
              id !== 5 ||
              id !== 6 ||
              id !== 7 ||
              id !== 8 ||
              id !== 9
            ) {
              if (count === 0) {
                setEmotionsSelected({ ...emotionsSelected, emotion1: id });
                setCount(count + 1);
              } else if (count === 1) {
                setEmotionsSelected({ ...emotionsSelected, emotion2: id });
                setCount(0);
              }
            }
          }}
          onMouseEnter={() => {
            if (
              id !== 2 &&
              id !== 3 &&
              id !== 4 &&
              id !== 5 &&
              id !== 6 &&
              id !== 7 &&
              id !== 8 &&
              id !== 9
            ) {
              setDisplayedEmotion(id);
            }
          }}
          onMouseLeave={() => setDisplayedEmotion(null)}
        >
          <path
            d={path}
            fill={
              displayedEmotion === id ||
              (emotionsSelected && emotionsSelected.emotion1 === id) ||
              (emotionsSelected && emotionsSelected.emotion2 === id)
                ? '#da2d64'
                : color
            }
          />
          <text
            textAnchor="middle"
            transform={emotionPosition}
            fill={handleTextColor(id)}
          >
            {text}
          </text>
        </g>
      );
    } else {
      return (
        <g
          role="button"
          className="PlutchickWheel_petals-opacity"
          onClick={() => {
            if (count === 0) {
              setEmotionsSelected({ ...emotionsSelected, emotion1: id });
              setCount(count + 1);
            } else if (count === 1) {
              setEmotionsSelected({ ...emotionsSelected, emotion2: id });
              setCount(0);
            }
          }}
        >
          <path d={path} fill={color} />
          <text
            textAnchor="middle"
            transform={emotionPosition}
            fill={handleTextColor(id)}
          >
            {text}
          </text>
        </g>
      );
    }
  };

  const handleTextColor = (idEmotion) => {
    switch (idEmotion) {
      case '0':
        return '#fd8740'; // Sérénité
      case '26':
        return '#fd8740'; // Extase
      case '2':
        return '#ffc231'; // Amour
      case '3':
        return '#75be52'; // Soumission
      case '4':
        return '#009b4e'; // Crainte
      case '5':
        return '#008fc3'; // Désappointement
      case '6':
        return '#1278ba'; // Remords
      case '7':
        return '#8168a7'; // Mépris
      case '8':
        return '#f84f57'; // Aggressivité
      case '9':
        return '#fd8740'; // Optimisme
      default:
        return '#fff';
    }
  };

  const handleEmotionsSecondarySadness = [
    'Désespoir',
    'Fadeur',
    'Désappointement',
    'Pessimisme',
    'Remords',
    'Envie',
  ];

  const handleCombinationSadness = () => {
    if (
      (Number(emotionsSelected.emotion1) === 1 ||
        Number(emotionsSelected.emotion2) === 1) &&
      (Number(emotionsSelected.emotion1) === 31 ||
        Number(emotionsSelected.emotion2) === 31)
    ) {
      return 'Désespoir';
    } else if (
      (Number(emotionsSelected.emotion1) === 1 ||
        Number(emotionsSelected.emotion2) === 1) &&
      (Number(emotionsSelected.emotion1) === 81 ||
        Number(emotionsSelected.emotion2) === 81)
    ) {
      return 'Fadeur';
    } else if (
      (Number(emotionsSelected.emotion1) === 1 ||
        Number(emotionsSelected.emotion2) === 1) &&
      (Number(emotionsSelected.emotion1) === 41 ||
        Number(emotionsSelected.emotion2) === 41)
    ) {
      return 'Désappointement';
    } else if (
      (Number(emotionsSelected.emotion1) === 1 ||
        Number(emotionsSelected.emotion2) === 1) &&
      (Number(emotionsSelected.emotion1) === 71 ||
        Number(emotionsSelected.emotion2) === 71)
    ) {
      return 'Pessimisme';
    } else if (
      (Number(emotionsSelected.emotion1) === 1 ||
        Number(emotionsSelected.emotion2) === 1) &&
      (Number(emotionsSelected.emotion1) === 51 ||
        Number(emotionsSelected.emotion2) === 51)
    ) {
      return 'Remords';
    } else if (
      (Number(emotionsSelected.emotion1) === 1 ||
        Number(emotionsSelected.emotion2) === 1) &&
      (Number(emotionsSelected.emotion1) === 61 ||
        Number(emotionsSelected.emotion2) === 61)
    ) {
      return 'Envie';
    }
    return 'Oups';
  };

  const handleEmotionsSecondaryJoy = [
    'Culpabilité',
    'Amour',
    'Ravissement',
    'Optimisme',
    'Morbidité',
    'Fierté',
  ];

  const handleCombinationJoy = () => {
    if (
      (Number(emotionsSelected.emotion1) === 21 ||
        Number(emotionsSelected.emotion2) === 21) &&
      (Number(emotionsSelected.emotion1) === 31 ||
        Number(emotionsSelected.emotion2) === 31)
    ) {
      return 'Culpabilité';
    } else if (
      (Number(emotionsSelected.emotion1) === 21 ||
        Number(emotionsSelected.emotion2) === 21) &&
      (Number(emotionsSelected.emotion1) === 81 ||
        Number(emotionsSelected.emotion2) === 81)
    ) {
      return 'Amour';
    } else if (
      (Number(emotionsSelected.emotion1) === 21 ||
        Number(emotionsSelected.emotion2) === 21) &&
      (Number(emotionsSelected.emotion1) === 41 ||
        Number(emotionsSelected.emotion2) === 41)
    ) {
      return 'Ravissement';
    } else if (
      (Number(emotionsSelected.emotion1) === 21 ||
        Number(emotionsSelected.emotion2) === 21) &&
      (Number(emotionsSelected.emotion1) === 71 ||
        Number(emotionsSelected.emotion2) === 71)
    ) {
      return 'Optimisme';
    } else if (
      (Number(emotionsSelected.emotion1) === 21 ||
        Number(emotionsSelected.emotion2) === 21) &&
      (Number(emotionsSelected.emotion1) === 51 ||
        Number(emotionsSelected.emotion2) === 51)
    ) {
      return 'Morbidité';
    } else if (
      (Number(emotionsSelected.emotion1) === 21 ||
        Number(emotionsSelected.emotion2) === 21) &&
      (Number(emotionsSelected.emotion1) === 61 ||
        Number(emotionsSelected.emotion2) === 61)
    ) {
      return 'Fierté';
    }
    return 'Oups';
  };
  const handleEmotionsSecondarySurprise = [
    'Crainte',
    'Curiosité',
    'Ravissement',
    'Désappointement',
    'Horreur',
    'Indignation',
  ];

  const handleCombinationSurprise = () => {
    if (
      (Number(emotionsSelected.emotion1) === 41 ||
        Number(emotionsSelected.emotion2) === 41) &&
      (Number(emotionsSelected.emotion1) === 31 ||
        Number(emotionsSelected.emotion2) === 31)
    ) {
      return 'Crainte';
    } else if (
      (Number(emotionsSelected.emotion1) === 41 ||
        Number(emotionsSelected.emotion2) === 41) &&
      (Number(emotionsSelected.emotion1) === 81 ||
        Number(emotionsSelected.emotion2) === 81)
    ) {
      return 'Curiosité';
    } else if (
      (Number(emotionsSelected.emotion1) === 41 ||
        Number(emotionsSelected.emotion2) === 41) &&
      (Number(emotionsSelected.emotion1) === 21 ||
        Number(emotionsSelected.emotion2) === 21)
    ) {
      return 'Ravissement';
    } else if (
      (Number(emotionsSelected.emotion1) === 41 ||
        Number(emotionsSelected.emotion2) === 41) &&
      (Number(emotionsSelected.emotion1) === 1 ||
        Number(emotionsSelected.emotion2) === 1)
    ) {
      return 'Désappoitement';
    } else if (
      (Number(emotionsSelected.emotion1) === 41 ||
        Number(emotionsSelected.emotion2) === 41) &&
      (Number(emotionsSelected.emotion1) === 51 ||
        Number(emotionsSelected.emotion2) === 51)
    ) {
      return 'Horreur';
    } else if (
      (Number(emotionsSelected.emotion1) === 41 ||
        Number(emotionsSelected.emotion2) === 41) &&
      (Number(emotionsSelected.emotion1) === 61 ||
        Number(emotionsSelected.emotion2) === 61)
    ) {
      return 'Indignation';
    }
    return 'Oups';
  };

  const handleEmotionsSecondaryTrust = [
    'Soumission',
    'Curiosité',
    'Amour',
    'Fadeur',
    'Domination',
    'Fatalisme',
  ];

  const handleCombinationTrust = () => {
    if (
      (Number(emotionsSelected.emotion1) === 81 ||
        Number(emotionsSelected.emotion2) === 81) &&
      (Number(emotionsSelected.emotion1) === 31 ||
        Number(emotionsSelected.emotion2) === 31)
    ) {
      return 'Soumission';
    } else if (
      (Number(emotionsSelected.emotion1) === 81 ||
        Number(emotionsSelected.emotion2) === 81) &&
      (Number(emotionsSelected.emotion1) === 41 ||
        Number(emotionsSelected.emotion2) === 41)
    ) {
      return 'Curiosité';
    } else if (
      (Number(emotionsSelected.emotion1) === 81 ||
        Number(emotionsSelected.emotion2) === 81) &&
      (Number(emotionsSelected.emotion1) === 21 ||
        Number(emotionsSelected.emotion2) === 21)
    ) {
      return 'Amour';
    } else if (
      (Number(emotionsSelected.emotion1) === 81 ||
        Number(emotionsSelected.emotion2) === 81) &&
      (Number(emotionsSelected.emotion1) === 1 ||
        Number(emotionsSelected.emotion2) === 1)
    ) {
      return 'Fadeur';
    } else if (
      (Number(emotionsSelected.emotion1) === 81 ||
        Number(emotionsSelected.emotion2) === 81) &&
      (Number(emotionsSelected.emotion1) === 61 ||
        Number(emotionsSelected.emotion2) === 61)
    ) {
      return 'Domination';
    } else if (
      (Number(emotionsSelected.emotion1) === 81 ||
        Number(emotionsSelected.emotion2) === 81) &&
      (Number(emotionsSelected.emotion1) === 71 ||
        Number(emotionsSelected.emotion2) === 71)
    ) {
      return 'Fatalisme';
    }
    return 'Oups';
  };

  const handleEmotionsSecondaryDisgust = [
    'Honte',
    'Horreur',
    'Morbidité',
    'Mépris',
    'Remords',
    'Cynisme',
  ];

  const handleCombinationDisgust = () => {
    if (
      (Number(emotionsSelected.emotion1) === 51 ||
        Number(emotionsSelected.emotion2) === 51) &&
      (Number(emotionsSelected.emotion1) === 31 ||
        Number(emotionsSelected.emotion2) === 31)
    ) {
      return 'Honte';
    } else if (
      (Number(emotionsSelected.emotion1) === 51 ||
        Number(emotionsSelected.emotion2) === 51) &&
      (Number(emotionsSelected.emotion1) === 41 ||
        Number(emotionsSelected.emotion2) === 41)
    ) {
      return 'Horreur';
    } else if (
      (Number(emotionsSelected.emotion1) === 51 ||
        Number(emotionsSelected.emotion2) === 51) &&
      (Number(emotionsSelected.emotion1) === 21 ||
        Number(emotionsSelected.emotion2) === 21)
    ) {
      return 'Morbidité';
    } else if (
      (Number(emotionsSelected.emotion1) === 51 ||
        Number(emotionsSelected.emotion2) === 51) &&
      (Number(emotionsSelected.emotion1) === 1 ||
        Number(emotionsSelected.emotion2) === 1)
    ) {
      return 'Remords';
    } else if (
      (Number(emotionsSelected.emotion1) === 51 ||
        Number(emotionsSelected.emotion2) === 51) &&
      (Number(emotionsSelected.emotion1) === 61 ||
        Number(emotionsSelected.emotion2) === 61)
    ) {
      return 'Mépris';
    } else if (
      (Number(emotionsSelected.emotion1) === 51 ||
        Number(emotionsSelected.emotion2) === 51) &&
      (Number(emotionsSelected.emotion1) === 71 ||
        Number(emotionsSelected.emotion2) === 71)
    ) {
      return 'Cynisme';
    }
    return 'Oups';
  };

  const handleEmotionsSecondaryAnticipation = [
    'Anxiété',
    'Cynisme',
    'Optimisme',
    'Pessimisme',
    'Aggressivité',
    'Fatalisme',
  ];

  const handleCombinationAnticipation = () => {
    if (
      (Number(emotionsSelected.emotion1) === 71 ||
        Number(emotionsSelected.emotion2) === 71) &&
      (Number(emotionsSelected.emotion1) === 31 ||
        Number(emotionsSelected.emotion2) === 31)
    ) {
      return 'Anxiété';
    } else if (
      (Number(emotionsSelected.emotion1) === 71 ||
        Number(emotionsSelected.emotion2) === 71) &&
      (Number(emotionsSelected.emotion1) === 51 ||
        Number(emotionsSelected.emotion2) === 51)
    ) {
      return 'Cynisme';
    } else if (
      (Number(emotionsSelected.emotion1) === 71 ||
        Number(emotionsSelected.emotion2) === 71) &&
      (Number(emotionsSelected.emotion1) === 21 ||
        Number(emotionsSelected.emotion2) === 21)
    ) {
      return 'Optimisme';
    } else if (
      (Number(emotionsSelected.emotion1) === 71 ||
        Number(emotionsSelected.emotion2) === 71) &&
      (Number(emotionsSelected.emotion1) === 1 ||
        Number(emotionsSelected.emotion2) === 1)
    ) {
      return 'Pessimisme';
    } else if (
      (Number(emotionsSelected.emotion1) === 71 ||
        Number(emotionsSelected.emotion2) === 71) &&
      (Number(emotionsSelected.emotion1) === 61 ||
        Number(emotionsSelected.emotion2) === 61)
    ) {
      return 'Agressivité';
    } else if (
      (Number(emotionsSelected.emotion1) === 71 ||
        Number(emotionsSelected.emotion2) === 71) &&
      (Number(emotionsSelected.emotion1) === 81 ||
        Number(emotionsSelected.emotion2) === 81)
    ) {
      return 'Fatalisme';
    }
    return 'Oups';
  };
  const handleEmotionsSecondaryFear = [
    'Crainte',
    'Honte',
    'Culpabilité',
    'Désespoir',
    'Anxiété',
    'Soumission',
  ];

  const handleCombinationFear = () => {
    if (
      (Number(emotionsSelected.emotion1) === 31 ||
        Number(emotionsSelected.emotion2) === 31) &&
      (Number(emotionsSelected.emotion1) === 41 ||
        Number(emotionsSelected.emotion2) === 41)
    ) {
      return 'Crainte';
    } else if (
      (Number(emotionsSelected.emotion1) === 31 ||
        Number(emotionsSelected.emotion2) === 31) &&
      (Number(emotionsSelected.emotion1) === 51 ||
        Number(emotionsSelected.emotion2) === 51)
    ) {
      return 'Honte';
    } else if (
      (Number(emotionsSelected.emotion1) === 31 ||
        Number(emotionsSelected.emotion2) === 31) &&
      (Number(emotionsSelected.emotion1) === 21 ||
        Number(emotionsSelected.emotion2) === 21)
    ) {
      return 'Culpabilité';
    } else if (
      (Number(emotionsSelected.emotion1) === 31 ||
        Number(emotionsSelected.emotion2) === 31) &&
      (Number(emotionsSelected.emotion1) === 1 ||
        Number(emotionsSelected.emotion2) === 1)
    ) {
      return 'Désespoir';
    } else if (
      (Number(emotionsSelected.emotion1) === 31 ||
        Number(emotionsSelected.emotion2) === 31) &&
      (Number(emotionsSelected.emotion1) === 71 ||
        Number(emotionsSelected.emotion2) === 71)
    ) {
      return 'Anxiété';
    } else if (
      (Number(emotionsSelected.emotion1) === 31 ||
        Number(emotionsSelected.emotion2) === 31) &&
      (Number(emotionsSelected.emotion1) === 81 ||
        Number(emotionsSelected.emotion2) === 81)
    ) {
      return 'Soumission';
    }
    return 'Oups';
  };
  const handleEmotionsSecondaryAnger = [
    'Indignation',
    'Mépris',
    'Fierté',
    'Envie',
    'Agressivité',
    'Domination',
  ];

  const handleCombinationAnger = () => {
    if (
      (Number(emotionsSelected.emotion1) === 61 ||
        Number(emotionsSelected.emotion2) === 61) &&
      (Number(emotionsSelected.emotion1) === 41 ||
        Number(emotionsSelected.emotion2) === 41)
    ) {
      return 'Indignation';
    } else if (
      (Number(emotionsSelected.emotion1) === 61 ||
        Number(emotionsSelected.emotion2) === 61) &&
      (Number(emotionsSelected.emotion1) === 51 ||
        Number(emotionsSelected.emotion2) === 51)
    ) {
      return 'Mépris';
    } else if (
      (Number(emotionsSelected.emotion1) === 61 ||
        Number(emotionsSelected.emotion2) === 61) &&
      (Number(emotionsSelected.emotion1) === 21 ||
        Number(emotionsSelected.emotion2) === 21)
    ) {
      return 'Fierté';
    } else if (
      (Number(emotionsSelected.emotion1) === 61 ||
        Number(emotionsSelected.emotion2) === 61) &&
      (Number(emotionsSelected.emotion1) === 1 ||
        Number(emotionsSelected.emotion2) === 1)
    ) {
      return 'Envie';
    } else if (
      (Number(emotionsSelected.emotion1) === 61 ||
        Number(emotionsSelected.emotion2) === 61) &&
      (Number(emotionsSelected.emotion1) === 71 ||
        Number(emotionsSelected.emotion2) === 71)
    ) {
      return 'Agressivité';
    } else if (
      (Number(emotionsSelected.emotion1) === 61 ||
        Number(emotionsSelected.emotion2) === 61) &&
      (Number(emotionsSelected.emotion1) === 81 ||
        Number(emotionsSelected.emotion2) === 81)
    ) {
      return 'Domination';
    }
    return 'Oups';
  };

  const handleCombination = () => {
    if (currentGame.id_emotion) {
      switch (currentGame.id_emotion) {
        case 1:
          return handleCombinationSadness();
          break;
        case 21:
          return handleCombinationJoy();
          break;
        case 41:
          return handleCombinationSurprise();
          break;
        case 81:
          return handleCombinationTrust();
          break;
        case 51:
          return handleCombinationDisgust();
          break;
        case 71:
          return handleCombinationAnticipation();
          break;
        case 31:
          return handleCombinationFear();
          break;
        case 61:
          return handleCombinationAnger();
          break;
        default:
          break;
      }
    }
  };

  const handleResponse = () => {
    setTestCombinaison(!testCombinaison);

    if (emotionSecondary === handleCombination()) {
      const textModal = 'Bravo';
      const textModalButton = 'Suivant';
      const type = 'win';
      const action = finishGame;
      const icon = iconWin;
      const descriptionModal = Parser(currentGame.text_win);

      const content = {
        icon,
        textModal,
        textModalButton,
        type,
        action,
        descriptionModal,
      };

      openModal(content);
    } else {
      setTimeout(() => {
        setTestCombinaison(false);
        setEmotionsSelected(null);
      }, 2000);
    }
  };

  const finishGame = () => {
    closeModal();
    checkGameAndChallenge();
  };

  return (
    <div
      className={`PlutchickWheel ${
        testCombinaison && 'PlutchickWheel_testCombinaison'
      }`}
    >
      <div className="PlutchickWheel_question">
        <p>Retrouve les émotions correspondantes</p>
        <div className="PlutchickWheel_question_emotion">
          <p className="PlutchickWheel_secondary">{emotionSecondary}</p>
          <p> est une émotions composée des 2 émotions primaires</p>
        </div>
      </div>
      <svg id="plutchik-wheel" viewBox="0 0 500 500">
        {handlePath(
          //   '0', // Sérénité
          '21', // Joie

          'M212.7,86.9C224.2,40.9,250,0,250,0h0s25.8,40.8,37.3,86.9A169.89,169.89,0,0,0,250,82.7,164.05,164.05,0,0,0,212.7,86.9Z',
          'translate(250 69)',
          '#ffea9b'
          //   'Sérénité'
        )}

        {handlePath(
          '21', // Joie
          'M250,155.2a95.15,95.15,0,0,0-36.3,7.2h0c-9.4-22.7-7.4-49.9-1-75.5A169.89,169.89,0,0,1,250,82.7a164.05,164.05,0,0,1,37.3,4.2c6.4,25.6,8.4,52.8-1,75.5h0A93.3,93.3,0,0,0,250,155.2Z',
          'translate(250 122)',
          '#ffd678',
          'Joie'
        )}
        {handlePath(
          //   '26', // Extase
          '21', // Joie
          'M250,250l-36.3-87.6h0a95.11,95.11,0,0,1,72.6,0Z',
          'translate(250 178)',
          '#ffc231'
          //   'Extase'
        )}
        {handlePath(
          //   '10', // Acceptation
          '81', // Confiance

          'M338.9,108.3c40.7-24.4,87.8-35,87.8-35h0s-10.6,47.1-35,87.8a175.62,175.62,0,0,0-23.4-29.4A162.47,162.47,0,0,0,338.9,108.3Z',
          'translate(397 107) rotate(-45)',
          '#c0da87'
          //   'Acceptation'
        )}
        {handlePath(
          '81', // Confiance
          'M317,183a94.15,94.15,0,0,0-30.8-20.6h0c9.4-22.7,30-40.5,52.6-54.1a175.62,175.62,0,0,1,29.4,23.4,162.47,162.47,0,0,1,23.4,29.4c-13.6,22.6-31.4,43.3-54.1,52.6h0A91.91,91.91,0,0,0,317,183Z',
          'translate(345 159) rotate(-45)',
          '#9acc68',
          'Confiance'
        )}
        {handlePath(
          //   '12', // Admiration
          '81', // Confiance
          'M250,250l36.3-87.6h0A92.72,92.72,0,0,1,317,183a96.43,96.43,0,0,1,20.6,30.7Z',
          'translate(293 211) rotate(-45)',
          '#75be52'
          //   'Admiration'
        )}
        {handlePath(
          //   '13', // Appréhension
          '31', // Peur
          'M413.1,212.7C459.1,224.2,500,250,500,250h0s-40.8,25.8-86.9,37.3a169.89,169.89,0,0,0,4.2-37.3A164.05,164.05,0,0,0,413.1,212.7Z',
          'translate(456 253)',
          '#63bf8f'
          //   'Appréhension'
        )}
        {handlePath(
          '31', // Peur
          'M344.8,250a95.15,95.15,0,0,0-7.2-36.3h0c22.7-9.4,49.9-7.4,75.5-1a169.89,169.89,0,0,1,4.2,37.3,164.05,164.05,0,0,1-4.2,37.3c-25.6,6.4-52.8,8.4-75.5-1h0A93.3,93.3,0,0,0,344.8,250Z',
          'translate(381 253)',
          '#00ac6e',
          'Peur'
        )}
        {handlePath(
          //   '14', // Terreur
          '31', // Peur
          'M250,250l87.6-36.3h0a95.11,95.11,0,0,1,0,72.6Z',
          'translate(308 253)',
          '#009b4e'
          //   'Terreur'
        )}
        {handlePath(
          //   '15', // Distraction
          '41', // Surprise
          'M391.7,338.9c24.4,40.7,35,87.8,35,87.8h0s-47.1-10.6-87.8-35a175.62,175.62,0,0,0,29.4-23.4A162.47,162.47,0,0,0,391.7,338.9Z',
          'translate(393 397) rotate(45)',
          '#75c0de'
          //   'Distraction'
        )}
        {handlePath(
          '41', // Surprise
          'M317,317a94.15,94.15,0,0,0,20.6-30.8h0c22.7,9.4,40.5,30,54.1,52.6a175.62,175.62,0,0,1-23.4,29.4,162.47,162.47,0,0,1-29.4,23.4c-22.6-13.6-43.3-31.4-52.6-54.1h0A91.91,91.91,0,0,0,317,317Z',
          'translate(341 345) rotate(45)',
          '#05a5ce',
          'Surprise'
        )}
        {handlePath(
          //   '16', // Étonnement
          '41', // Surprise
          'M250,250l87.6,36.3h0A92.72,92.72,0,0,1,317,317a96.43,96.43,0,0,1-30.7,20.6Z',
          'translate(289 293) rotate(45)',
          '#008fc3'
          //   'Etonnement'
        )}
        {handlePath(
          //   '17', // Songerie
          '1', // Tristesse
          'M287.3,413.1C275.8,459.1,250,500,250,500h0s-25.8-40.8-37.3-86.9a169.89,169.89,0,0,0,37.3,4.2A164.05,164.05,0,0,0,287.3,413.1Z',
          'translate(250 436)',
          '#92b7df'
          //   'Songerie'
        )}
        {handlePath(
          '1', // Tristesse
          'M250,344.8a95.15,95.15,0,0,0,36.3-7.2h0c9.4,22.7,7.4,49.9,1,75.5a169.89,169.89,0,0,1-37.3,4.2,164.05,164.05,0,0,1-37.3-4.2c-6.4-25.6-8.4-52.8,1-75.5h0A93.3,93.3,0,0,0,250,344.8Z',
          'translate(250 384)',
          '#639fd1',
          'Tristesse'
        )}
        {handlePath(
          //   '18', // Chagrin
          '1', // Tristesse
          'M250,250l36.3,87.6h0a95.11,95.11,0,0,1-72.6,0Z',
          'translate(250 327)',
          '#1278ba'
          //   'Chagrin'
        )}
        {handlePath(
          //   '19', // Ennui
          '51', // Dégoût
          'M161.1,391.7c-40.7,24.4-87.8,35-87.8,35h0s10.6-47.1,35-87.8a175.62,175.62,0,0,0,23.4,29.4A162.47,162.47,0,0,0,161.1,391.7Z',
          'translate(107 397) rotate(-45)',
          '#b2a0cb'
          //   'Ennui'
        )}
        {handlePath(
          '51', // Dégoût
          'M183,317a94.15,94.15,0,0,0,30.8,20.6h0c-9.4,22.7-30,40.5-52.6,54.1a175.62,175.62,0,0,1-29.4-23.4,162.47,162.47,0,0,1-23.4-29.4c13.6-22.6,31.4-43.3,54.1-52.6h0A91.91,91.91,0,0,0,183,317Z',
          'translate(159 345) rotate(-45)',
          '#9b85ba',
          'Dégout'
        )}
        {handlePath(
          //   '21', // Aversion
          '51', // Dégoût
          'M250,250l-36.3,87.6h0A92.72,92.72,0,0,1,183,317a96.43,96.43,0,0,1-20.6-30.7Z',
          'translate(212 293) rotate(-45)',
          '#8168a7'
          //   'Aversion'
        )}
        {handlePath(
          //   '22', // Contrariété
          '61', // Colère
          'M86.9,287.3C40.9,275.8,0,250,0,250H0s40.8-25.8,86.9-37.3A169.89,169.89,0,0,0,82.7,250,164.05,164.05,0,0,0,86.9,287.3Z',
          'translate(45 253)',
          '#fb8177'
          //   'Contrariété'
        )}
        {handlePath(
          '61', // Colère
          'M155.2,250a95.15,95.15,0,0,0,7.2,36.3h0c-22.7,9.4-49.9,7.4-75.5,1A169.89,169.89,0,0,1,82.7,250a164.05,164.05,0,0,1,4.2-37.3c25.6-6.4,52.8-8.4,75.5,1h0A93.3,93.3,0,0,0,155.2,250Z',
          'translate(119 253)',
          '#fa6764',
          'Colère'
        )}
        {handlePath(
          //   '23', // Rage
          '61', // Colère
          'M250,250l-87.6,36.3h0a95.11,95.11,0,0,1,0-72.6Z',
          'translate(192 253)',
          '#f84f57'
          //   'Rage'
        )}
        {handlePath(
          //   '24', // Intérêt
          '71', // Anticipation
          'M108.3,161.1c-24.4-40.7-35-87.8-35-87.8h0s47.1,10.6,87.8,35a175.62,175.62,0,0,0-29.4,23.4A162.47,162.47,0,0,0,108.3,161.1Z',
          'translate(103 107) rotate(45)',
          '#ffbc81'
          //   'Intérêt'
        )}
        {handlePath(
          '71', // Anticipation
          'M183,183a94.15,94.15,0,0,0-20.6,30.8h0c-22.7-9.4-40.5-30-54.1-52.6a175.62,175.62,0,0,1,23.4-29.4,162.47,162.47,0,0,1,29.4-23.4c22.6,13.6,43.3,31.4,52.6,54.1h0A91.91,91.91,0,0,0,183,183Z',
          'translate(155 159) rotate(45)',
          '#ffa263',
          'Anticipation'
        )}
        {handlePath(
          //   '25', // Vigilance
          '71', // Anticipation
          'M250,250l-87.6-36.3h0A92.72,92.72,0,0,1,183,183a96.43,96.43,0,0,1,30.7-20.6Z',
          'translate(207 212) rotate(45)',
          '#fd8740'
          //   'Vigilance'
        )}
        <g
          role="button"
          onClick={() =>
            emotionsSelected &&
            emotionsSelected.emotion1 &&
            emotionsSelected.emotion2 &&
            handleResponse()
          }
        >
          <circle cx="250" cy="250" r="60" fill="#ffffff" />
          <text
            textAnchor="middle"
            transform={'translate(250 250)'}
            fill={'rgba(141, 160, 165, 1)'}
          >
            {testCombinaison && handleCombination()}
            {!testCombinaison &&
              emotionsSelected &&
              emotionsSelected.emotion1 &&
              emotionsSelected.emotion2 &&
              'Combiner'}
          </text>
        </g>
      </svg>
      {modal.isOpen && (
        <Modal
          buttonText={buttonTextModal}
          actionModal={actionModal}
          image={imageModal}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentGame: state.games.currentGame,
    modal: state.modal,
    buttonTextModal: state.modal.buttonText,
    actionModal: state.modal.buttonAction,
    imageModal: state.modal.image,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (data) => {
      dispatch(openModal(data));
    },
    closeModal: (data) => {
      dispatch(closeModal(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlutchickWheel);
