import React from 'react';
import { connect } from 'react-redux';

import joie from '../../styles/images/joie.svg';
import question from '../../styles/images/emotionsDebrief/anticipation/question.svg';
import ancre from '../../styles/images/emotionsDebrief/anticipation/ancre.svg';
import fall from '../../styles/images/emotionsDebrief/anticipation/fall.svg';
import jump from '../../styles/images/emotionsDebrief/anticipation/jump.svg';
import calendar from '../../styles/images/emotionsDebrief/anticipation/calendar.svg';
import run from '../../styles/images/emotionsDebrief/anticipation/run.svg';

import elio from '../../styles/images/emotionsDebrief/anticipation/elio.png';

import '../../styles/css/Emotions/Anticipation.css';

const Anticipation = ({ currentEmotion }) => {
  return (
    <div className="Anticipation">
      <div className="Anticipation_left">
        <img src={joie} alt="logoTricky" className="Anticipation_left-tricky" />
        <div className="Anticipation_left-description">
          <h3>L'ANTICIPATION</h3>
          <p>{currentEmotion.what_first} </p>
        </div>
        <img
          className="Anticipation_left-image"
          src={elio}
          alt="Elio Anticipation"
        />
      </div>
      <div className="Anticipation_right">
        <h3 className="Anticipation_right-title">GERER SON ANTICIPATION</h3>
        <div className="Anticipation_right-question">
          <img src={question} alt="Pourquoi j'anticipe ?" />
          <div className="Anticipation_right-question-content">
            <p className="Anticipation_right-question-content-title">
              Pourquoi j'anticipe autant ?
            </p>
            <p>J'anticipe autant parce que...</p>
          </div>
        </div>
        <div className="Anticipation_right-fall">
          <div className="Anticipation_right-fall-content">
            <p className="Anticipation_right-fall-content-title">
              Roue de la vie
            </p>
            <p className="Anticipation_right-fall-content-text">
              Cet exercice permet de :
            </p>
            <div className="Anticipation_right-fall-content-lists">
              <div className="Anticipation_right-fall-content-list">
                <img src={ancre} alt="Visualiser attentes" />
                <p className="Anticipation_right-fall-content-list-text">
                  Visualiser ses attentes
                </p>
              </div>
              <div className="Anticipation_right-fall-content-list">
                <img src={ancre} alt="Cibler et prioriser" />
                <p className="Anticipation_right-fall-content-list-text">
                  Cibler et prioriser les points que l'on espère voir changer
                  dans sa vie.
                </p>
              </div>
            </div>
          </div>
          <img src={fall} alt="Roue de la vie" />
        </div>
        <div className="Anticipation_right-goals">
          <div className="Anticipation_right-goals-content">
            <p className="Anticipation_right-goals-content-title">
              Se fixer des objectifs
            </p>
            <div className="Anticipation_right-goals-lists">
              <div className="Anticipation_right-goals-list">
                <div className="Anticipation_right-goals-list-checkbox" />
                <p>
                  <strong>S</strong>pécifiques
                </p>
              </div>
              <div className="Anticipation_right-goals-list">
                <div className="Anticipation_right-goals-list-checkbox" />
                <p>
                  <strong>M</strong>esurables
                </p>
              </div>
              <div className="Anticipation_right-goals-list">
                <div className="Anticipation_right-goals-list-checkbox" />
                <p>
                  <strong>A</strong>tteignables
                </p>
              </div>
              <div className="Anticipation_right-goals-list">
                <div className="Anticipation_right-goals-list-checkbox" />
                <p>
                  <strong>R</strong>éalisables
                </p>
              </div>
              <div className="Anticipation_right-goals-list">
                <div className="Anticipation_right-goals-list-checkbox" />
                <p>
                  <strong>T</strong>emporellement définis
                </p>
              </div>
              <div className="Anticipation_right-goals-list">
                <div className="Anticipation_right-goals-list-checkbox" />
                <p>
                  <strong>E</strong>cologique pour soi
                </p>
              </div>
            </div>
          </div>
          <img src={jump} alt="Objectifs" />
        </div>
        <div className="Anticipation_right-calendar">
          <img src={calendar} alt="Un grand projet ?" />
          <div className="Anticipation_right-calendar-content">
            <p className="Anticipation_right-calendar-content-title">
              Un grand projet ?
            </p>
            <p className="Anticipation_right-calendar-content-text">
              Le rétroplanning est l'outil clef. Gérer son temps c'est
              s'organiser pour réussir !
            </p>
          </div>
        </div>
        <div className="Anticipation_right-fight">
          <div className="Anticipation_right-fight-content">
            <p className="Anticipation_right-fight-title">
              Vaincre la procrastination
            </p>
            <p>
              C'est lutter contre la culpabilité de remettre au lendemain et
              éviter le stress de faire les choses à la dernière minute.
            </p>
          </div>
          <img src={run} alt="procrastination" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentEmotion: state.emotions.currentEmotion,
  };
};

export default connect(mapStateToProps, null)(Anticipation);
