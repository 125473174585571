// ActionProvider starter code
class ActionProvider {
  constructor(createChatBotMessage, setStateFunc, createClientMessage) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
    this.createClientMessage = createClientMessage;
  }

  helloWorldHandler = () => {
    const message = this.createChatBotMessage("Salut je m'appelle Emeline");
    this.setChatbotMessage(message);
  };

  setChatbotMessage = (message) => {
    this.setState((state) => ({
      ...state,
      messages: [...state.messages, message],
    }));
  };

  setResponseOfUser = (response, question) => {
    this.setState((state) => ({
      ...state,
      responses: { ...state.responses, [`${question}`]: response },
    }));
  };

  handleLose = (response, question) => {
    const message = this.createChatBotMessage(
      "Je suis navré de l'apprendre, à combien de temps remonte cette perte ?",
      {
        widget: 'questionTime',
      }
    );
    this.setResponseOfUser(response, question);
    this.setChatbotMessage(message);
  };
  handleLosePhysical = (response, question) => {
    const message = this.createChatBotMessage(
      "Je suis navré de l'apprendre, depuis combien de temps constatez vous cette perte d'autonomie ?",
      {
        widget: 'questionTime',
      }
    );
    this.setResponseOfUser(response, question);
    this.setChatbotMessage(message);
  };
  handleSomeTime = (response, question) => {
    const message = this.createChatBotMessage(
      "La phase de négation peut durer dans le temps, donc ne t'inquiète pas si elle te parait longue. Prends le temps de bien analyser la situation afin de passer à la seconde phase de ton processus de deuil: la colère.",
      {
        widget: 'stepAnger',
      }
    );

    this.setResponseOfUser(response, question);
    this.setChatbotMessage(message);

    const message2 = this.createChatBotMessage(
      'Ressens-tu encore de la douleur ?',
      {
        widget: 'stepPain',
      }
    );

    setTimeout(() => {
      this.setChatbotMessage(message2);
    }, 5000);
  };

  handleBoolPain = (response, question) => {
    let message;
    if (response === 1) {
      message = this.createChatBotMessage(
        "Pourrais-tu m'exprimer ta colère et identifier celui que tu penses être 'coupable' de ton malheur ?",
        {
          widget: 'stepGuilty',
        }
      );
    } else {
      message = this.createChatBotMessage(
        'Bravo pour avoir passé cette étape, nous allons pouvoir nous focaliser sur la suite'
      );

      setTimeout(() => {
        this.handleNegociation();
      }, 2000);
    }
    this.setResponseOfUser(response, question);
    this.setChatbotMessage(message);
  };

  handleNegociation = (response, question) => {
    const message = this.createChatBotMessage(
      "La phase de colère peut durer dans le temps, donc ne t'inquiète pas si elle te paraît longue! Exprimer et identifier ta colère te permet d'avancer dans ton processus de deuil et de passer à la phase de négociation. "
    );
    if (response) {
      this.setResponseOfUser(response, question);
    }
    this.setChatbotMessage(message);

    const message2 = this.createChatBotMessage(
      "La négociation de notre perte nous permet de réfléchir au moyens pour retrouver ce que l'on a perdu, mais sans aggraver le problème! Il ne faut jamais se précipiter dans la prise de nos décisions, alors on prends toujours le temps de la réflexion."
    );
    const message3 = this.createChatBotMessage(
      "Penses-tu qu'il existe un moyen de retrouver ce que tu as perdu ?",
      {
        widget: 'questionLose',
      }
    );

    setTimeout(() => {
      this.setChatbotMessage(message2);
      setTimeout(() => {
        this.setChatbotMessage(message3);
      }, 4000);
    }, 4000);
  };
  handleExplainLose = (response, question) => {
    let message;
    if (response[5] === 1) {
      message = this.createChatBotMessage(
        "Pourrais-tu m'exprimer ce que tu pourrais faire pour retrouver ce que tu as perdu ?",
        {
          widget: 'explainLose',
        }
      );
    } else {
      message = this.createChatBotMessage(
        'Pourrais-tu me dire ce que tu ferais si tu retrouvais ce que tu as perdu ?',
        {
          widget: 'explainLose',
        }
      );
    }
    this.setResponseOfUser(response, question);
    this.setChatbotMessage(message);
  };

  handleDepression = (response, question) => {
    let message = this.createChatBotMessage(
      "Cette phase de déprime et de baisse de moral intervient lorsque tu prends conscience de la perte vécue. Ses conséquences te paraissent plus claires. L'étapes dépression n'est pas la plus simple, mais elle témoigne d'une amélioration psychique dans ton processus de deuil."
    );

    this.setResponseOfUser(response, question);
    this.setChatbotMessage(message);

    setTimeout(() => {
      let message2 = this.createChatBotMessage(
        'Traverses-tu actuellement un passage à vide ?',
        {
          widget: 'questionDepression',
        }
      );
      this.setChatbotMessage(message2);
    }, 4000);
  };

  handleBoolDepression = (response, question) => {
    let message;
    if (response === 1) {
      message = this.createChatBotMessage(
        "Comment qualifirais-tu ce que tu ressens ? Pourrais tu mettre des mots dessus et m'en dire d'avantage ? Cela te permettra de passer cette étape de dépression. Plus tu décriras ce que tu ressens, plus ce sera facile pour toi d'accepter la situation. Ex: Je ressens ... Cela signifie que ..."
      );
    } else {
      message = this.createChatBotMessage(
        "Bravo pour avoir passé cette étape qui n'est pas la plus facile ! Nous allons pouvoir nous focaliser sur la suite."
      );
      setTimeout(() => this.handleExplainDepression(), 2000);
    }
    this.setResponseOfUser(response, question);
    this.setChatbotMessage(message);
  };

  handleResponseFeeling = (response, question) => {
    this.setResponseOfUser(response, question);
    this.handleExplainDepression();
  };

  handleExplainDepression = () => {
    const message = this.createChatBotMessage(
      "La phase de dépression est une phase difficile. Ne t'inquiète pas si tu as l'impression qu'elle dure trop dans le temps, tu as toutes les cartes en main pour t'en sortir et passer à la dernière phase de ton processus de deuil: l'acceptation."
    );
    this.setChatbotMessage(message);

    const message2 = this.createChatBotMessage(
      "L'acceptation est la dernière étape de ton processus de deuil. Elle intervient seulement si les phases précédentes ont bien été menées. L'acceptation te permet de reconnaître et d'accepter sereinement la réalité de ta perte afin d'en tirer des bénéfices afin d'aller de l'avant."
    );

    const message3 = this.createChatBotMessage(
      'Arrives-tu à accepter la perte que  tu as vécue ?',
      {
        widget: 'questionAcceptation',
      }
    );

    setTimeout(() => {
      this.setChatbotMessage(message2);
      setTimeout(() => this.setChatbotMessage(message3), 5000);
    }, 3000);
  };

  handleBoolAcceptation = (response, question) => {
    let message;
    if (response === 1) {
      message = this.createChatBotMessage(
        "Nous sommes heureux d'apprendre que cette période soit derrière toi ou en voie de l'être. As-tu pu tirer, malgré la douleur, du positif dans cette situation ?",
        {
          widget: 'handleLesson',
        }
      );
    } else {
      message = this.createChatBotMessage(
        "Ne t'en fais pas si tu n'as pas encore réussi à passer l'ensemble des étapes de ton processus de deuil. Il s'agit d'un processus qui prend du temps et qui est vécu différement d'une personne à l'autre ? Prends donc le temps de bien passer chaque étape une à une, afin de tirer des avantages de cette situation et de percevoir un meilleur avenir "
      );

      setTimeout(() => this.handleFinishGame(), 5000);
    }
    this.setResponseOfUser(response, question);
    this.setChatbotMessage(message);
  };

  handleFinishGame = (response, question) => {
    const message = this.createChatBotMessage(
      "Merci, c'était un plaisir de discuter avec toi :) "
    );
    if (response) {
      this.setResponseOfUser(response, question);
    }
    this.setChatbotMessage(message);
  };
}

export default ActionProvider;
