import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Breathing from './Breathing';
import Comfort from './Comfort';
import List from './List';
import Spider from './Spider';
import Surprise from './Surprise';
import Bot from './Bot/Bot2';
import Memory from './Memory/Memory';
import Recycle from './Recycle';
import Tinder from './Tinder';
import PlutchickWheel from './PlutchickWheel';

import {
  resetCurrentGame,
  resetCurrentChallenge,
  changeStep,
  setPoints,
} from '../actions/index';

const Games = ({
  user,
  resetCurrentGame,
  currentChallenge,
  currentGame,
  changeStep,
  setPoints,
}) => {
  const history = useHistory();

  let Component;

  switch (currentGame.slug) {
    case 'Breathing':
      Component = Breathing;
      break;
    case 'Comfort':
      Component = Comfort;
      break;
    case 'List':
      Component = List;
      break;
    case 'Spider':
      Component = Spider;
      break;
    case 'Surprise':
      Component = Surprise;
      break;
    case 'Bot':
      Component = Bot;
      break;
    case 'Memory':
      Component = Memory;
      break;
    case 'Recycle':
      Component = Recycle;
      break;
    case 'Tinder':
      Component = Tinder;
      break;
    case 'PlutchickWheel':
      Component = PlutchickWheel;
      break;
    default:
      break;
  }

  const checkGameAndChallenge = (strokes, timer) => {
    changeStep(4)
    axios
      .get(
        `${process.env.REACT_APP_URL_SERVER}/api/games/slug/${currentGame.slug}`
      )
      .then((response) => response.data)
      .then((data) =>

        axios
          .put(
            `${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}/games/${data.id}`,
            {
              validate: 1,
              final_date: new Date().toISOString().slice(0, 19).replace('T', ' '),
              strokes,
              time: timer,
            }
          )
          .then((response) =>
            axios
              .put(
                `${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}/challenges/${currentChallenge.challenge_id}`,
                {
                  validate: 1,
                }
              )
              .then((response) => response.data)
          )
      )
      .then(() => {
        axios
          .put(
            `${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}/points`,
            {
              points:
                currentChallenge.number_try_choice + 1 + Number(user.points),
            }
          )
          .then(() =>
            setPoints(
              currentChallenge.number_try_choice + 1 + Number(user.points)
            )
          )
            })
      .finally(() => {
        finishChallenge()
      });
  };

  const finishChallenge = () => {
    axios
      .put(
        `${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}/challenges/${currentChallenge.challenge_id}`,
        { step: 4 }
      )
    setTimeout(() => {
      resetCurrentGame();
    }, 2000);
  };

  return (
    <Component
      slug={currentGame.slug}
      checkGameAndChallenge={checkGameAndChallenge}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    currentChallenge: state.challenges.currentChallenge,
    currentGame: state.games.currentGame,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetCurrentGame: () => {
      dispatch(resetCurrentGame());
    },
    resetCurrentChallenge: () => {
      dispatch(resetCurrentChallenge());
    },
    changeStep: (data) => {
      dispatch(changeStep(data));
    },
    setPoints: (data) => {
      dispatch(setPoints(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Games);
