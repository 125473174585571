import React, { useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchUser } from './actions';

const PrivateRoute = ({
  path,
  component: Component,
  fetchUser,
  exact,
  location,
  ...props
}) => {
  const history = useHistory();
  const search = location.search;
  const params = new URLSearchParams(search);
  const email = params.get('email');
  const token = params.get('token');

  useEffect(() => {
    if (email && token) {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_URL_SERVER}/api/users/profile`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          email,
          token,
        },
      })
        .then((response) => response.data)
        .then((data) => {
          localStorage.setItem('token', data.token);
          handleUser(data.authData);
        })
        .catch(() => history.push('/'));
    } else {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_URL_SERVER}/api/users/profile`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((response) => response.data)
        .then((data) => {
          handleUser(data);
        })
        .catch(() => history.push('/'));
    }
  }, []);

  const handleUser = (data) => {
    fetchUser(data);
    axios
      .get(`${process.env.REACT_APP_URL_SERVER}/api/users/${data.id}`)
      .then((response) => fetchUser(response.data));
  };

  return (
    <Route path={path} exact render={(props) => <Component {...props} />} />
  );
};

PrivateRoute.propTypes = {
  fetchUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: (data) => {
      dispatch(fetchUser(data));
    },
  };
};

export default connect(null, mapDispatchToProps)(PrivateRoute);
