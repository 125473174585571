import React from 'react';
import { connect } from 'react-redux';

import joie from '../../styles/images/joie.svg';
import temperature from '../../styles/images/emotionsDebrief/joy/temperature.svg';
import dance from '../../styles/images/emotionsDebrief/joy/dance.svg';
import question from '../../styles/images/emotionsDebrief/joy/question.svg';
import elio from '../../styles/images/emotionsDebrief/joy/elio.png';
import trophy from '../../styles/images/emotionsDebrief/joy/trophy.svg';

import '../../styles/css/Emotions/Joy.css';

const Joy = ({ currentEmotion }) => {
  return (
    <div className="Joy">
      <div className="Joy_left">
        <img src={joie} alt="logoTricky" className="Joy_left-tricky" />
        <div className="Joy_left-description">
          <h3>LA JOIE</h3>
          <p>{currentEmotion.what_first} </p>
        </div>
        <img className="Joy_left-image" src={elio} alt="Elio tristesse" />
      </div>
      <div className="Joy_right">
        <h3 className="Joy_right-title">CULTIVER SA JOIE</h3>
        <div className="Joy_right-temperature">
          <img
            className="Joy_right-temperature-image"
            src={temperature}
            alt="temperature"
          />
          <div className="Joy_right-temperature-right">
            <p>Quelle est l'intensité de ta joie ?</p>
            <p className="Joy_right-temperature-numbers">0 1 2 3 4 5</p>
          </div>
        </div>
        <div className="Joy_right-music-question">
          <div className="Joy_right-music">
            <img src={dance} alt="dance" />
            <p className="Joy_right-music-text">
              Ecouter une musique qui rend heureux
            </p>
          </div>
          <div className="Joy-right-question">
            <p className="Joy_right-question-text">
              Qu'est ce qui me rend heureux.se ?
            </p>
            <img src={question} alt="question" />
          </div>
        </div>
        <div className="Joy-right-lorem">
          <p className="Joy-right-lorem-latin">Mens sana in corpore sano</p>
          <p className="Joy-right-lorem-latin-description">
            Il est capital de prendre soin de son corps, de tout faire pour le
            maintenir en bonne santé et cela passe par une nutrition saine et
            équilibrée, un bon sommeil et de l'exercice physique.
          </p>
        </div>
        <div className="Joy-right-trophy">
          <img src={trophy} alt="Trophé" />
          <div className="Joy-right-trophy-content">
            <div className="Joy-right-trophy-content-lorem">
              <p className="Joy-right-trophy-content-lorem-text">
                La victoire sur soi est la plus grande des victoires.
              </p>
              <p className="Joy-right-trophy-content-lorem-author">(Platon)</p>
            </div>
            <p className="Joy-right-trophy-content-text">
              Il est important de fêter ses victoires, de les partager avec son
              entourage
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentEmotion: state.emotions.currentEmotion,
  };
};

export default connect(mapStateToProps, null)(Joy);
