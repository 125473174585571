import { combineReducers } from 'redux';
import user from './User';
import challenges from './Challenges';
import emotions from './Emotions';
import modal from './Modal';
import games from './Games';
import petals from './Petals';
import typesUser from './TypesUser';

export default combineReducers({
  user,
  challenges,
  emotions,
  modal,
  games,
  petals,
  typesUser,
});
