import React, { useState } from 'react';

import '../../../styles/css/Games/Bot2/Quiz.css';
const FlashCard = ({ question, answer, incrementIndex }) => {
  const [showAnswer, setShowAnswer] = useState(false);
  return (
    <div>
      <div
        className="flashcard-container"
        onClick={() => setShowAnswer(!showAnswer)}
      >
        {!showAnswer ? question : answer}
      </div>
      {showAnswer && (
        <button className="flashcard-button" onClick={() => incrementIndex()}>
          Suivant
        </button>
      )}
    </div>
  );
};

export default FlashCard;
