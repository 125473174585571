import React from 'react';
import { connect } from 'react-redux';

import joie from '../../styles/images/joie.svg';
import temperature from '../../styles/images/emotionsDebrief/disgust/temperature.svg';
import man from '../../styles/images/emotionsDebrief/disgust/man.svg';
import happy from '../../styles/images/emotionsDebrief/disgust/happy.svg';
import cook from '../../styles/images/emotionsDebrief/disgust/cook.svg';
import elio from '../../styles/images/emotionsDebrief/disgust/elio.png';

import '../../styles/css/Emotions/Disgust.css';

const Disgust = ({ currentEmotion }) => {
  return (
    <div className="Disgust">
      <div className="Disgust_left">
        <img src={joie} alt="logoTricky" className="Disgust_left-tricky" />
        <div className="Disgust_left-description">
          <h3>LE DEGOUT</h3>
          <p>{currentEmotion.what_first} </p>
        </div>
        <img className="Disgust_left-image" src={elio} alt="Elio Disgust" />
      </div>
      <div className="Disgust_right">
        <h3 className="Disgust_right-title">GERER SON DEGOUT</h3>
        <div className="Disgust_right-temperature">
          <img
            className="Disgust_right-temperature-image"
            src={temperature}
            alt="temperature"
          />
          <div className="Disgust_right-temperature-right">
            <p>Quelle est l'intensité de ton dégoût ?</p>
            <p className="Disgust_right-temperature-numbers">0 1 2 3 4 5</p>
          </div>
        </div>
        <div className="Disgust_right-question">
          <p className="Disgust_right-question-title">Le savais-tu ?</p>
          <p className="Disgust_right-question-text">
            Un mal-être au travail peut-être lié au dégoût. Lorsque vous n'êtes
            pas en phase avec vos valeurs, cela a uner répercussion sur votre
            psychisme et c'est cette émotion qui en est la cause.
          </p>
        </div>
        <div className="Disgust_right-principes">
          <img
            className="Disgust_right-principes-image"
            src={man}
            alt="Lister principes"
          />
          <p className="Disgust_right-principes-text">
            Lister ses principes et les prioriser pour connaître ceux qui sont
            les plus importants à retrouver autour de soi.
          </p>
        </div>
        <div className="Disgust_right-expression">
          <div className="Disgust_right-expression-content">
            <p className="Disgust_right-expression-content-title">
              Exprimer son dégoût !
            </p>
            <p className="Disgust_right-expression-content-text">
              Que cela soit à l'écrit ou à l'oral, prendre le temps de noter ou
              d'informer sur son aversion pour une chose ou une action.
            </p>
          </div>
          <img src={happy} alt="Exprimer son dégoût" />
        </div>
        <div className="Disgust_right-spirit">
          <img src={cook} alt="Esprit ouvert" />
          <div className="Disgust_right-spirit-content">
            <p className="Disgust_right-spirit-content-title">
              Gardond l'esprit ouvert !
            </p>
            <p className="Disgust_right-spirit-content-text">
              Nos goûts ne sont pas uniquement dictés pas nos sens, les
              paramètres socioculturels sont également à prendre en compte.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentEmotion: state.emotions.currentEmotion,
  };
};

export default connect(mapStateToProps, null)(Disgust);
