import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import {
  fetchUserPetals,
  addUserPetals,
  currentEmotion,
  resetCurrentEmotion,
} from '../actions/index';

import Joy from '../Emotions/Joy';
import Sadness from '../Emotions/Sadness';
import Trust from '../Emotions/Trust';
import Surprise from '../Emotions/Surprise';
import Disgust from '../Emotions/Disgust';
import Fear from '../Emotions/Fear';
import Angry from '../Emotions/Angry';
import Anticipation from '../Emotions/Anticipation';

import Button from '../../stories/components/Button/Button';

import Link from '../../stories/components/Link/Link';
import hearImage from '../../styles/images/heart.svg';
import petalImage from '../../styles/images/petals.svg';
import lockImage from '../../styles/images/lock.svg';

import '../../styles/css/BoxPetals/ListPetals.css';
const ListPetals = ({
  user,
  fetchUserPetals,
  addUserPetals,
  petalsOfUser,
  currentEmotion,
  addCurrentEmotion,
  resetCurrentEmotion,
}) => {
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}/petals`)
      .then((response2) => {
        fetchUserPetals();
        addUserPetals(response2.data);
      });
  }, [user]);

  const [seeEmotion, setSeeEmotion] = useState(null);
  const handlePath = (id, path, emotionPosition, color, text, isClickable) => {
    petalsOfUser.map((petal) => Number(id) === petal.id);
    if (
      petalsOfUser.length > 0 &&
      petalsOfUser.find((petal) => Number(id) === petal.id) !== undefined
    ) {
      return (
        <g
          role="button"
          onClick={() => isClickable && setSeeEmotion(id)}
          className="PlutchickWheel_petals"
        >
          <path d={path} fill={color} />
          <text
            textAnchor="middle"
            transform={emotionPosition}
            fill={handleTextColor(id)}
          >
            {text}
          </text>
        </g>
      );
    }

    return (
      <g className="PlutchickWheel_petals">
        <path className="PlutchickWheel_petals-path" d={path} fill={color} />
        <text
          textAnchor="middle"
          transform={emotionPosition}
          fill={handleTextColor(id)}
          className="PlutchickWheel_petals-blur"
        >
          {text}
        </text>
        <image
          transform={emotionPosition}
          href={lockImage}
          fill="black"
          x="-10"
          y="-10"
          height="20"
          width="20"
        />
      </g>
    );
  };
  const handleTextColor = (idEmotion) => {
    switch (idEmotion) {
      case '181':
        return '#fd8740'; // Sérénité
      case '201':
        return '#fd8740'; // Extase
      case '281':
        return '#ffc231'; // Amour
      case '271':
        return '#75be52'; // Soumission
      case '311':
        return '#009b4e'; // Crainte
      case '261':
        return '#008fc3'; // Désappointement
      case '251':
        return '#1278ba'; // Remords
      case '241':
        return '#8168a7'; // Mépris
      case '301':
        return '#f84f57'; // Aggressivité
      case '291':
        return '#fd8740'; // Optimisme
      default:
        return '#fff';
    }
  };

  useEffect(() => {
    if (seeEmotion !== null) {
      axios
        .get(`${process.env.REACT_APP_URL_SERVER}/api/emotions/${seeEmotion}`)
        .then((response) => addCurrentEmotion(response.data));
    }
  }, [seeEmotion]);

  const handleEmotion = () => {
    if (currentEmotion) {
      switch (currentEmotion.slug) {
        case 'joy':
          return <Joy />;
          break;
        case 'sadness':
          return <Sadness />;
          break;
        case 'trust':
          return <Trust />;
          break;
        case 'surprise':
          return <Surprise />;
          break;
        case 'fear':
          return <Fear />;
          break;
        case 'disgust':
          return <Disgust />;
          break;
        case 'anger':
          return <Angry />;
          break;
        case 'anticipation':
          return <Anticipation />;
          break;
        default:
          break;
      }
    }
  };
  return (
    <div className="ListPetals">
      <div>
        {user.picture_url ? (
          <img
            className="ListPetals_profil"
            src={user.picture_url}
            alt="Photo de profil"
          />
        ) : (
          <div className="ListPetals_profil" />
        )}
        <p>
          {user.firstname} {user.lastname}
        </p>
        <Link url="mon-compte" label="Mon compte" />
        <div className="ListPetals_bar" />
        <div className="ListPetals_content-tools">
          <div className="ListPetals_content-tool">
            {user.points} <img src={hearImage} />
          </div>
          <div className="ListPetals_content-tool">
            {user.petals} <img src={petalImage} />
          </div>
        </div>
      </div>
      {seeEmotion !== null ? (
        <div>
          <Button
            label="Retour"
            primary
            onClick={() => {
              setSeeEmotion(null);
              resetCurrentEmotion();
            }}
          />
          {handleEmotion()}
        </div>
      ) : (
        <div className="ListPetals_content">
          <div>
            <svg id="plutchik-wheel" viewBox="0 0 500 500">
              {handlePath(
                '181', // Joie
                'M212.7,86.9C224.2,40.9,250,0,250,0h0s25.8,40.8,37.3,86.9A169.89,169.89,0,0,0,250,82.7,164.05,164.05,0,0,0,212.7,86.9Z',
                'translate(250 69)',
                '#ffea9b',
                'Sérénité',
                false
              )}

              {handlePath(
                '21', // Joie
                'M250,155.2a95.15,95.15,0,0,0-36.3,7.2h0c-9.4-22.7-7.4-49.9-1-75.5A169.89,169.89,0,0,1,250,82.7a164.05,164.05,0,0,1,37.3,4.2c6.4,25.6,8.4,52.8-1,75.5h0A93.3,93.3,0,0,0,250,155.2Z',
                'translate(250 122)',
                '#ffd678',
                'Joie',
                true
              )}
              {handlePath(
                '201', // Extase
                'M250,250l-36.3-87.6h0a95.11,95.11,0,0,1,72.6,0Z',
                'translate(250 178)',
                '#ffc231',
                'Extase',
                false
              )}
              {handlePath(
                '151', // Acceptation
                'M338.9,108.3c40.7-24.4,87.8-35,87.8-35h0s-10.6,47.1-35,87.8a175.62,175.62,0,0,0-23.4-29.4A162.47,162.47,0,0,0,338.9,108.3Z',
                'translate(397 107) rotate(-45)',
                '#c0da87',
                'Acceptation',
                false
              )}
              {handlePath(
                '81', // Confiance
                'M317,183a94.15,94.15,0,0,0-30.8-20.6h0c9.4-22.7,30-40.5,52.6-54.1a175.62,175.62,0,0,1,29.4,23.4,162.47,162.47,0,0,1,23.4,29.4c-13.6,22.6-31.4,43.3-54.1,52.6h0A91.91,91.91,0,0,0,317,183Z',
                'translate(345 159) rotate(-45)',
                '#9acc68',
                'Confiance',
                true
              )}
              {handlePath(
                '171', // Admiration
                'M250,250l36.3-87.6h0A92.72,92.72,0,0,1,317,183a96.43,96.43,0,0,1,20.6,30.7Z',
                'translate(293 211) rotate(-45)',
                '#75be52',
                'Admiration',
                false
              )}
              {handlePath(
                '121', // Appréhension
                'M413.1,212.7C459.1,224.2,500,250,500,250h0s-40.8,25.8-86.9,37.3a169.89,169.89,0,0,0,4.2-37.3A164.05,164.05,0,0,0,413.1,212.7Z',
                'translate(456 253)',
                '#63bf8f',
                'Appréhension',
                false
              )}
              {handlePath(
                '31', // Peur
                'M344.8,250a95.15,95.15,0,0,0-7.2-36.3h0c22.7-9.4,49.9-7.4,75.5-1a169.89,169.89,0,0,1,4.2,37.3,164.05,164.05,0,0,1-4.2,37.3c-25.6,6.4-52.8,8.4-75.5-1h0A93.3,93.3,0,0,0,344.8,250Z',
                'translate(381 253)',
                '#00ac6e',
                'Peur',
                true
              )}
              {handlePath(
                '141', // Terreur
                'M250,250l87.6-36.3h0a95.11,95.11,0,0,1,0,72.6Z',
                'translate(308 253)',
                '#009b4e',
                'Terreur',
                false
              )}
              {handlePath(
                '91', // Distraction
                'M391.7,338.9c24.4,40.7,35,87.8,35,87.8h0s-47.1-10.6-87.8-35a175.62,175.62,0,0,0,29.4-23.4A162.47,162.47,0,0,0,391.7,338.9Z',
                'translate(393 397) rotate(45)',
                '#75c0de',
                'Distraction',
                false
              )}
              {handlePath(
                '41', // Surprise
                'M317,317a94.15,94.15,0,0,0,20.6-30.8h0c22.7,9.4,40.5,30,54.1,52.6a175.62,175.62,0,0,1-23.4,29.4,162.47,162.47,0,0,1-29.4,23.4c-22.6-13.6-43.3-31.4-52.6-54.1h0A91.91,91.91,0,0,0,317,317Z',
                'translate(341 345) rotate(45)',
                '#05a5ce',
                'Surprise',
                true
              )}
              {handlePath(
                '111', // Étonnement
                'M250,250l87.6,36.3h0A92.72,92.72,0,0,1,317,317a96.43,96.43,0,0,1-30.7,20.6Z',
                'translate(289 293) rotate(45)',
                '#008fc3',
                'Etonnement',
                false
              )}
              {handlePath(
                '221', // Songerie
                'M287.3,413.1C275.8,459.1,250,500,250,500h0s-25.8-40.8-37.3-86.9a169.89,169.89,0,0,0,37.3,4.2A164.05,164.05,0,0,0,287.3,413.1Z',
                'translate(250 436)',
                '#92b7df',
                'Songerie',
                false
              )}
              {handlePath(
                '1', // Tristesse
                'M250,344.8a95.15,95.15,0,0,0,36.3-7.2h0c9.4,22.7,7.4,49.9,1,75.5a169.89,169.89,0,0,1-37.3,4.2,164.05,164.05,0,0,1-37.3-4.2c-6.4-25.6-8.4-52.8,1-75.5h0A93.3,93.3,0,0,0,250,344.8Z',
                'translate(250 384)',
                '#639fd1',
                'Tristesse',
                true
              )}
              {handlePath(
                '321', // Chagrin
                'M250,250l36.3,87.6h0a95.11,95.11,0,0,1-72.6,0Z',
                'translate(250 327)',
                '#1278ba',
                'Chagrin',
                false
              )}
              {handlePath(
                '101', // Ennui
                'M161.1,391.7c-40.7,24.4-87.8,35-87.8,35h0s10.6-47.1,35-87.8a175.62,175.62,0,0,0,23.4,29.4A162.47,162.47,0,0,0,161.1,391.7Z',
                'translate(107 397) rotate(-45)',
                '#b2a0cb',
                'Ennui',
                false
              )}
              {handlePath(
                '51', // Dégoût
                'M183,317a94.15,94.15,0,0,0,30.8,20.6h0c-9.4,22.7-30,40.5-52.6,54.1a175.62,175.62,0,0,1-29.4-23.4,162.47,162.47,0,0,1-23.4-29.4c13.6-22.6,31.4-43.3,54.1-52.6h0A91.91,91.91,0,0,0,183,317Z',
                'translate(159 345) rotate(-45)',
                '#9b85ba',
                'Dégout',
                true
              )}
              {handlePath(
                '161', // Aversion
                'M250,250l-36.3,87.6h0A92.72,92.72,0,0,1,183,317a96.43,96.43,0,0,1-20.6-30.7Z',
                'translate(212 293) rotate(-45)',
                '#8168a7',
                'Aversion',
                false
              )}
              {handlePath(
                '191', // Contrariété
                'M86.9,287.3C40.9,275.8,0,250,0,250H0s40.8-25.8,86.9-37.3A169.89,169.89,0,0,0,82.7,250,164.05,164.05,0,0,0,86.9,287.3Z',
                'translate(45 253)',
                '#fb8177',
                'Contrariété',
                false
              )}
              {handlePath(
                '61', // Colère
                'M155.2,250a95.15,95.15,0,0,0,7.2,36.3h0c-22.7,9.4-49.9,7.4-75.5,1A169.89,169.89,0,0,1,82.7,250a164.05,164.05,0,0,1,4.2-37.3c25.6-6.4,52.8-8.4,75.5,1h0A93.3,93.3,0,0,0,155.2,250Z',
                'translate(119 253)',
                '#fa6764',
                'Colère',
                true
              )}
              {handlePath(
                '131', // Rage
                'M250,250l-87.6,36.3h0a95.11,95.11,0,0,1,0-72.6Z',
                'translate(192 253)',
                '#f84f57',
                'Rage',
                false
              )}
              {handlePath(
                '211', // Intérêt
                'M108.3,161.1c-24.4-40.7-35-87.8-35-87.8h0s47.1,10.6,87.8,35a175.62,175.62,0,0,0-29.4,23.4A162.47,162.47,0,0,0,108.3,161.1Z',
                'translate(103 107) rotate(45)',
                '#ffbc81',
                'Intérêt',
                false
              )}
              {handlePath(
                '71', // Anticipation
                'M183,183a94.15,94.15,0,0,0-20.6,30.8h0c-22.7-9.4-40.5-30-54.1-52.6a175.62,175.62,0,0,1,23.4-29.4,162.47,162.47,0,0,1,29.4-23.4c22.6,13.6,43.3,31.4,52.6,54.1h0A91.91,91.91,0,0,0,183,183Z',
                'translate(155 159) rotate(45)',
                '#ffa263',
                'Anticipation',
                true
              )}
              {handlePath(
                '231', // Vigilance
                'M250,250l-87.6-36.3h0A92.72,92.72,0,0,1,183,183a96.43,96.43,0,0,1,30.7-20.6Z',
                'translate(207 212) rotate(45)',
                '#fd8740',
                'Vigilance',
                false
              )}
              {handlePath(
                '281', // Amour
                'M286.3,162.4C312.4,99.4,250.9,1.4,250,0A249.25,249.25,0,0,1,426.8,73.2S312.6,98.9,286.3,162.4Z',
                'translate(331 54) rotate(22.5)',
                '#fff6dc',
                'Amour',
                false
              )}
              {handlePath(
                '271', // Soumission
                'M337.6,213.7c63-26.1,88.8-138.9,89.2-140.5A249.59,249.59,0,0,1,500,250S401.1,187.4,337.6,213.7Z',
                'translate(446 169) rotate(67.5)',
                '#f5ffd9',
                'Soumission',
                false
              )}
              {handlePath(
                '311', // Crainte
                'M337.6,286.3c63,26.1,161-35.4,162.4-36.3a249.42,249.42,0,0,1-73.2,176.8S401.1,312.6,337.6,286.3Z',
                'translate(451 333) rotate(-67.5)',
                '#d9ffea',
                'Crainte',
                false
              )}
              {handlePath(
                '261', // Désappointement
                'M286.3,337.6c26.1,63,138.9,88.8,140.5,89.2A249.59,249.59,0,0,1,250,500S312.6,401.1,286.3,337.6Z',
                'translate(333 451) rotate(-22.5)',
                '#d9f6ff',
                'Désappointement',
                false
              )}
              {handlePath(
                '251', // Remords
                'M213.7,337.6c-26.1,63,35.4,161,36.3,162.4A249.42,249.42,0,0,1,73.2,426.8S187.4,401.1,213.7,337.6Z',
                'translate(167 451) rotate(22.5)',
                '#d9edff',
                'Remords',
                false
              )}
              {handlePath(
                '241', // Mépris
                'M162.4,286.3c-63,26.1-88.8,138.9-89.2,140.5A249.59,249.59,0,0,1,0,250S98.9,312.6,162.4,286.3Z',
                'translate(49 333) rotate(67.5)',
                '#e8d9ff',
                'Mépris',
                false
              )}
              {handlePath(
                '301', // Aggressivité
                'M162.4,213.7C99.4,187.6,1.4,249.1,0,250A249.25,249.25,0,0,1,73.2,73.2S98.9,187.4,162.4,213.7Z',
                'translate(54 169) rotate(-67.5)',
                '#ffdbd9',
                'Aggressivité',
                false
              )}
              {handlePath(
                '291', // Optimisme
                'M213.7,162.4C187.6,99.4,74.8,73.6,73.2,73.2A249.59,249.59,0,0,1,250,0S187.4,98.9,213.7,162.4Z',
                'translate(169 54) rotate(-22.5)',
                '#ffe8d9',
                'Optimisme',
                false
              )}
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    allPetals: state.petals.allPetals,
    petalsOfUser: state.petals.petalsOfUser,
    currentEmotion: state.emotions.currentEmotion,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserPetals: (data) => {
      dispatch(fetchUserPetals(data));
    },
    addUserPetals: (data) => {
      dispatch(addUserPetals(data));
    },
    addCurrentEmotion: (data) => {
      dispatch(currentEmotion(data));
    },
    resetCurrentEmotion: (data) => {
      dispatch(resetCurrentEmotion(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListPetals);
