import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import Button from '../../stories/components/Button/Button';
import Intro from '../../components/Auth/Intro';
import NavBar from './NavBar';

import { IconContext } from 'react-icons';
import { FaMedal, FaUsers } from 'react-icons/fa';
import { ImUserCheck } from 'react-icons/im';

import '../../styles/css/Admin/Admin.css';

const Admin = ({ user }) => {
  const history = useHistory();
  const [allUsers, setAllUsers] = useState([]);
  const [numberUserFinishChallenges, setNumberUserFinishChallenges] =
    useState(0);
  const [listFirstChallenge, setListFirstChallenge] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL_SERVER}/api/users`)
      .then((results) => results.data)
      .then((data) => {
        setAllUsers(data);
        data.map((userDetails) => {
          axios
            .get(
              `${process.env.REACT_APP_URL_SERVER}/api/users/${userDetails.id}/challenges`
            )
            .then((results) => results.data)
            .then((data2) => {
              const challengesFinish = data2.filter(
                (challenge) => challenge.validate
              );
              axios
                .get(`${process.env.REACT_APP_URL_SERVER}/api/challenges`)
                .then((results) => results.data)
                .then((data3) => {
                  if (challengesFinish.length >= data3.length) {
                    let newNumberUserFinish = numberUserFinishChallenges;
                    newNumberUserFinish += 1;

                    setNumberUserFinishChallenges(newNumberUserFinish);
                  }
                });
            });
        });
      });

    axios
      .get(`${process.env.REACT_APP_URL_SERVER}/api/usersChallenges`)
      .then((response) => response.data)
      .then((data) => {
        {
          let idsChallenge = [];
          data.filter((challenge) => {
            idsChallenge.push(challenge.id);
          });
          const newIdsChallenge = [...new Set(idsChallenge)];
          const challengeRecurent = newIdsChallenge;
          challengeRecurent.map(
            (id, index) =>
              (challengeRecurent[index] = {
                id,
                numberOfChallenge: 0,
              })
          );

          challengeRecurent.map((idChallenge, index) =>
            data.filter((challenge) => {
              if (challenge.id === idChallenge.id) {
                challengeRecurent[index].numberOfChallenge += 1;
                challengeRecurent[index].challenge = challenge;
              }
            })
          );

          challengeRecurent.sort(
            (firstElement, secondElement) =>
              secondElement.numberOfChallenge - firstElement.numberOfChallenge
          );

          const newListFirstChallenge = challengeRecurent.filter(
            (elementChallenge, index) =>
              (index === 0 || index === 1 || index === 2) && elementChallenge
          );

          setListFirstChallenge(newListFirstChallenge);
        }
      });
  }, []);

  const handleNumberOfUsersFinishAllChallenges = () => {
    const numberOfUsers = allUsers.length;

    if (numberUserFinishChallenges === 0 && !numberOfUsers) {
      return <p> ...en cours</p>;
    }

    return <p className="Admin_users-number">{numberUserFinishChallenges}</p>;
  };

  if (Object.keys(user).length === 0 || !user.role === 'admin') {
    return (
      <div className="Admin_redirection">
        <Intro />
        <div className="Admin_redirection-content">
          Vous ne pouvez pas accèder à cette page sans autorisation, veuillez
          vous identifier
          <Button
            onClick={() => history.push('/')}
            primary
            label="Me connecter"
          />
        </div>
      </div>
    );
  }

  const handleColor = (index) => {
    switch (index) {
      case 0:
        return '#ffd700';
        break;
      case 1:
        return '#c0c0c0';
        break;
      case 2:
        return 'white';
        break;
      default:
        break;
    }
  };

  return (
    <div className="Admin">
      <NavBar />
      <div className="Admin_content">
        <div className="Challenges_introduction">
          <div className="Challenges_introduction-left">
            <h3>Bienvenue sur votre tableau de bord ! </h3>
            <p>
              Vous pouvez visualiser le top 3 des challenges, la listes des
              utilisateurs....
            </p>
          </div>
          <div className="Admin_introduction-right">
            <div className="Admin_title">
              <p>Utilisateurs</p>
              <p>Terminer les challenges</p>
            </div>
            <div className="Admin_users">
              <div className="Admin_users-content">
                <p className="Admin_users-number">{allUsers.length}</p>
                <IconContext.Provider value={{ color: 'white' }}>
                  <FaUsers />
                </IconContext.Provider>
              </div>
              <div className="Admin_users-content">
                {handleNumberOfUsersFinishAllChallenges()}
                <IconContext.Provider value={{ color: 'white' }}>
                  <ImUserCheck />
                </IconContext.Provider>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p>Classements challenges</p>
          <div className="Admin_top-challenges">
            {listFirstChallenge &&
              listFirstChallenge.map((challengeElement, index) => (
                <button
                  className="ViewUser_challenge"
                  style={{
                    backgroundColor: challengeElement.challenge.color,
                  }}
                >
                  <p>
                    Top {index + 1}
                    <IconContext.Provider value={{ color: handleColor(index) }}>
                      <FaMedal />
                    </IconContext.Provider>
                  </p>
                  <p>{challengeElement.challenge.name}</p>
                  <p>{challengeElement.numberOfChallenge} utilisateurs</p>
                </button>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

Admin.propTypes = {
  user: PropTypes.shape({}).isRequired,
};

export default connect(mapStateToProps, null)(Admin);
