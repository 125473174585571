const initialState = {
  isOpen: false,
};

const Modal = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        isOpen: true,
        icon: action.payload.icon,
        text: action.payload.textModal,
        buttonText: action.payload.textModalButton,
        type: action.payload.type,
        buttonAction: action.payload.action,
        image: action.payload.image,
        descriptionModal: action.payload.descriptionModal,
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        isOpen: false,
        text: null,
        buttonText: null,
        type: null,
      };
    default:
      return state;
  }
};

export default Modal;
