import React, { useState } from 'react';

import Button from '../../../../stories/components/Button/Button';

import '../../../../styles/css/Games/Bot2/QuestionLesson.css';
const QuestionLesson = (props) => {
  const [inputs, setInputs] = useState();

  return (
    <div className="QuestionLesson">
      <p>J'ai irrémédiablement perdu</p>{' '}
      <input
        className="QuestionLesson_input"
        placholder="remplir"
        onChange={(e) => setInputs({ ...inputs, firstInput: e.target.value })}
      />
      <p>Mais en même temps, j'ai gagné.e </p>
      <input
        className="QuestionLesson_input"
        placeholer="remplir"
        onChange={(e) => setInputs({ ...inputs, secondInput: e.target.value })}
      />
      <Button
        label="Valider"
        primary
        onClick={() => props.actionProvider.handleFinishGame(inputs, 10)}
      />
    </div>
  );
};

export default QuestionLesson;
