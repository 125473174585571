import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';

import TypeOfChallenges from './TypeOfChallenges';
import Link from '../../stories/components/Link/Link';

import '../../styles/css/Challenges/Challenges.css';
import petals from '../../styles/images/petals.svg';
import heart from '../../styles/images/logo-tools-active.svg';

const Challenges = ({ user }) => {
  const [types, setTypes] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL_SERVER}/api/types`)
      .then((response) => setTypes(response.data));
  }, []);

  return (
    <div className="Challenges">
      <div className="Challenges_introduction">
        <div className="Challenges_introduction-left">
          <h3>
            Bonjour {user.firstname}, bienvenue sur votre tableau de bord !{' '}
          </h3>
          <p>
            Parcourez et débloquez les challenges qui s'offrent à vous pour en
            apprendre plus sur les émotions et apprendre à les gérer
          </p>
        </div>
        <div className="Challenges_introduction-right">
          <div className="Challenges_introduction-right-user">
            {user.picture_url ? (
              <img
                className="Challenges_introduction-right-user-picture"
                src={user.picture_url}
                alt="Photo de profil"
              />
            ) : (
              <div className="Challenges_introduction-right-user-picture" />
            )}
            <div className="Challenges_introduction-right-user-details">
              <p>
                {user.firstname} {user.lastname}
              </p>
              <Link url="/mon-compte" label="Mon compte" />
            </div>
          </div>
          <div className="Challenges_introduction-right-score">
            <div className="Challenges_introduction-right-score-points">
              <p>Score</p>
              <div className="Challenges_introduction-right-score-points-number">
                {user.points}
                <img src={heart} alt="points utilisateur" />
              </div>
            </div>
            <div className="Challenges_introduction-right-score-petals">
              <p>Pétales</p>
              <div className="Challenges_introduction-right-score-petals-number">
                {user.petals}
                <img src={petals} alt="petales utilisateur" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {types
        .filter((type) => user.type === type.id)
        .map((type) => (
          <TypeOfChallenges {...type} />
        ))}
      {types
        .filter((type) => user.type !== type.id)
        .map((type) => (
          <TypeOfChallenges {...type} />
        ))}
    </div>
  );
};

Challenges.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Challenges);
