import React, { useState } from 'react';
import Button from '../../../../stories/components/Button/Button';
import '../../../../styles/css/Games/Bot2/StepGuilty.css';
const StepGuilty = (props) => {
  const [inputs, setInputs] = useState();

  return (
    <div className="StepGuilty">
      <p>Je suis faché contre</p>
      <input
        className="StepGuilty_input"
        placholder="remplir"
        onChange={(e) => setInputs({ ...inputs, firstInput: e.target.value })}
      />
      <p>parce que</p>
      <input
        className="StepGuilty_input"
        placeholer="remplir"
        onChange={(e) => setInputs({ ...inputs, secondInput: e.target.value })}
      />
      <Button
        label="Valider"
        primary
        onClick={() => props.actionProvider.handleNegociation(inputs, 4)}
      />
    </div>
  );
};
export default StepGuilty;
