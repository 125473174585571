const initialState = {
  currentChallenge: null,
  currentAnswers: [],
  allChallenges: {},
  loading: true,
  error: null,
};

const Challenges = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_ALLCHALLENGES':
      return {
        ...state,
        loading: true,
      };
    case 'ADD_ALLCHALLENGES':
      return {
        ...state,
        allChallenges: {
          ...state.allChallenges,
          [action.payload.idType]: action.payload.data,
        },
        loading: false,
      };
    case 'UPDATE_ALLCHALLENGES':
      const newArrayChallenge = [];
      state.allChallenges[action.payload.idType]
        .filter((challenge) => challenge.id === action.payload.idChallenge)
        .map((challenge) => challenge);
      return {
        ...state,
        allChallenges: {
          ...state.allChallenges,
          [action.payload.idType]: [...newArrayChallenge],
        },
        loading: false,
      };
    case 'ERROR_ALLCHALLENGES':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case 'CURRENT_CHALLENGE':
      return {
        ...state,
        currentChallenge: action.payload,
      };
    case 'RESET_CURRENT_CHALLENGE':
      return {
        ...state,
        currentChallenge: null,
        currentAnswers: [],
      };
    case 'FETCH_CHALLENGE_ANSWERS':
      return {
        ...state,
        currentAnswers: action.payload,
      };
    case 'ADD_RESPONSE_FEELING':
      return {
        ...state,
        currentChallenge: {
          ...state.currentChallenge,
          response_feeling: action.payload,
          step: 1,
        },
      };
    case 'ADD_RESPONSE_CHOICE':
      return {
        ...state,
        currentChallenge: {
          ...state.currentChallenge,
          response_choice: action.payload,
          step: 2,
        },
      };
    case 'SET_TRY_CHOICE':
      return {
        ...state,
        currentChallenge: {
          ...state.currentChallenge,
          number_try_choice: action.payload,
        },
      };
    case 'CHANGE_STEP':
      return {
        ...state,
        currentChallenge: {
          ...state.currentChallenge,
          step: action.payload,
        },
      };
    default:
      return state;
  }
};

export default Challenges;
