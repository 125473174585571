import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';

import { useHistory } from 'react-router-dom';

import { updateUser as updateUserAction } from '../actions/index';

import Input from '../../stories/components/Input/Input';
import Button from '../../stories/components/Button/Button';
import Alert from '../../stories/components/Alert/Alert';

import '../../styles/css/Profil/UpdateProfil.css';

const UpdateProfil = ({ user, updateUser }) => {
  const [inputs, setInputs] = useState({
    firstname: '',
    lastname: '',
    email: '',
  });

  const [errorMessage, setErrorMessage] = useState(null);
  const [goodMessage, setGoodMessage] = useState(null);

  const history = useHistory();

  useEffect(() => {
    setInputs({
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
    });
  }, [user]);

  const dataFile = new FormData();

  const modifyUser = () => {
    axios
      .put(`${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}`, inputs)
      .then((response) => changeToken(response.data))
      .catch((err) =>
        setErrorMessage(
          'Une erreur est survenue lors de la modification de votre profil, veuillez réessayé'
        )
      );
  };

  const changeToken = (data) => {
    localStorage.setItem('token', data.token);
    updateUser(data.user);
    setGoodMessage('Modification réussi');
    setTimeout(() => history.push('/mon-compte'), 1000);
  };

  return (
    <div className="UpdateProfil">
      <div className="UpdateProfil_form">
        <div className="Update_form_inputs">
          <Input
            primary
            type="text"
            label="Nom"
            value={inputs.lastname}
            name="lastname"
            onClick={() => setInputs({ ...inputs, lastname: '' })}
            onChange={(e) =>
              setInputs({ ...inputs, [e.target.name]: e.target.value })
            }
          />
          <Input
            primary
            type="text"
            label="Prénom"
            value={inputs.firstname}
            name="firstname"
            onClick={() => setInputs({ ...inputs, firstname: '' })}
            onChange={(e) =>
              setInputs({ ...inputs, [e.target.name]: e.target.value })
            }
          />
          <Input
            primary
            type="email"
            label="Email"
            value={inputs.email}
            name="email"
            onClick={() => setInputs({ ...inputs, email: '' })}
            onChange={(e) =>
              setInputs({ ...inputs, [e.target.name]: e.target.value })
            }
          />
          <Input
            primary
            type="text"
            label="Mot de passe"
            value={inputs.password}
            name="password"
            onClick={() => setInputs({ ...inputs, password: '' })}
            onChange={(e) =>
              setInputs({ ...inputs, [e.target.name]: e.target.value })
            }
          />
        </div>
        {errorMessage && <Alert message={errorMessage} primary />}
        {goodMessage && <Alert message={goodMessage} />}
        <Button label="Modifier" primary onClick={() => modifyUser()} />
      </div>
    </div>
  );
};

UpdateProfil.propTypes = {
  history: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (data) => {
      dispatch(updateUserAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfil);
