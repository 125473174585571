import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  fetchChallengeAnswers,
  openModal,
  addResponseChoice,
  closeModal,
  setTryChoice,
  changeStep,
} from '../actions/index';

import Button from '../../stories/components/Button/Button';
import InputRadio from '../../stories/components/Input/InputRadio';
import Alert from '../../stories/components/Alert/Alert';

import '../../styles/css/Questions/QuestionChoice.css';

import iconBad from '../../styles/images/modal-bad.svg';
import iconWin from '../../styles/images/modal-win.svg';

const QuestionChoice = ({
  challenge,
  fetchChallengeAnswers,
  currentAnswers,
  user,
  openModal,
  closeModal,
  addResponseChoice,
  setTryChoice,
  changeStep,
  currentChallenge,
}) => {
  const [sortCurrentAnswer, setSortCurrentAnswer] = useState(null);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_URL_SERVER}/api/challenges/${challenge.challenge_id}/choices`
      )
      .then((response) => response.data)
      .then((data) => {
        fetchChallengeAnswers(data);
      });
    setSortCurrentAnswer(currentAnswers.sort(() => Math.random() - 0.5));
  }, [currentAnswers.length]);

  const [choice, setChoice] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const history = useHistory();
  const [numberTry, setNumberTry] = useState(3);

  const handleChoice = () => {
    if (choice !== null) {
      const answerSelected = currentAnswers.filter((answer) =>
        choice.includes(answer.id)
      );

      const goodAnswer =
        answerSelected.filter((answer) => answer.goodAnswer === 1).length > 0;
      const wrongAnswer =
        answerSelected.filter((answer) => answer.goodAnswer === 0).length > 0;
      let textModal;
      let textModalButton;
      let type;
      let action;
      let icon;
      let descriptionModal;

      if (wrongAnswer && numberTry - 1 !== 0) {
        setNumberTry(numberTry - 1);
        textModal = 'Mauvaise réponse';
        textModalButton = 'Réessayer';
        type = 'wrong';
        icon = iconBad;
        action = closeModal;
        descriptionModal = `Il y avait une meilleure réaction face à cette situation. Il te reste ${
          numberTry - 1
        } essai${numberTry > 1 && 's'}`;

        const content = {
          icon,
          textModal,
          textModalButton,
          type,
          action,
          descriptionModal,
        };

        openModal(content);

        axios
          .put(
            `${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}/challenges/${challenge.challenge_id}`,
            { number_try_choice: numberTry - 1 }
          )
          .then(() => setTryChoice(numberTry - 1))
          .catch((err) =>
            setErrorMessage(
              'Un soucis lors de la validation de votre réaction, veuillez réésayer !'
            )
          );
      } else if (wrongAnswer && numberTry - 1 === 0) {
        textModal = 'Mauvaise réponse';
        textModalButton = 'Suivant';
        type = 'wrong';
        icon = iconBad;
        action = closeModal;
        descriptionModal =
          'Il y avait une meilleure réaction face à cette situation. Cliquez sur Suivant pour en apprendre plus sur comment gérer les situations sensibles ou conflictuelles.';

        const content = {
          icon,
          textModal,
          textModalButton,
          type,
          action,
          descriptionModal,
        };

        openModal(content);

        axios
          .put(
            `${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}/challenges/${challenge.challenge_id}`,
            { response_choice: choice, step: 2, number_try_choice: 0 }
          )
          .then(() => addResponseChoice(choice))
          .then(() => setTryChoice(0))
          .catch((err) =>
            setErrorMessage(
              'Un soucis lors de la validation de votre réaction, veuillez réésayer !'
            )
          );
      } else if (goodAnswer) {
        textModal = 'Bonne réponse';
        textModalButton = 'Suivant';
        type = 'win';
        action = handleAction;
        icon = iconWin;
        descriptionModal =
          'Vous avez choisi la meilleure réaction face à cette situation. Cliquez sur Suivant pour en apprendre plus sur comment gérer les situations sensibles ou conflictuelles.';

        const content = {
          icon,
          textModal,
          textModalButton,
          type,
          action,
          descriptionModal,
        };

        openModal(content);
      }

      if (goodAnswer && !wrongAnswer) {
        if (choice.length > 1) {
          choice.map((choiceResponse) => {
            axios
              .put(
                `${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}/challenges/${challenge.challenge_id}`,
                {
                  response_choice: choiceResponse,
                  step: 2,
                  number_try_choice: numberTry,
                }
              )
              .then(() => addResponseChoice(choiceResponse))
              .then(() => setTryChoice(numberTry))
              .catch((err) =>
                setErrorMessage(
                  'Un soucis lors de la validation de votre réaction, veuillez réésayer !'
                )
              );
          });
        } else {
          axios
            .put(
              `${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}/challenges/${challenge.challenge_id}`,
              { response_choice: choice, step: 2, number_try_choice: numberTry }
            )
            .then(() => addResponseChoice(choice))
            .then(() => setTryChoice(numberTry))
            .catch((err) =>
              setErrorMessage(
                'Un soucis lors de la validation de votre réaction, veuillez réésayer !'
              )
            );
        }
      }
      if (wrongAnswer) {
        if (numberTry - 1 === 0) {
          textModal = 'Mauvaise réponse';
          textModalButton = 'Suivant';
          type = 'wrong';
          icon = iconBad;
          action = closeModal;
          descriptionModal =
            'Il y avait une meilleure réaction face à cette situation. Cliquez sur Suivant pour en apprendre plus sur comment gérer les situations sensibles ou conflictuelles.';

          const content = {
            icon,
            textModal,
            textModalButton,
            type,
            action,
            descriptionModal,
          };

          openModal(content);

          axios
            .put(
              `${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}/challenges/${challenge.challenge_id}`,
              { response_choice: choice, step: 2, number_try_choice: 0 }
            )
            .then(() => addResponseChoice(choice))
            .then(() => setTryChoice(0))
            .catch((err) =>
              setErrorMessage(
                'Un soucis lors de la validation de votre réaction, veuillez réésayer !'
              )
            );
        } else {
          setNumberTry(numberTry - 1);
          textModal = 'Mauvaise réponse';
          textModalButton = 'Réessayer';
          type = 'wrong';
          icon = iconBad;
          action = closeModal;
          descriptionModal = `Il y avait une meilleure réaction face à cette situation. Il te reste ${
            numberTry - 1
          } essai${numberTry > 1 && 's'}`;

          const content = {
            icon,
            textModal,
            textModalButton,
            type,
            action,
            descriptionModal,
          };

          openModal(content);
          axios
            .put(
              `${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}/challenges/${challenge.challenge_id}`,
              { number_try_choice: numberTry - 1 }
            )
            .then(() => setTryChoice(numberTry - 1))
            .catch((err) =>
              setErrorMessage(
                'Un soucis lors de la validation de votre réaction, veuillez réésayer !'
              )
            );
        }
      }
    } else {
      setErrorMessage('Veuillez cliquer sur une réaction');
    }
  };

  const handleAction = () => {
    closeModal();
  };

  const handleChoiceInput = (answer) => {
    if (choice.includes(answer.id)) {
      setChoice(choice.filter((id) => id !== answer.id));
    } else {
      setChoice([...choice, answer.id]);
    }
  };

  const handleGoBack = () => {
    if (currentChallenge.step === 0) {
      history.push('/challenges');
    } else {
      changeStep(currentChallenge.step - 1);
    }
  };
  return (
    <div className="QuestionChoice">
      <p className="QuestionChoice_title">
        Comment réagir face à cette situation ? (choix multiple possible)
      </p>
      <div className="QuestionChoice_challenge-answers">
        {sortCurrentAnswer &&
          sortCurrentAnswer.map((answer) => (
            <InputRadio
              primary
              label={answer.description}
              backgroundColor="#59478e"
              onClick={() => handleChoiceInput(answer)}
              checked={choice.includes(answer.id)}
            />
          ))}
      </div>
      {errorMessage && <Alert message={errorMessage} primary />}
      <div className="QuestionChoice_title-buttons">
        <Button primary onClick={() => handleGoBack()} label="Précédent" />
        <Button label="Valider" primary onClick={() => handleChoice()} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentAnswers: state.challenges.currentAnswers,
    user: state.user,
    currentChallenge: state.challenges.currentChallenge,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchChallengeAnswers: (data) => {
      dispatch(fetchChallengeAnswers(data));
    },
    openModal: (data) => {
      dispatch(openModal(data));
    },
    closeModal: (data) => {
      dispatch(closeModal(data));
    },
    addResponseChoice: (data) => {
      dispatch(addResponseChoice(data));
    },
    setTryChoice: (data) => {
      dispatch(setTryChoice(data));
    },
    changeStep: (data) => {
      dispatch(changeStep(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionChoice);
