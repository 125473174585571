// ACTION USER

export const fetchUser = (payload) => ({
  type: 'FETCH_USER',
  payload,
});

export const updateUser = (payload) => ({
  type: 'UPDATE_USER',
  payload,
});

export const setPoints = (payload) => ({
  type: 'SET_POINTS',
  payload,
});

// ACTION CHALLENGES

export const fetchAllChallenges = () => ({
  type: 'FETCH_ALLCHALLENGES',
});

export const addAllChallenges = (idType, data) => ({
  type: 'ADD_ALLCHALLENGES',
  payload: { idType, data },
});

export const updateAllChallenges = (idType, idChallenge, data) => ({
  type: 'UPDATE_ALLCHALLENGES',
  payload: { idType, idChallenge, data },
});

export const errorAllChallenges = (idType, data) => ({
  type: 'ERROR_ALLCHALLENGES',
  payload: { idType, data },
});

export const currentChallenge = (payload) => ({
  type: 'CURRENT_CHALLENGE',
  payload,
});

export const fetchChallengeAnswers = (payload) => ({
  type: 'FETCH_CHALLENGE_ANSWERS',
  payload,
});

export const addResponseFeeling = (payload) => ({
  type: 'ADD_RESPONSE_FEELING',
  payload,
});

export const addResponseChoice = (payload) => ({
  type: 'ADD_RESPONSE_CHOICE',
  payload,
});

export const setTryChoice = (payload) => ({
  type: 'SET_TRY_CHOICE',
  payload,
});

export const changeStep = (payload) => ({
  type: 'CHANGE_STEP',
  payload,
});

// ACTION TYPE USER

export const fetchUserTypes = (payload) => ({
  type: 'FETCH_USER_TYPES',
  payload,
});

// ACTION EMOTION

export const fetchEmotions = () => ({
  type: 'FETCH_EMOTIONS',
});

export const addEmotions = (payload) => ({
  type: 'ADD_EMOTIONS',
  payload,
});

export const errorEmotions = (payload) => ({
  type: 'ERROR_EMOTIONS',
  payload,
});

export const currentEmotion = (payload) => ({
  type: 'CURRENT_EMOTION',
  payload,
});

export const resetCurrentEmotion = (payload) => ({
  type: 'RESET_CURRENT_EMOTION',
  payload,
});

// ACTION MODAL

export const openModal = (payload) => ({
  type: 'OPEN_MODAL',
  payload: payload,
});

export const closeModal = (payload) => ({
  type: 'CLOSE_MODAL',
  payload,
});

// ACTION GAMES

export const fetchGamesOfEmotion = (payload) => ({
  type: 'FETCH_GAMES_EMOTION',
  payload,
});

export const fetchGamesOfUser = (payload) => ({
  type: 'FETCH_GAMES_USER',
  payload,
});

export const addCurrentGame = (payload) => ({
  type: 'ADD_CURRENT_GAME',
  payload,
});

export const addGamesUser = (payload) => ({
  type: 'ADD_GAMES_USER',
  payload,
});

export const resetCurrentGame = (payload) => ({
  type: 'RESET_CURRENT_GAME',
  payload,
});

export const resetCurrentChallenge = (payload) => ({
  type: 'RESET_CURRENT_CHALLENGE',
  payload,
});

export const addResponsesGame = (payload) => ({
  type: 'ADD_RESPONSES_GAME',
  payload,
});

// ACTION PETALS

export const fetchPetals = (payload) => ({
  type: 'FETCH_PETALS',
  payload,
});

export const addPetals = (payload) => ({
  type: 'ADD_PETALS',
  payload,
});

export const errorPetals = (payload) => ({
  type: 'ERROR_PETALS',
  payload,
});

export const fetchCurrentPetal = (payload) => ({
  type: 'FETCH_CURRENT_PETAL',
  payload,
});

export const addCurrentPetal = (payload) => ({
  type: 'ADD_CURRENT_PETAL',
  payload,
});

export const fetchUserPetals = (payload) => ({
  type: 'FETCH_USER_PETALS',
  payload,
});

export const addUserPetals = (payload) => ({
  type: 'ADD_USER_PETALS',
  payload,
});

export const fetchSubPetals = (payload) => ({
  type: 'FETCH_SUBPETALS',
  payload,
});

export const addSubPetals = (payload) => ({
  type: 'ADD_SUBPETALS',
  payload,
});

export const resetCurrentPetal = (payload) => ({
  type: 'RESET_CURRENT_PETAL',
  payload,
});
