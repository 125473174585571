const initialState = {};

const User = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_USER':
      return action.payload;
    case 'UPDATE_USER':
      return action.payload;
    case 'SET_POINTS':
      return {
        ...state,
        points: action.payload,
      };
    default:
      return state;
  }
};

export default User;
