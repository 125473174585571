import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-input-slider';
import { connect } from 'react-redux';
import Parser from 'html-react-parser';

import { closeModal, openModal } from '../actions/index';

import imageSlider from '../../styles/images/heart.png';
import Button from '../../stories/components/Button/Button';
import Alert from '../../stories/components/Alert/Alert';
import Modal from '../../stories/components/Modal/Modal';

import iconWin from '../../styles/images/modal-win.svg';

import '../../styles/css/Games/Breathing.css';

const Breathing = ({
  checkGameAndChallenge,
  modal,
  openModal,
  buttonTextModal,
  actionModal,
  imageModal,
  currentGame,
  closeModal,
  currentEmotion,
}) => {
  const [count, setCount] = useState(1);
  const [slider, setSlider] = useState({ x: 5 });
  const [start, setStart] = useState(false);
  const [finish, setFinish] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [messageBreath, setMessageBreath] = useState(true);

  const useInterval = (callback, slider) => {
    const savedCallback = useRef(callback);

    // Remember the latest callback if it changes.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      // Don't schedule if no delay is specified.
      if (slider === null) {
        return;
      }

      const id = setInterval(() => savedCallback.current(), slider);
      return () => clearInterval(id);
    }, [slider]);
  };

  const startAnimation = () => {
    setStart(!start);

    const delayConvert = slider.x * 60 * 1000;

    setTimeout(() => {
      setFinish(!finish);
    }, delayConvert);
  };

  useInterval(
    () => {
      if (count <= 3) {
        setCount(count + 1);
      }
    },
    start ? 1000 : null
  );

  const stopGame = () => {
    const textModal = Parser(currentGame.title_win);
    const textModalButton = 'Suivant';
    const type = 'win';
    const action = finishGame;
    const icon = iconWin;
    const descriptionModal = Parser(currentGame.text_win);

    const content = {
      icon,
      textModal,
      textModalButton,
      type,
      action,
      descriptionModal,
    };

    openModal(content);
  };

  useEffect(() => {
    if (start) {
      setTimeout(() => {
        setMessageBreath('1/ Inspire par le nez en gonflant le ventre');
      }, 1000);

      setTimeout(() => {
        setMessageBreath(`2/ Bloque ta respiration et visualise un nuage de
        ${currentEmotion.name}, ce nuage renferme tous les obstacles ou
        évènements indésirables`);
      }, 5000);
      setTimeout(() => {
        setMessageBreath(`3/ Expire par la bouche en rentrant le ventre, et chasse le plus loin
        possible ce nuage ${currentEmotion.name}`);
      }, 10000);
    }
  }, [start]);

  const finishGame = () => {
    closeModal();
    checkGameAndChallenge();
  };

  const handleMessageBreath = () => {
    return <p>{messageBreath}</p>;
  };

  return (
    <div className="Breathing">
      {start && !finish && <p className="anim-circle">Respiration</p>}
      {!start && !finish && (
        <div className="Breathing_parameters">
          <div className="Breathing_parameter">
            <label className="Breathing_parameter_label">
              Choisir un durée
            </label>
            <div className="Breathing_parameter_content">
              <p>5</p>
              <div className="Breathing_parameter_content_input">
                <p>{slider.x} min</p>
                <Slider
                  styles={{
                    track: {
                      backgroundColor: '#da2d64',
                    },
                    active: {
                      backgroundColor: '#da2d64',
                    },
                    thumb: {
                      width: 25,
                      height: 25,
                      backgroundImage: `url(${imageSlider})`,
                      backgroundSize: '15px',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: '50%',
                    },
                    disabled: {
                      opacity: 0.5,
                    },
                  }}
                  axis="x"
                  xstep="5"
                  xmax="55"
                  x={slider.x}
                  onChange={({ x }) => setSlider({ ...slider, x })}
                />
              </div>
              <p>55</p>
            </div>
          </div>
          <Button onClick={() => startAnimation()} label="Démarrer" primary />
        </div>
      )}

      {errorMessage && <Alert message={errorMessage} primary />}
      {modal.isOpen && (
        <Modal
          buttonText={buttonTextModal}
          actionModal={actionModal}
          image={imageModal}
        />
      )}
      <div>
        {start && !finish && handleMessageBreath()}
        {finish && 'Super vous avez terminé votre exercice de respiration !'}
        {start && <Button onClick={() => stopGame()} label="Suivant" primary />}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
    buttonTextModal: state.modal.buttonText,
    actionModal: state.modal.buttonAction,
    imageModal: state.modal.image,
    currentGame: state.games.currentGame,
    currentEmotion: state.emotions.currentEmotion,
  };
};

const mapDispatchProps = (dispatch) => {
  return {
    openModal: (data) => {
      dispatch(openModal(data));
    },
    closeModal: (data) => {
      dispatch(closeModal(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Breathing);
