import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import '../../../styles/css/Admin/Users/User.css';

import heartLogo from '../../../styles/images/logo-tools-active.svg';
import petalsLogo from '../../../styles/images/petals.svg';
import logoQuokka from '../../../styles/images/joie2.svg';

import { IconContext } from 'react-icons';
import { BsFillTrashFill } from 'react-icons/bs';
import { AiFillEdit, AiFillEye } from 'react-icons/ai';
import { VscVerified } from 'react-icons/vsc';

const User = ({
  lastname,
  firstname,
  email,
  picture_url,
  verify,
  points,
  petals,
  type,
  id,
}) => {
  const [typeUser, setTypeUser] = useState(null);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL_SERVER}/api/types/${type}`)
      .then((results) => results.data)
      .then((data) => setTypeUser(data));
  }, []);

  const history = useHistory();

  return (
    <div
      className="User"
      style={{
        backgroundColor: typeUser ? typeUser.color : 'rgba(0, 154, 255, 0.6)',
      }}
    >
      <div className="User_details">
        <p>{typeUser && typeUser.name}</p>
        <div className="User_content">
          <img
            className="User_picture"
            src={picture_url ? picture_url : logoQuokka}
            alt={`picture-${lastname}`}
          />
          <div className="User_description">
            {firstname} {lastname}
            <p className="User_email">
              {email}
              {verify && (
                <IconContext.Provider value={{ color: 'white' }}>
                  <VscVerified />
                </IconContext.Provider>
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="User_options">
        <div className="User_buttons">
          <button
            className="User_button"
            onClick={() => history.push(`/utilisateurs/${id}`)}
          >
            <IconContext.Provider value={{ color: 'white' }}>
              <AiFillEye />
            </IconContext.Provider>
          </button>
        </div>
        <div>
          <div className="User_score">
            {points}
            <img src={heartLogo} alt="points utilisateur" />
          </div>
          <div className="User_score">
            {petals}
            <img src={petalsLogo} alt="petales utilisateur" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
