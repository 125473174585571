const initialState = {
  gamesOfEmotion: [],
  gamesOfUser: [],
  currentGame: null,
};

const Games = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_GAMES_EMOTION':
      return {
        ...state,
        gamesOfEmotion: action.payload,
      };
    case 'FETCH_GAMES_USER':
      return {
        ...state,
        gamesOfUser: action.payload,
      };
    case 'ADD_GAMES_USER':
      const listGames = [...state.gamesOfUser];
      listGames.push(action.payload);

      return {
        ...state,
        gamesOfUser: listGames,
      };
    case 'ADD_CURRENT_GAME':
      return {
        ...state,
        currentGame: action.payload,
      };

    case 'RESET_CURRENT_GAME':
      return {
        ...state,
        currentGame: null,
        gamesOfEmotion: [],
      };
    case 'ADD_RESPONSES_GAME':
      return {
        ...state,
        currentGame: {
          ...state.currentGame,
          responses: action.payload,
        },
      };
    default:
      return state;
  }
};

export default Games;
