import React from 'react';
import { GiCheckMark } from 'react-icons/gi';
import { IconContext } from 'react-icons';
import { useHistory } from 'react-router-dom';

import Intro from './Intro';
import Button from '../../stories/components/Button/Button';

import '../../styles/css/Auth/SignupSuccess.css';

const ResetPasswordSuccess = () => {
  const history = useHistory();
  return (
    <div className="SignupSuccess">
      <Intro />
      <div className="SignupSuccess_content">
        <IconContext.Provider value={{ color: '#DA2D64' }}>
          <GiCheckMark />
        </IconContext.Provider>
        <h3 className="SignupSuccess_content-title">
          Réinitialisation de mot de passe réussie
        </h3>
        <p className="SignupSuccess_content-description">
          Vous pouvez dès à présent vous connectez à la plateforme
        </p>
        <Button
          primary
          label="Connectez-vous"
          onClick={() => history.push('/')}
        />
      </div>
    </div>
  );
};

export default ResetPasswordSuccess;
