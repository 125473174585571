import React from 'react';
import Parser from 'html-react-parser';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';

import { changeStep } from '../actions/index';

import Button from '../../stories/components/Button/Button';
import Link from '../../stories/components/Link/Link';

import '../../styles/css/Questions/QuestionSolution.css';
import imageQuestion from '../../styles/images/imageQuestion.svg';

const QuestionSolution = ({
  challenge,
  user,
  changeStep,
  currentChallenge,
}) => {
  const history = useHistory();

  const goToGame = () => {
    axios
      .put(
        `${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}/challenges/${challenge.challenge_id}`,
        { step: 3 }
      )
      .then(() => changeStep(3));
  };

  const handleGoBack = () => {
    if (currentChallenge.step === 0) {
      history.push('/challenges');
    } else {
      changeStep(currentChallenge.step - 1);
    }
  };
  return (
    <div className="QuestionSolution">
      <div className="QuestionSolution_information">
        <img src={imageQuestion} alt="information" />
        <div>{Parser(challenge.good_response)}</div>
      </div>
      <div className="QuestionSolution_information-link">
        <p className="QuestionSolution_information-link-text">
          Pour prendre connaissance de ces différentes solutions et des
          possibilités de fincancement, vous pouvez consulter:
        </p>
        <Link url={challenge.link} blank label="Lien utile" primary />
      </div>
      {challenge.good_practice !== null && (
        <div className="QuestionSolution_practice">
          <p className="QuestionSolution_practice-title">Bonnes pratiques</p>
          <div>{Parser(challenge.good_practice)}</div>
        </div>
      )}
      <div className="QuestionSolution_practice-buttons">
        <Button primary onClick={() => handleGoBack()} label="Précédent" />
        <Button label="Valider" primary onClick={() => goToGame()} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    currentChallenge: state.challenges.currentChallenge,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeStep: (data) => {
      dispatch(changeStep(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionSolution);
