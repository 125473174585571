import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import ReactLoading from 'react-loading';

import {
  resetCurrentChallenge,
  changeStep,
  fetchPetals,
  addPetals,
  fetchUserPetals,
  addUserPetals,
  fetchCurrentPetal,
  addCurrentPetal,
  updateUser,
  fetchSubPetals,
  addSubPetals,
  resetCurrentEmotion,
  resetCurrentPetal,
} from '../actions/index';

import Joy from '../Emotions/Joy';
import Sadness from '../Emotions/Sadness';
import Trust from '../Emotions/Trust';
import Fear from '../Emotions/Fear.jsx';
import Surprise from '../Emotions/Surprise.jsx';
import Disgust from '../Emotions/Disgust.jsx';
import Angry from '../Emotions/Angry.jsx';
import Anticipation from '../Emotions/Anticipation.jsx';

import Button from '../../stories/components/Button/Button';
import iconWin from '../../styles/images/modal-win.svg';

import '../../styles/css/Questions/QuestionDebrief.css';

const QuestionDebrief = ({
  currentEmotion,
  resetCurrentChallenge,
  changeStep,
  user,
  currentChallenge,
  allPetals,
  addPetals,
  fetchPetals,
  petalsOfUser,
  fetchUserPetals,
  addUserPetals,
  fetchCurrentPetal,
  addCurrentPetal,
  currentPetal,
  updateUser,
  fetchSubPetals,
  addSubPetals,
  resetCurrentEmotion,
  resetCurrentPetal,
}) => {
  const history = useHistory();
  const [isPetals, setIsPetals] = useState(false);
  const [noMorePetal, setNoMorePetal] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL_SERVER}/api/petals`)
      .then((response) => {
        fetchPetals();
        addPetals(response.data);
        axios
          .get(
            `${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}/petals`
          )
          .then((response2) => {
            fetchUserPetals();
            addUserPetals(response2.data);

            const listPetalId = [];
            const listUserPetalId = [];

            response.data.map((petal) => listPetalId.push(petal.id));
            response2.data.map((petal) => listUserPetalId.push(petal.id));

            const newDataId = [];
            listPetalId.map(
              (petal) =>
                !listUserPetalId.includes(petal) && newDataId.push(petal)
            );

            const listUserPetal = [];

            newDataId.map((petalId) =>
              response.data.map(
                (petal) => petal.id === petalId && listUserPetal.push(petal)
              )
            );

            if (currentEmotion) {
              axios
                .get(
                  `${process.env.REACT_APP_URL_SERVER}/api/petals/${currentEmotion.id}/subpetals`
                )
                .then((response) => {
                  fetchSubPetals();
                  addSubPetals(response.data);

                  const isEmotionPrincipal = listPetalId.find(
                    (petal) => petal === currentEmotion.id && petal
                  );

                  const isEmotionPrincipalUsed =
                    listUserPetalId.includes(isEmotionPrincipal);

                  if (!isEmotionPrincipalUsed) {
                    handleCurrentPetal(isEmotionPrincipal);
                  } else {
                    const arraySubPetalId = [];
                    response.data.map((petal) =>
                      arraySubPetalId.push(petal.id_petal_secondary)
                    );
                    const arraySubPetalIdNotUsed = [];
                    arraySubPetalId.map(
                      (petalId) =>
                        !listUserPetalId.includes(petalId) &&
                        arraySubPetalIdNotUsed.push(petalId)
                    );

                    const randomNumberPetal = Math.floor(
                      Math.random() * arraySubPetalIdNotUsed.length
                    );

                    if (arraySubPetalIdNotUsed.length !== 0) {
                      handleCurrentPetal(
                        arraySubPetalIdNotUsed[randomNumberPetal]
                      );
                    } else {
                      setNoMorePetal(true);
                    }
                  }
                });
            }
          });
      });
  }, [user]);

  const handleCurrentPetal = (id) => {
    axios
      .get(`${process.env.REACT_APP_URL_SERVER}/api/petals/${id}`)
      .then((response) => {
        fetchCurrentPetal();
        addCurrentPetal(response.data);
      });
    axios.post(
      `${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}/petals/${id}`,
      { user_id: user.id, petal_id: id }
    );
  };

  const handleEmotionDebrief = () => {
    if (currentEmotion) {
      switch (currentEmotion.slug) {
        case 'joy':
          return <Joy />;
          break;
        case 'sadness':
          return <Sadness />;
          break;
        case 'trust':
          return <Trust />;
          break;
        case 'surprise':
          return <Surprise />;
          break;
        case 'fear':
          return <Fear />;
          break;
        case 'disgust':
          return <Disgust />;
          break;
        case 'anger':
          return <Angry />;
          break;
        case 'anticipation':
          return <Anticipation />;
          break;
        default:
          break;
      }
    }
  };

  const finishChallenge = () => {
    axios
      .put(
        `${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}/challenges/${currentChallenge.challenge_id}`,
        { step: 0 }
      )
      .then(() => changeStep(0));
    axios
      .put(`${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}`, {
        petals: user.petals + 1,
      })
      .then((response) => updateUser(response.data.user));
    setTimeout(() => {
      resetCurrentChallenge();
      resetCurrentEmotion();
      resetCurrentPetal();
    }, 1000);
    history.push('/boite-a-outils');
  };

  const handlePath = (textColor, path, emotionPosition, color, text) => {
    return (
      <g role="button">
        <path d={path} fill={color} />
        <text textAnchor="middle" transform={emotionPosition} fill={textColor}>
          {text}
        </text>
      </g>
    );
  };

  const handleFinishGameWithoutPetal = () => {
    axios
      .put(
        `${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}/challenges/${currentChallenge.challenge_id}`,
        { step: 0 }
      )
      .then(() => changeStep(0));
    setTimeout(() => {
      resetCurrentChallenge();
      resetCurrentEmotion();
      resetCurrentPetal();
      setNoMorePetal(false);
    }, 1000);
    history.push('/boite-a-outils');
  };

  const handlePetal = () => {
    if (noMorePetal) {
      return (
        <div>
          <img src={iconWin} />
          <p>
            {`Tu as collecté toutes les pétales liés à l'émotion
            ${currentEmotion && currentEmotion.name}, bravo !`}
          </p>
          <Button
            label="Terminer"
            primary
            onClick={() => handleFinishGameWithoutPetal()}
          />
        </div>
      );
    } else {
      if (!currentPetal) {
        return (
          <ReactLoading
            type="bubbles"
            color="white"
            height={'10%'}
            width={'10%'}
          />
        );
      }
      return (
        <div>
          <p>Tu as collecté une pétale de la {currentPetal.name}</p>
          <svg id="plutchik-wheel" viewBox="0 0 500 500">
            {handlePath(
              //   '10', // Acceptation
              currentPetal.textColor, // Confiance

              'M338.9,108.3c40.7-24.4,87.8-35,87.8-35h0s-10.6,47.1-35,87.8a175.62,175.62,0,0,0-23.4-29.4A162.47,162.47,0,0,0,338.9,108.3Z',
              'translate(397 107) rotate(-45)',
              currentPetal.color
              //   'Acceptation'
            )}
            {handlePath(
              currentPetal.textColor, // Confiance
              'M317,183a94.15,94.15,0,0,0-30.8-20.6h0c9.4-22.7,30-40.5,52.6-54.1a175.62,175.62,0,0,1,29.4,23.4,162.47,162.47,0,0,1,23.4,29.4c-13.6,22.6-31.4,43.3-54.1,52.6h0A91.91,91.91,0,0,0,317,183Z',
              'translate(345 159) rotate(-45)',
              currentPetal.color,
              currentPetal.name
            )}
            {handlePath(
              //   '12', // Admiration
              currentPetal.textColor, // Confiance
              'M250,250l36.3-87.6h0A92.72,92.72,0,0,1,317,183a96.43,96.43,0,0,1,20.6,30.7Z',
              'translate(293 211) rotate(-45)',
              currentPetal.color
              //   'Admiration'
            )}
          </svg>
          <p className="QuestionDebrief_plus">+ 1</p>
          <p>Collecte toutes les pétales pour nourrir Qokka</p>
          <Button
            label="Ajouter à l'inventaire"
            primary
            onClick={() => finishChallenge()}
          />
        </div>
      );
    }
  };

  const handleGoBack = () => {
    if (currentChallenge.step === 0) {
      history.push('/challenges');
    } else {
      changeStep(currentChallenge.step - 1);
    }
  };

  return (
    <div className="QuestionDebrief">
      {!isPetals ? (
        <div>
          {handleEmotionDebrief()}
          <div className="QuestionDebrief-buttons">
            {currentChallenge.step !== 4 && <Button primary onClick={() => handleGoBack()} label="Précédent" />}
            <Button label="Valider" primary onClick={() => setIsPetals(true)} />
          </div>
        </div>
      ) : (
        <div>{handlePetal()}</div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentEmotion: state.emotions.currentEmotion,
    currentChallenge: state.challenges.currentChallenge,
    user: state.user,
    allPetals: state.petals.allPetals,
    petalsOfUser: state.petals.petalsOfUser,
    currentPetal: state.petals.currentPetal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetCurrentChallenge: () => {
      dispatch(resetCurrentChallenge());
    },
    resetCurrentEmotion: () => {
      dispatch(resetCurrentEmotion());
    },
    resetCurrentPetal: () => {
      dispatch(resetCurrentPetal());
    },
    changeStep: (data) => {
      dispatch(changeStep(data));
    },
    fetchPetals: (data) => {
      dispatch(fetchPetals(data));
    },
    addPetals: (data) => {
      dispatch(addPetals(data));
    },
    fetchUserPetals: (data) => {
      dispatch(fetchUserPetals(data));
    },
    addUserPetals: (data) => {
      dispatch(addUserPetals(data));
    },
    fetchCurrentPetal: (data) => {
      dispatch(fetchCurrentPetal(data));
    },
    addCurrentPetal: (data) => {
      dispatch(addCurrentPetal(data));
    },
    updateUser: (data) => {
      dispatch(updateUser(data));
    },
    fetchSubPetals: (data) => {
      dispatch(fetchSubPetals(data));
    },
    addSubPetals: (data) => {
      dispatch(addSubPetals(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionDebrief);
