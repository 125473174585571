import React from 'react';

import Button from '../../../../stories/components/Button/Button';

const QuestionDepression = (props) => {
  const responsesDepression = [
    {
      id: 1,
      text: 'Oui',
      handler: props.actionProvider.handleBoolDepression,
    },
    {
      id: 2,
      text: 'Non',
      handler: props.actionProvider.handleBoolDepression,
    },
  ];

  return (
    <div className="QuestionDepression">
      {responsesDepression.map((response) => (
        <Button
          key={response.id}
          label={response.text}
          primary
          onClick={() => response.handler(response.id, 7, props.responses[1])}
        />
      ))}
    </div>
  );
};

export default QuestionDepression;
