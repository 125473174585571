import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import axios from 'axios';
import { connect } from 'react-redux';

import { updateUser, fetchUserTypes } from './actions';

import Input from '../stories/components/Input/Input';
import InputRadio from '../stories/components/Input/InputRadio';
import Button from '../stories/components/Button/Button';
import Box from '../stories/components/Content/Box';
import logoQuokka from '../styles/images/joie2.svg';

import '../styles/css/FirstConnexion.css';

const FirstConnexion = ({ user, updateUser, typesUser, fetchUserTypes }) => {
  const [inputs, setInputs] = useState({
    firstname: '',
    lastname: '',
    firstconnexion: 0,
  });
  // creating the ref
  const customeSlider = useRef(null);

  // setting slider configurations
  const [sliderSettings] = useState({
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL_SERVER}/api/types`)
      .then((response) => fetchUserTypes(response.data));
  }, []);

  const gotoNext = (type) => {
    if (type === 'modify') {
      axios
        .put(`${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}`, inputs)
        .then((response) => updateUser(response.data.user))
    }
    customeSlider.current.slickNext();
  };

  const gotoPrev = () => {
    customeSlider.current.slickPrev();
  };

  const validFirstConnexion = () => {
    axios
      .put(`${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}`, {
        firstconnexion: 1,
      })
      .then((response) => changeToken(response.data))

    let arrayFirstChallenge = [];

    switch (user.type) {
      case 2:
        arrayFirstChallenge = [2, 6, 7];
        break;
      case 3:
        arrayFirstChallenge = [3, 8, 9];
        break;
      case 1:
        arrayFirstChallenge = [1, 4, 5];
        break;
      default:
        break;
    }

    arrayFirstChallenge.map((challenge) =>
      axios
        .post(
          `${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}/challenges/${challenge}`
        )
    );
  };

  const changeToken = (data) => {
    localStorage.setItem('token', data.token);
    updateUser(data.user);
  };

  return (
    <div className="FirstConnexion">
      <Slider {...sliderSettings} ref={customeSlider}>
        <div className="FirstConnexion_intro">
          <h1>Bienvenue sur Quokka</h1>
          <img
            src={logoQuokka}
            alt="logo_quokka"
            className="FirstConnexion_intro_image"
          />
          <Button label="Continuer" primary onClick={() => gotoNext()} />
        </div>
        <div className="FirstConnexion_firstname">
          <Input
            primary
            type="text"
            label="Quel est votre prénom ?"
            value={inputs.firstname}
            name="firstname"
            required
            onChange={(e) =>
              setInputs({ ...inputs, [e.target.name]: e.target.value })
            }
          />
          {inputs.firstname && (
            <Button
              label="Valider"
              primary
              onClick={() => gotoNext('modify')}
            />
          )}
        </div>
        <div className="FirstConnexion_firstname">
          <Input
            primary
            type="text"
            label="Quel est votre nom ?"
            value={inputs.lastname}
            name="lastname"
            required
            onChange={(e) =>
              setInputs({ ...inputs, [e.target.name]: e.target.value })
            }
          />
          {inputs.lastname && (
            <Button
              label="Valider"
              primary
              onClick={() => gotoNext('modify')}
            />
          )}
        </div>
        <div className="FirstConnexion_intro">
          <h2>
            Bonjour {inputs.firstname} {inputs.lastname} !
          </h2>
          <Box
            type="question"
            text="Quokka est l'animal le plus joyeux du monde."
            thirdText="Mais sur le route de son travail, il a perdu toutes ses pétales de fleurs"
            primary
          />
          <Button label="Valider" primary onClick={() => gotoNext()} />
        </div>
        <div className="FirstConnexion_intro">
          <Box
            type="question"
            text="Aidez-le à collectionner des pétales et points pour qu'il puisse retrouver sa joie de vivre"
            primary
          />
          <Button label="Valider" primary onClick={() => gotoNext()} />
        </div>
        <div className="FirstConnexion_intro">
          <Box
            type="question"
            text="En chemin vous trouverez des challenges et des jeux amusants adaptés à votre situation..."
            thirdText="Pour vous informer sur les dispositifs d'aides aux aidant et mieux comprendre ou gérer vos émotions"
            primary
          />
          <Button label="Valider" primary onClick={() => gotoNext()} />
        </div>
        <div className="FirstConnexion_firstname">
          <p>Vous êtes</p>
          {typesUser &&
            typesUser.map((type) => (
              <InputRadio
                primary
                label={type.name}
                onClick={() => setInputs({ ...inputs, type: type.id })}
                checked={type.id === inputs.type}
              />
            ))}
          {inputs.lastname && (
            <Button
              label="Valider"
              primary
              onClick={() => gotoNext('modify')}
            />
          )}
        </div>
        <div className="FirstConnexion_intro">
          <Box
            type="question"
            text="Avec Quokka,"
            thirdText="Etre aidant ce n'est plus le parcours du combatant."
            primary
          />
          <Button
            label="C'est parti !"
            onClick={() => validFirstConnexion()}
            primary
          />
        </div>
      </Slider>
      {/* <button onClick={() => gotoPrev()}>Précédent</button> */}
    </div>
  );
};

FirstConnexion.propTypes = {
  user: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    firstconnexion: PropTypes.bool,
    id: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    typesUser: state.typesUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (data) => {
      dispatch(updateUser(data));
    },
    fetchUserTypes: (data) => {
      dispatch(fetchUserTypes(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FirstConnexion);
