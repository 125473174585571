import React, { useEffect, useState } from 'react';
import { SpriteAnimator } from 'react-sprite-animator';

import Box from '../../stories/components/Content/Box';
import Button from '../../stories/components/Button/Button';

import '../../styles/css/Games/Spider.css';

const Spider = ({ checkGameAndChallenge }) => {
  const [startAnimation, setStartAnimation] = useState(false);
  const [endOfAnimation, setEndOfAnimation] = useState(false);

  useEffect(() => {
    setTimeout(function () {
      setStartAnimation(true);
    }, 3000);

    setTimeout(function () {
      setStartAnimation(false);
    }, 10000);

    setTimeout(function () {
      setEndOfAnimation(!endOfAnimation);
    }, 10000);
  }, []);

  return (
    <div className="Spider">
      {!startAnimation && !endOfAnimation && (
        <Box type="question" text="Regarder votre écran" />
      )}
      {endOfAnimation && (
        <div>
          <Box type="question" text="Essayer de ne plus penser aux araignées" />
          <Box type="question" text="Faire une proposition" />
          <Button
            onClick={() => checkGameAndChallenge()}
            label="Valider"
            primary
          />
        </div>
      )}
      {startAnimation && (
        <div>
          <div className="SpiderBotomToTop">
            <SpriteAnimator
              sprite="https://s3-us-west-2.amazonaws.com/s.cdpn.io/23954/spider_crawl.png"
              width={120}
              height={150}
              fps={5}
              direction="vertical"
            />
          </div>
          <div className="SpiderBotomToTopTwo">
            <SpriteAnimator
              sprite="https://s3-us-west-2.amazonaws.com/s.cdpn.io/23954/spider_crawl.png"
              width={120}
              height={150}
              fps={5}
              direction="vertical"
            />
          </div>
          <div className="SpiderBotomToTopThree">
            <SpriteAnimator
              sprite="https://s3-us-west-2.amazonaws.com/s.cdpn.io/23954/spider_crawl.png"
              width={120}
              height={150}
              fps={5}
              direction="vertical"
            />
          </div>
          <div className="SpiderBotomToTopFour">
            <SpriteAnimator
              sprite="https://s3-us-west-2.amazonaws.com/s.cdpn.io/23954/spider_crawl.png"
              width={120}
              height={150}
              fps={5}
              direction="vertical"
            />
          </div>
          <div className="SpiderTopToBottom">
            <SpriteAnimator
              sprite="https://s3-us-west-2.amazonaws.com/s.cdpn.io/23954/spider_crawl.png"
              width={120}
              height={150}
              fps={5}
              direction="vertical"
            />
          </div>
          <div className="SpiderLeftToRight">
            <SpriteAnimator
              sprite="https://s3-us-west-2.amazonaws.com/s.cdpn.io/23954/spider_crawl.png"
              width={120}
              height={150}
              fps={5}
              direction="vertical"
            />
          </div>
          <div className="SpiderRightToLeft">
            <SpriteAnimator
              sprite="https://s3-us-west-2.amazonaws.com/s.cdpn.io/23954/spider_crawl.png"
              width={120}
              height={150}
              fps={5}
              direction="vertical"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Spider;
