import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import Intro from './Intro';
import Input from '../../stories/components/Input/Input';
import Button from '../../stories/components/Button/Button';
import Alert from '../../stories/components/Alert/Alert';

import '../../styles/css/Auth/ResetPassword.css';

const ResetPassword = ({ location }) => {
  const search = location.search;
  const params = new URLSearchParams(search);
  const token = params.get('token');
  const email = params.get('email');

  const [inputs, setInputs] = useState({
    password: '',
    token: token,
    email: email,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  const handleReset = () => {
    axios
      .post(`${process.env.REACT_APP_URL_SERVER}/api/users/reset`, inputs)
      .then(() => {
        history.push('/mot-de-passe-reinitialiser-valide');
      })
      .catch(() =>
        setErrorMessage(
          'Une erreur est survenu lors de la réinitialisation de votre mot de passe, veuillez réessayer'
        )
      );
  };

  return (
    <div className="ResetPassword">
      <Intro />
      <div className="ResetPassword_content">
        <div className="ResetPassword_content-top">
          <h2 className="ResetPassword_content-title">
            Réinitialisation mot de passe
          </h2>
          <p className="ResetPassword_content-description">
            Vous pouvez dès à présent réinitialiser votre mot de passe
          </p>
        </div>
        <div className="ResetPassword_form">
          <Input
            type={showPassword ? 'text' : 'password'}
            label="Mot de passe"
            value={inputs.password}
            name="password"
            required
            placeholder="*****"
            colorEye="grey"
            setShowPassword={() => setShowPassword(!showPassword)}
            onChange={(e) =>
              setInputs({ ...inputs, [e.target.name]: e.target.value })
            }
          />
          {errorMessage && <Alert message={errorMessage} primary />}
          <Button onClick={() => handleReset()} primary label="Réinitialiser" />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
