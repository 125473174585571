import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Parser from 'html-react-parser';

import Card from './Card';

import Button from '../../../stories/components/Button/Button';
import Modal from '../../../stories/components/Modal/Modal';

import { openModal, closeModal } from '../../actions/index';

import iconWin from '../../../styles/images/modal-win.svg';

import '../../../styles/css/Games/Memory.css';

const Memory = ({
  checkGameAndChallenge,
  currentEmotion,
  openModal,
  modal,
  buttonTextModal,
  actionModal,
  imageModal,
  closeModal,
  currentGame,
}) => {
  const arrayTrust = [
    'Acceptation',
    'Admiration',
    'Apaisé',
    'Détendu',
    'Calme',
    'Cool',
  ];

  const arrayJoy = [
    'Extase',
    'Sérénité',
    'Enthousiasme',
    'Fierté',
    // 'Gaieté',
    'Amusement',
    // 'Bonheur',
    // 'Exaltation',
    // 'Satisfaction',
    // 'Entrain',
    'Optimisme',
    // 'Passionnant',
    // 'Soulagement',
  ];

  const arrayFear = [
    'Horreur',
    'Alerte',
    // 'Choc',
    'Peur',
    // 'Frayeur',
    // 'Horreur',
    // 'Terreur',
    // 'Panique',
    // 'Hystérie',
    // 'Mortification',
    'Nervosité',
    'Anxiété',
    // 'Tension',
    // 'Incomfort',
    // 'Appréhension',
    // 'Inquiétude',
    // 'Détresse',
    'Crainte',
  ];

  const arraySadness = [
    'Dépression',
    'Désespoir',
    // 'Mélancolie',
    // 'Maussade',
    // 'Chagrin',
    'Peine',
    // 'Malheur',
    // 'Détresse',
    // 'Déception',
    // 'Consternation',
    'Mécontentement',
    // 'Honte',
    // 'Culpabilité',
    // 'Regret',
    'Remords',
    // 'Négligence',
    // 'Solitude',
    // 'Nostalgie',
    // 'Découragement',
    'Insécurité',
    // 'Embarras',
    // 'Humiliation',
    // 'Insulte',
    // 'Compassion',
  ];

  const arrayAnticipation = [
    'Vigilance',
    'Intérêt',
    'Alerte',
    'Réactif',
    'Eveil',
    'Mesuré',
    // 'Objectivé',
  ];

  const arraySurprise = [
    'Etonnement',
    'Surprise',
    'Stupéfaction',
    'Sidération',
    'Distraction',
    'Désappointement',
  ];

  const arrayDisgust = [
    'Revulsion',
    'Mépris',
    'Aversion',
    'Ennui  ',
    'Remord',
    // 'Répugnance',
    'Dégout',
  ];

  const arrayAnger = [
    'Agacement',
    'Exaspération',
    // 'Frustration',
    'Rage',
    'Colère',
    'Envie',
    'Jalousie',
    // 'Tourmenter',
    // 'Souffrance',
    // 'Agonie',
  ];

  const [duplicatedEmotions, setDuplicatedEmotions] = useState([]);
  const [randomizedEmotions, setRandomizedEmotions] = useState([]);
  const [finalizedEmotions, setFinalizedEmotions] = useState([]);
  const [openedEmotions, setOpenedEmotions] = useState([]);
  const [pairFound, setPairFound] = useState([]);
  const [numberOfPairTotal, setNumberOfPairTotal] = useState(0);
  const [timer, setTimer] = useState(0);
  const [strokes, setStrokes] = useState(0);
  const [stopCounter, setStopCounter] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (!currentEmotion) {
      history.goBack();
    } else {
      let emotions;
      switch (currentEmotion.slug) {
        case 'trust':
          emotions = arrayTrust;
          break;
        case 'joy':
          emotions = arrayJoy;
          break;
        case 'fear':
          emotions = arrayFear;
          break;
        case 'anticipation':
          emotions = arrayAnticipation;
          break;
        case 'sadness':
          emotions = arraySadness;
          break;
        case 'surprise':
          emotions = arraySurprise;
          break;
        case 'disgust':
          emotions = arrayDisgust;
          break;
        case 'anger':
          emotions = arrayAnger;
          break;
        default:
          break;
      }
      start(emotions);
    }
  }, []);
  let timeout;

  useEffect(() => {
    if (!stopCounter) {
      timeout = setTimeout(() => {
        setTimer(timer + 1);
      }, 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [timer]);

  useEffect(() => {
    if (openedEmotions.length == 2) {
      setTimeout(() => {
        check();
      }, 750);
    }
  }, [openedEmotions]);

  const handleClick = (name, index) => {
    let framework = {
      name,
      index,
    };

    let newFinalizedEmotions = finalizedEmotions;
    finalizedEmotions[index].close = false;
    setOpenedEmotions([...openedEmotions, framework]);
    setFinalizedEmotions(newFinalizedEmotions);
    setStrokes(strokes + 1);
  };

  const check = () => {
    let newFinalizedEmotions = finalizedEmotions;

    if (
      openedEmotions[0].name == openedEmotions[1].name &&
      openedEmotions[0].index != openedEmotions[1].index
    ) {
      finalizedEmotions[openedEmotions[0].index].complete = true;
      finalizedEmotions[openedEmotions[1].index].complete = true;

      setPairFound([...pairFound, openedEmotions]);
    } else {
      finalizedEmotions[openedEmotions[0].index].close = true;
      finalizedEmotions[openedEmotions[1].index].close = true;
    }

    setFinalizedEmotions(newFinalizedEmotions);
    setOpenedEmotions([]);
  };
  const start = (emotions) => {
    let newFinalizedEmotions = [];

    setNumberOfPairTotal(emotions.length);

    setDuplicatedEmotions(emotions.concat(emotions));
    // duplicatedEmotions = emotions.concat(emotions);

    setRandomizedEmotions(shuffle(emotions.concat(emotions)));
    // randomizedEmotions = shuffle(duplicatedEmotions);

    shuffle(emotions.concat(emotions)).map((name, index) => {
      newFinalizedEmotions.push({
        name,
        close: true,
        complete: false,
        fail: false,
      });
    });

    setFinalizedEmotions(newFinalizedEmotions);
  };
  const shuffle = (array) => {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  };

  const handleDuration = () => {
    const durationNumber = Number(timer);
    const hour = Math.floor(durationNumber / 3600);
    const minute = Math.floor((durationNumber % 3600) / 60);
    const seconds = Math.floor((durationNumber % 3600) % 60);

    const hDisplay = hour > 0 ? hour + 'H' : '';
    const mDisplay = minute > 0 ? minute + 'm' : '';
    const sDisplay = seconds > 0 ? seconds + 's' : '';
    return hDisplay + mDisplay + sDisplay;
  };
  const numberOfPairFound = pairFound.length;

  const stopGame = () => {
    const textModal = Parser(currentGame.title_win);
    const textModalButton = 'Suivant';
    const type = 'win';
    const action = finishGame;
    const icon = iconWin;
    const descriptionModal = Parser(currentGame.text_win);

    const content = {
      icon,
      textModal,
      textModalButton,
      type,
      action,
      descriptionModal,
    };

    openModal(content);
  };

  const finishGame = () => {
    closeModal();
    checkGameAndChallenge(strokes, timer);
  };

  useEffect(() => {
    if (numberOfPairFound === numberOfPairTotal && numberOfPairTotal !== 0) {
      setStopCounter(true);
    }
  }, [numberOfPairFound]);

  return (
    <div className="Memory">
      <div className="Memory_header">
        <p>
          {numberOfPairFound} / {numberOfPairTotal}
        </p>
        <p>{handleDuration()}</p>
        <p>{strokes !== 0 && `${strokes} coup${strokes > 1 ? 's' : ''}`}</p>
      </div>
      <div className="playground">
        {finalizedEmotions.map((emotion, index) => {
          return (
            <Card
              emotion={emotion.name}
              click={() => {
                handleClick(emotion.name, index);
              }}
              close={emotion.close}
              complete={emotion.complete}
              openedEmotions={openedEmotions}
            />
          );
        })}
      </div>
      {modal.isOpen && (
        <Modal
          buttonText={buttonTextModal}
          actionModal={actionModal}
          image={imageModal}
        />
      )}
      {numberOfPairFound === numberOfPairTotal && (
        <Button onClick={() => stopGame()} label="Terminer" primary />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    currentEmotion: state.emotions.currentEmotion,
    modal: state.modal,
    buttonTextModal: state.modal.buttonText,
    actionModal: state.modal.buttonAction,
    imageModal: state.modal.image,
    currentGame: state.games.currentGame,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (data) => {
      dispatch(openModal(data));
    },
    closeModal: (data) => {
      dispatch(closeModal(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Memory);
