import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { IconContext } from 'react-icons';
import { FiUpload } from 'react-icons/fi';
import axios from 'axios';

import { updateUser } from '../actions/index';

import NavBar from '../NavBar';
import UpdateProfil from './UpdateProfil';

import Button from '../../stories/components/Button/Button';
import Alert from '../../stories/components/Alert/Alert';

import imageProfil from '../../styles/images/joie2.svg';

import '../../styles/css/Profil/Profil.css';

const Profil = ({ user, updateUser }) => {
  const history = useHistory();
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [goodMessage, setGoodMessage] = useState(null);

  const dataFile = new FormData();

  const handleDeconnexion = () => {
    localStorage.setItem('token', null);
    history.push('/');
  };

  const changeToken = (data) => {
    localStorage.setItem('token', data.token);
    updateUser(data.user);
  };
  const handleUploadPicture = (e) => {
    setSelectedFile(e.target.files[0]);

    const reader = new FileReader();

    reader.onloadend = () => {
      setPreviewFile(reader.result);
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleFile = () => {
    if (selectedFile) {
      dataFile.append('file', selectedFile);
      dataFile.append('upload_preset', process.env.REACT_APP_CLOUD_NAME);
      axios
        .post(process.env.REACT_APP_CLOUD_BASE_API_URL, dataFile)
        .then((response) =>
          axios
            .put(`${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}`, {
              picture_url: response.data.secure_url,
            })
            .then((response2) => changeToken(response2.data))
            .then(() => setGoodMessage('Votre photo de profil est mise à jour'))
        )
        .catch((error) =>
          setErrorMessage(
            "Votre photo de profil n'a pas pu être enregistré veuillez réassayer !"
          )
        );
    }
  };

  return (
    <div className="Profil">
      <NavBar title={`${user.firstname} ${user.lastname}`} />
      <div className="Profil_content">
        <div className="Profil_content-top">
          {user.picture_url ? (
            <img
              className="ListPetals_profil"
              src={previewFile || user.picture_url}
              alt="Photo de profil"
            />
          ) : (
            <div className="ListPetals_profil" />
          )}
          {user.role === 'admin' && (
            <Button
              onClick={() => history.push('/admin')}
              label="Admin"
              primary
            />
          )}

          <p>
            {user.firstname} {user.lastname}
          </p>
          {previewFile ? (
            <Button label="Valider" primary onClick={() => handleFile()} />
          ) : (
            <div className="UpdateProfil_form_input">
              <input
                type="file"
                name="file"
                id="file"
                className="UpdateProfil_form_input_file"
                onChange={(e) => handleUploadPicture(e)}
              />
              <label htmlFor="file">
                Choisir une photo
                <IconContext.Provider value={{ color: 'white' }}>
                  <FiUpload style={{ marginLeft: '10px' }} />
                </IconContext.Provider>
              </label>
            </div>
          )}
          {errorMessage && <Alert message={errorMessage} primary />}
          {goodMessage && <Alert message={goodMessage} />}
        </div>
        <div className="ListPetals_bar" />
        <UpdateProfil />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (data) => {
      dispatch(updateUser(data));
    },
  };
};

Profil.propTypes = {
  user: PropTypes.shape({}).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profil);
