import React, { useState } from 'react';

import Button from '../../../../stories/components/Button/Button';

import '../../../../styles/css/Games/Bot2/ExplainLose.css';

const ExplainLose = (props) => {
  const [inputs, setInputs] = useState();
  let firstText = null;
  let secondText = null;
  if (props.responses[5] === 1) {
    firstText = "Quand je parlerai de ce qui m'arrive à ";
    secondText = ', je dirais ';
  } else {
    firstText = "Si je retrouvais ce que j'ai perdu, je promets de ";
  }

  return (
    <div className="ExplainLose">
      <div>
        <p>{firstText}</p>
        <input
          className="ExplainLose_input"
          placholder="remplir"
          onChange={(e) => setInputs({ ...inputs, firstInput: e.target.value })}
        />
      </div>
      {secondText !== null && (
        <div>
          <p>{secondText}</p>
          <input
            className="ExplainLose_input"
            placeholer="remplir"
            onChange={(e) =>
              setInputs({ ...inputs, secondInput: e.target.value })
            }
          />
        </div>
      )}
      <Button
        label="Valider"
        primary
        onClick={() => props.actionProvider.handleDepression(inputs, 6)}
      />
    </div>
  );
};

export default ExplainLose;
