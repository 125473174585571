import React, { useState } from 'react';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import { connect } from 'react-redux';
import Parser from 'html-react-parser';

import { closeModal, openModal } from '../actions/index';

import Modal from '../../stories/components/Modal/Modal';
import Button from '../../stories/components/Button/Button';

import '../../styles/css/Games/Comfort.css';
import peur from '../../styles/images/peur.svg';
import middle from '../../styles/images/degout.svg';
import middle2 from '../../styles/images/anticipation.svg';
import happy from '../../styles/images/joie2.svg';
import iconWin from '../../styles/images/modal-win.svg';

const listOfQuokka = [peur, middle, middle2, happy];

const Comfort = ({
  checkGameAndChallenge,
  modal,
  buttonTextModal,
  actionModal,
  imageModal,
  currentGame,
  closeModal,
  openModal,
}) => {
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [sadness, setSadness] = useState(0);
  const [isTalking, setIsTalking] = useState(false);

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return null;
  }

  const handleTalk = () => {
    SpeechRecognition.startListening();
    setIsTalking(!isTalking);
  };

  const sendTalk = () => {
    setSadness(sadness + 1);
    setIsTalking(!isTalking);
    resetTranscript();
  };

  const handleRestart = () => {
    resetTranscript();
    SpeechRecognition.startListening();
  };

  const stopGame = () => {
    const textModal = Parser(currentGame.title_win);
    const textModalButton = 'Suivant';
    const type = 'win';
    const action = finishGame;
    const icon = iconWin;
    const descriptionModal = Parser(currentGame.text_win);

    const content = {
      icon,
      textModal,
      textModalButton,
      type,
      action,
      descriptionModal,
    };

    openModal(content);
  };

  const finishGame = () => {
    closeModal();
    checkGameAndChallenge();
  };

  return (
    <div className="Comfort">
      <p>Réconforter Quokka</p>
      <div className="Comfort_quokka">
        {listOfQuokka
          .filter((urlImage, index) => index === sadness)
          .map((urlImage, index) => (
            <img src={urlImage} alt={`images-quokka-sad-${index}`} />
          ))}
      </div>
      <p>{transcript}</p>

      <div className="Comfort_buttons">
        {!isTalking && sadness !== 3 && (
          <Button
            type="button"
            primary
            label="Discuter avec Quokka"
            onClick={() => handleTalk()}
          />
        )}
        {isTalking && (
          <Button
            type="button"
            primary
            label="Recommencer"
            onClick={() => handleRestart()}
          />
        )}
        {transcript && (
          <Button
            type="button"
            primary
            label="Je lui envoie"
            onClick={() => sendTalk()}
          />
        )}
      </div>
      {sadness === 3 ? '' : <p>Tu peux utiliser ton micro*</p>}
      {modal.isOpen && (
        <Modal
          buttonText={buttonTextModal}
          actionModal={actionModal}
          image={imageModal}
        />
      )}
      {sadness === 3 && (
        <Button
          type="button"
          primary
          label="C'est fini"
          onClick={() => stopGame()}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
    currentEmotion: state.emotions.currentEmotion,
    buttonTextModal: state.modal.buttonText,
    actionModal: state.modal.buttonAction,
    imageModal: state.modal.image,
    currentGame: state.games.currentGame,
  };
};

const mapDispatchProps = (dispatch) => {
  return {
    openModal: (data) => {
      dispatch(openModal(data));
    },
    closeModal: (data) => {
      dispatch(closeModal(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Comfort);
