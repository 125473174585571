const initialState = {
  allEmotions: [
    {
      name: 'Tristesse',
      slug: 'tristesse',
      imageUrl:
        'https://res.cloudinary.com/hu2cixefb/image/upload/v1618596079/images/depositphotos_264383222-stock-illustration-a-vector-quokka-illustration-style_1_s4y4fz.jpg',
    },
  ],
  currentEmotion: null,
  loading: true,
  error: null,
};

const User = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_EMOTIONS':
      return {
        ...state,
        loading: true,
      };
    case 'ADD_EMOTIONS':
      return {
        ...state,
        allEmotions: action.payload,
        loading: false,
      };
    case 'ERROR_EMOTIONS':
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case 'CURRENT_EMOTION':
      return {
        ...state,
        currentEmotion: action.payload,
      };
      case 'RESET_CURRENT_EMOTION':
        return {
          ...state,
          currentEmotion: null,
        };
    default:
      return state;
  }
};

export default User;
