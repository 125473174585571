import React from 'react';
import { connect } from 'react-redux';

import joie from '../../styles/images/joie.svg';
import temperature from '../../styles/images/emotionsDebrief/fear/temperature.svg';
import zen from '../../styles/images/emotionsDebrief/surprise/zen.svg';
import spider from '../../styles/images/emotionsDebrief/surprise/afraid.svg';
import breath from '../../styles/images/emotionsDebrief/surprise/breath.svg';
import elio from '../../styles/images/emotionsDebrief/surprise/elio.png';
import heart from '../../styles/images/emotionsDebrief/surprise/heart.svg';

import '../../styles/css/Emotions/Surprise.css';

const Surprise = ({ currentEmotion }) => {
  //   const dégout = '#8851a9';
  //   const colere = '#ef60a5';
  //   const anticipation = '##ff7d35';
  return (
    <div className="SurpriseEmotion">
      <div className="SurpriseEmotion_left">
        <img
          src={joie}
          alt="logoTricky"
          className="SurpriseEmotion_left-tricky"
        />
        <div className="SurpriseEmotion_left-description">
          <h3>LA SURPRISE</h3>
          <p>{currentEmotion.what_first} </p>
        </div>
        <img
          className="SurpriseEmotion_left-image"
          src={elio}
          alt="Elio SurpriseEmotion"
        />
      </div>
      <div className="SurpriseEmotion_right">
        <h3 className="SurpriseEmotion_right-title">GERER SA SURPRISE</h3>
        <div className="SurpriseEmotion_right-temperature">
          <img
            className="SurpriseEmotion_right-temperature-image"
            src={temperature}
            alt="temperature"
          />
          <div className="SurpriseEmotion_right-temperature-right">
            <p>Quelle est l'intensité de ta SurpriseEmotion ?</p>
            <p className="SurpriseEmotion_right-temperature-numbers">
              0 1 2 3 4 5
            </p>
          </div>
        </div>
        <div className="SurpriseEmotion_right-surprise">
          <div className="SurpriseEmotion_right-surprise-content">
            <p className="SurpriseEmotion_right-surprise-content-title">
              Bonne ou mauvaise surprise ?
            </p>
            <p className="SurpriseEmotion_right-surprise-content-text">
              La SurpriseEmotion est émotion de courte durée qui disparaît,
              souvent au profit d'une autre émotion comme la tristesse ou à
              l'inverse la joie
            </p>
          </div>
          <img src={spider} alt="surprise" />
        </div>
        <div className="SurpriseEmotion_right-question">
          <p className="SurpriseEmotion_right-question-title">
            Quand je suis surpris.e :
          </p>
          <div className="SurpriseEmotion_right-question-content">
            <div className="SurpriseEmotion_right-question-content-choice">
              <img src={heart} alt="rytme cardiaque" />
              <p className="SurpriseEmotion_right-question-content-choice-text ">
                Mon rythme cardiaque s'accélère
              </p>
            </div>
            <div className="SurpriseEmotion_right-question-content-choice">
              <img src={breath} alt="Souffle coupé" />
              <p className="SurpriseEmotion_right-question-content-choice-text">
                J'ai le souffle coupé
              </p>
            </div>
          </div>
        </div>
        <div className="SurpriseEmotion_right-text">
          <p className="SurpriseEmotion_right-text-title">
            Pas tous identiques
          </p>
          <p className="SurpriseEmotion_right-text-text">
            Nous ne réagissons pas tous de la même manière face à une situation
            inhabituelle. En effet, l'état de surprise est subjectif et propre à
            chacun, car il est en lien avec les expériences de vie de chacun.
          </p>
        </div>
        <div className="SurpriseEmotion_right-introspection">
          <div className="SurpriseEmotion_right-introspection-content">
            <p className="SurpriseEmotion_right-introspection-content-title">
              Travail d'introspection
            </p>
            <p className="SurpriseEmotion_right-introspection-content-text">
              Tu pourras comprendre et analyser rapidement ce que tu as
              ressenti. De cette façon, l'émotion sera moins immédiate. Elle
              pourra être traitée plus facilement et produira moins d'effets à
              l'avenir.
            </p>
          </div>
          <img src={zen} alt="Travail d'introspection" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentEmotion: state.emotions.currentEmotion,
  };
};

export default connect(mapStateToProps, null)(Surprise);
