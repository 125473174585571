import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/css/link.css';

/**
 * Primary UI component for user interaction
 */
export const Link = ({ url, label, primary, blank, ...props }) => {
  const mode = primary
    ? 'storybook-link--primary'
    : 'storybook-link--secondary';
  return (
    <a
      className={`storybook-link ${mode}`}
      target={blank ? '_blank' : '_self'}
      href={url}
      {...props}
    >
      {label}
    </a>
  );
};

Link.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  primary: PropTypes.bool,
  /**
   * Link contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Link url
   */
  url: PropTypes.string.isRequired,
  /**
   * Optional Link target
   */
  target: PropTypes.string.isRequired,
};

Link.defaultProps = {
  primary: false,
  url: '/',
  label: 'Lien',
  target: '_self',
};

export default Link;
