const initialState = {
  allPetals: [],
  currentPetal: null,
  petalsOfUser: [],
  subPetals: [],
  error: {},
};

const Petals = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_PETALS':
      return {
        ...state,
        loading: true,
      };
    case 'ADD_PETALS':
      return {
        ...state,
        allPetals: action.payload,
        loading: false,
      };
    case 'FETCH_USER_PETALS':
      return {
        ...state,
        loading: true,
      };
    case 'ADD_USER_PETALS':
      return {
        ...state,
        petalsOfUser: action.payload,
        loading: false,
      };
    case 'ERROR_PETALS':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case 'FETCH_CURRENT_PETAL':
      return {
        ...state,
        loading: true,
      };
    case 'ADD_CURRENT_PETAL':
      return {
        ...state,
        currentPetal: action.payload,
        loading: false,
      };
    case 'FETCH_SUBPETALS':
      return {
        ...state,
        loading: true,
      };
    case 'ADD_SUBPETALS':
      return {
        ...state,
        subPetals: action.payload,
        loading: false,
      };
    case 'RESET_CURRENT_PETAL':
      return {
        ...state,
        currentPetal: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default Petals;
