import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import ReactLoading from 'react-loading';
import { useHistory } from 'react-router-dom';

import {
  fetchEmotions,
  addResponseFeeling,
  currentEmotion,
  addEmotions,
  errorEmotions,
  changeStep,
} from '../actions/index';

import Button from '../../stories/components/Button/Button';
import Box from '../../stories/components/Content/Box';
import Alert from '../../stories/components/Alert/Alert';

import '../../styles/css/Questions/QuestionFeeling.css';

const QuestionFeeling = ({
  gotoNext,
  challenge,
  user,
  fetchEmotions,
  emotions,
  addResponseFeeling,
  currentEmotion,
  addEmotions,
  errorEmotions,
  loading,
  currentChallenge,
  changeStep,
}) => {
  const [emotionChecked, setEmotionChecked] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const history = useHistory();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL_SERVER}/api/emotions`)
      .then((response) => response.data)
      .then((data) => {
        fetchEmotions();
        addEmotions(data);
      })
      .catch((error) => errorEmotions(error));
  }, []);

  const handleEmotion = () => {
    if (emotionChecked !== null) {
      axios
        .put(
          `${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}/challenges/${challenge.challenge_id}`,
          { response_feeling: emotionChecked, step: 1 }
        )
        .then(() => handleCurrentEmotion())
        .catch((err) =>
          setErrorMessage(
            'Un soucis lors de la validation de votre émotion, veuillez réésayer !'
          )
        );
    } else {
      setErrorMessage('Veuillez cliquer sur une émotion');
    }
  };

  const handleCurrentEmotion = () => {
    axios
      .get(`${process.env.REACT_APP_URL_SERVER}/api/emotions/${emotionChecked}`)
      .then((response) => response.data)
      .then((data) => currentEmotion(data));
    addResponseFeeling(emotionChecked);
  };

  const chooseEmotion = (id) => {
    setErrorMessage(null);
    setEmotionChecked(id);
  };

  const handleGoBack = () => {
    if (currentChallenge.step === 0) {
      history.push('/challenges');
    } else {
      changeStep(currentChallenge.step - 1);
    }
  };

  return (
    <div className="QuestionFeeling">
      <Box
        primary
        text={challenge.situation}
        type="question"
        secondText={challenge.protagonist}
      />
      <div className="QuestionFeeling_content">
        <p className="QuestionFeeling_title">
          Dans cette situation je ressens :
        </p>
        <div className="QuestionFeeling_emotions">
          {!loading ? (
            emotions.map((emotion) => (
              <div>
                <Button
                  type="image"
                  primary
                  imageUrl={emotion.imageUrl}
                  text={emotion.slug}
                  onClick={() => chooseEmotion(emotion.id)}
                  isChecked={emotionChecked === emotion.id}
                />
                <p>{emotion.name}</p>
              </div>
            ))
          ) : (
            <ReactLoading
              type="bubbles"
              color="white"
              height={'10%'}
              width={'10%'}
            />
          )}
        </div>
      </div>
      {errorMessage && <Alert message={errorMessage} primary />}
      <div className="QuestionFeeling_emotions-buttons">
        <Button primary onClick={() => handleGoBack()} label="Précédent" />
        <Button label="Valider" primary onClick={() => handleEmotion()} />
      </div>
    </div>
  );
};

QuestionFeeling.propTypes = {
  gotoNext: PropTypes.func.isRequired,
  challenge: PropTypes.shape({
    title: PropTypes.string,
    situation: PropTypes.string,
    protagonist: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEmotions: () => {
      dispatch(fetchEmotions());
    },
    addEmotions: (data) => {
      dispatch(addEmotions(data));
    },
    errorEmotions: (data) => {
      dispatch(errorEmotions(data));
    },
    addResponseFeeling: (data) => {
      dispatch(addResponseFeeling(data));
    },
    currentEmotion: (data) => {
      dispatch(currentEmotion(data));
    },
    changeStep: (data) => {
      dispatch(changeStep(data));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    emotions: state.emotions.allEmotions,
    loading: state.emotions.loading,
    currentChallenge: state.challenges.currentChallenge,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionFeeling);
