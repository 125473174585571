import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FirstConnexion from './FirstConnexion';
import NavBar from './NavBar';
import Challenges from './Challenges/Challenges';

import '../styles/css/Home.css';

const Home = ({ user }) => {
  if (user.firstconnexion == '0') {
    return <FirstConnexion />;
  }

  return (
    <div className="Home">
      <NavBar title="Mes challenges" />
      <Challenges />
    </div>
  );
};

Home.propTypes = {
  user: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(Home);
