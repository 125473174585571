import React from 'react';
import { GiCheckMark } from 'react-icons/gi';
import { IconContext } from 'react-icons';
import { useHistory } from 'react-router-dom';

import Intro from './Intro';
import Button from '../../stories/components/Button/Button';

import '../../styles/css/Auth/SignupSuccess.css';

const ForgetPasswordSuccess = () => {
  const history = useHistory();
  return (
    <div className="SignupSuccess">
      <Intro />
      <div className="SignupSuccess_content">
        <IconContext.Provider value={{ color: '#DA2D64' }}>
          <GiCheckMark />
        </IconContext.Provider>
        <h3 className="SignupSuccess_content-title">Email envoyé</h3>
        <p className="SignupSuccess_content-description">
          Un mail de réinitialisation de mot de passe a été envoyé à l'adresse
          renseignée. Veuillez cliquer sur le lien contenu dans cet email pour
          valider votre compte
        </p>
        <Button
          primary
          label="Connectez-vous"
          onClick={() => history.push('/')}
        />
      </div>
    </div>
  );
};

export default ForgetPasswordSuccess;
