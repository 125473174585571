import React, { useState } from 'react';
import { connect } from 'react-redux';
import Parser from 'html-react-parser';

import { closeModal, openModal } from '../actions/index';

import Button from '../../stories/components/Button/Button';
import Modal from '../../stories/components/Modal/Modal';
import iconWin from '../../styles/images/modal-win.svg';

import '../../styles/css/Games/Surprise.css';

const Surprise = ({
  currentGame,
  checkGameAndChallenge,
  modal,
  openModal,
  buttonTextModal,
  actionModal,
  imageModal,
  closeModal,
}) => {
  const [isOpenedBox, setIsOpenedBox] = useState(false);

  const handleEmoji = () => {
    let treatEmojis = [
      '🍭',
      '🍫',
      '🍬',
      '🍪',
      '🍩',
      '💌',
      '🍔',
      '🎉',
      '🍻',
      '💣',
    ];

    const emojiChoose =
      treatEmojis[Math.floor(Math.random() * treatEmojis.length)];

    return emojiChoose;
  };

  const stopGame = () => {
    const textModal = Parser(currentGame.title_win);
    const textModalButton = 'Suivant';
    const type = 'win';
    const action = finishGame;
    const icon = iconWin;
    const descriptionModal = Parser(currentGame.text_win);

    const content = {
      icon,
      textModal,
      textModalButton,
      type,
      action,
      descriptionModal,
    };

    openModal(content);
  };

  const finishGame = () => {
    closeModal();
    checkGameAndChallenge();
  };

  return (
    <div className="Surprise">
      <div
        onClick={() => setIsOpenedBox(!isOpenedBox)}
        className={`box ${isOpenedBox && 'box-open'}`}
      >
        <div className="box-side box-side-front">
          {!isOpenedBox && <span>Ouvre moi !</span>}
        </div>
        <div className="box-side box-side-back"></div>
        <div className="box-side box-side-left"></div>
        <div className="box-side box-side-right"></div>
        <div className="box-side box-side-top"></div>
        <div className="box-side box-side-bottom"></div>
        <div className="emoji">{handleEmoji()}</div>
      </div>
      {modal.isOpen && (
        <Modal
          buttonText={buttonTextModal}
          actionModal={actionModal}
          image={imageModal}
        />
      )}
      <div className="Surprise_button">
        {isOpenedBox && (
          <Button onClick={() => stopGame()} label="Suivant" primary />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentGame: state.games.currentGame,
    modal: state.modal,
    buttonTextModal: state.modal.buttonText,
    actionModal: state.modal.buttonAction,
    imageModal: state.modal.image,
  };
};

const mapDispatchProps = (dispatch) => {
  return {
    openModal: (data) => {
      dispatch(openModal(data));
    },
    closeModal: (data) => {
      dispatch(closeModal(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Surprise);
