import React from 'react';
import PropTypes from 'prop-types';
import { IconContext } from 'react-icons';
import { ImEye, ImEyeBlocked } from 'react-icons/im';

import InputRadio from './InputRadio';

import '../../styles/css/input.css';

/**
 * Primary UI component for user interaction
 */
export const Input = ({
  primary,
  backgroundColor,
  size,
  label,
  name,
  required,
  type,
  checked,
  colorEye,
  ...props
}) => {
  const mode = primary
    ? 'storybook-input--primary'
    : 'storybook-input--secondary';
  if (type === 'radio') {
    return (
      <InputRadio
        {...props}
        primary={primary}
        backgroundColor={backgroundColor}
        size={size}
        label={label}
        name={name}
        required={required}
        checked={checked}
        type={type}
      />
    );
  }

  return (
    <div className="storybook-input-content">
      {label && (
        <label className="storybook-label" htmlFor={name}>
          {label}
        </label>
      )}
      <div className="storybook-input-content-input">
        <input
          className={`storybook-input storybook-input--${size} ${mode}`}
          type={type}
          id={name}
          name={name}
          value={label}
          required
          {...props}
        />
        {name === 'password' && (
          <button
            className="not-button"
            onClick={() => props.setShowPassword()}
          >
            <IconContext.Provider value={{ color: colorEye }}>
              {type === 'text' ? <ImEye /> : <ImEyeBlocked />}
            </IconContext.Provider>
          </button>
        )}
      </div>
    </div>
  );
};

Input.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  primary: PropTypes.bool,
  /**
   * What background color to use
   */
  backgroundColor: PropTypes.string,
  /**
   * How large should the input be?
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Input contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Input identification
   */
  name: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Input types
   */
  type: PropTypes.oneOf(['text', 'number', 'email', 'password']),
  /**
   * Input is required or not
   */
  required: PropTypes.bool,
};

Input.defaultProps = {
  backgroundColor: null,
  primary: false,
  size: 'medium',
  onClick: undefined,
  onChange: undefined,
  type: 'text',
  required: false,
  colorEye: 'white',
};

export default Input;
