import React from 'react';

import '../../styles/css/modal/modal.css';
import Button from '../Button/Button';

/**
 * Primary UI component for user interaction
 */
export const Modal = ({
  textModal,
  buttonText,
  actionModal,
  iconModal,
  type,
  image,
  descriptionModal,
}) => {
  const handleCloseModal = () => {
    if (actionModal) {
      actionModal();
    }
  };

  return (
    <div className="storybook-modal">
      {iconModal && <img src={iconModal} alt={textModal} />}
      {textModal && <p className="storybook-modal-text">{textModal}</p>}
      {descriptionModal && (
        <p className="storybook-modal-description">{descriptionModal}</p>
      )}
      {image && (
        <img className="storybook-modal-image" src={image} alt="image-modal" />
      )}
      {buttonText && (
        <Button primary label={buttonText} onClick={() => handleCloseModal()} />
      )}
    </div>
  );
};

export default Modal;
