// MessageParser starter code
class MessageParser {
  constructor(actionProvider, state) {
    this.actionProvider = actionProvider;
    this.state = state;
  }

  parse(message) {
    const lowercase = message.toLowerCase();

    if (lowercase.includes('hello')) {
      this.actionProvider.helloWorldHandler();
    }

    if (lowercase.includes('todos')) {
      this.actionProvider.handleTodos();
    }
    if (this.state.responses && this.state.responses[7]) {
      this.actionProvider.handleResponseFeeling(message, 8);
    }
  }
}

export default MessageParser;
