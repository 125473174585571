import React from 'react';
import Chatbot from 'react-chatbot-kit';
import { connect } from 'react-redux';
import Parser from 'html-react-parser';

import MessageParser from './MessageParser';
import ActionProvider from './ActionProvider';
import config from './config';

import { closeModal, openModal } from '../../actions/index';

import Modal from '../../../stories/components/Modal/Modal';
import Button from '../../../stories/components/Button/Button';

import iconWin from '../../../styles/images/modal-win.svg';

import '../../../styles/css/Games/Bot2/Bot2.css';

const Bot2 = ({
  checkGameAndChallenge,
  modal,
  buttonTextModal,
  actionModal,
  imageModal,
  closeModal,
  openModal,
  currentGame,
}) => {
  const stopGame = () => {
    const textModal = Parser(currentGame.title_win);
    const textModalButton = 'Suivant';
    const type = 'win';
    const action = finishGame;
    const icon = iconWin;
    const descriptionModal = Parser(currentGame.text_win);

    const content = {
      icon,
      textModal,
      textModalButton,
      type,
      action,
      descriptionModal,
    };

    openModal(content);
  };

  const finishGame = () => {
    closeModal();
    checkGameAndChallenge();
  };
  return (
    <div className="Bot2">
      <Chatbot
        config={config}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
      />
      <Button label="Suivant" primary onClick={() => stopGame()} />
      {modal.isOpen && (
        <Modal
          buttonText={buttonTextModal}
          actionModal={actionModal}
          image={imageModal}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
    buttonTextModal: state.modal.buttonText,
    actionModal: state.modal.buttonAction,
    imageModal: state.modal.image,
    currentGame: state.games.currentGame,
  };
};

const mapDispatchProps = (dispatch) => {
  return {
    closeModal: (data) => {
      dispatch(closeModal(data));
    },
    openModal: (data) => {
      dispatch(openModal(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Bot2);
