import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
// import $ from 'jquery';

import Intro from './Intro';

import Input from '../../stories/components/Input/Input';
import Button from '../../stories/components/Button/Button';
import Link from '../../stories/components/Link/Link';
import Alert from '../../stories/components/Alert/Alert';

import '../../styles/css/Auth/Signup.css';

const Signup = () => {
  const [inputs, setInputs] = useState({
    email: '',
    password: '',
    firstname: '',
    lastname: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  const handleSignUp = () => {
    setErrorMessage('');
    axios
      .post(`${process.env.REACT_APP_URL_SERVER}/api/users`, inputs)
      .then((data) => history.push('/inscription-valide'))
      .catch((error) => {
        const errors = Object.assign({}, error).response.data.errors;
        if (errors) {
          errors.map((error) => setErrorMessage(error.msg));
        } else {
          setErrorMessage(
            "Nous n'avons pas pu créer votre compte, si vous avez déjà un compte veuillez vous connectez."
          );
        }
      });
  };

  return (
    <div className="Signup">
      <Intro />
      <div className="Signin_content">
        <div className="Signin_content-top">
          <h2 className="Signin_content-title">Créez votre compte</h2>
          <p className="Signin_content-description">
            Vous possédez déjà un compte ?
          </p>
          <Link url="/" label="Connectez-vous" />
        </div>
        <div className="Signin_form">
          <div className="Signin_form-inputs">
            <Input
              style={{
                borderColor: errorMessage && '#C02514',
                color: errorMessage && '#C02514',
              }}
              type="firstname"
              label="Firstname"
              value={inputs.firstname}
              name="firstname"
              required
              placeholder="Jean"
              onChange={(e) =>
                setInputs({ ...inputs, [e.target.name]: e.target.value })
              }
            />
            <Input
              style={{
                borderColor: errorMessage && '#C02514',
                color: errorMessage && '#C02514',
              }}
              type="lastname"
              label="Lastname"
              value={inputs.lastname}
              name="lastname"
              required
              placeholder="Dupont"
              onChange={(e) =>
                setInputs({ ...inputs, [e.target.name]: e.target.value })
              }
            />
            <Input
              style={{
                borderColor: errorMessage && '#C02514',
                color: errorMessage && '#C02514',
              }}
              type="email"
              label="Email"
              value={inputs.email}
              name="email"
              required
              placeholder="exemple@email.com"
              onChange={(e) =>
                setInputs({ ...inputs, [e.target.name]: e.target.value })
              }
            />
            <Input
              style={{
                borderColor: errorMessage && '#C02514',
                color: errorMessage && '#C02514',
              }}
              type={showPassword ? 'text' : 'password'}
              label="Mot de passe"
              value={inputs.password}
              name="password"
              required
              placeholder="Plus de 5 caractères"
              setShowPassword={() => setShowPassword(!showPassword)}
              colorEye="grey"
              required
              onChange={(e) =>
                setInputs({ ...inputs, [e.target.name]: e.target.value })
              }
            />
            {errorMessage && <Alert message={errorMessage} primary />}
          </div>
          <Button primary label="Je m'inscris" onClick={() => handleSignUp()} />
        </div>
      </div>
    </div>
  );
};

export default Signup;
