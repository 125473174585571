import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  currentChallenge as currentChallengeAction,
  fetchAllChallenges,
  addAllChallenges,
  errorAllChallenges,
  updateUser,
} from '../actions/index';

import '../../styles/css/Challenges/Challenge.css';

import heartEmptyImage from '../../styles/images/heart-empty.svg';
import heartImage from '../../styles/images/heart.svg';
import heartGreyImage from '../../styles/images/heart-grey.svg';
import lockImage from '../../styles/images/lock.svg';
import axios from 'axios';

const Challenge = ({
  challenge,
  currentChallenge,
  index,
  allChallenges,
  type,
  user,
  fetchAllChallenges,
  addAllChallenges,
  errorAllChallenges,
  updateUser,
}) => {

  const { name, color, buy, number_try_choice, points } = challenge;
  const history = useHistory();

  const chooseChallenge = () => {
    currentChallenge(challenge);
    history.push({
      state: { challenge },
      pathname: `/challenges/${challenge.id}/questions`,
    });
  };

  const arrayOfHearts = [1, 2, 3, 4];

  const handleHeartsImage = (heart) => {
    if (number_try_choice !== null) {
      if (heart <= number_try_choice + 1) {
        return heartImage;
      } else {
        return heartEmptyImage;
      }
    } else {
      return heartGreyImage;
    }
  };

  const [nameType, setNameType] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL_SERVER}/api/types/${user.type}`)
      .then((response) => setNameType(response.data.name));
  }, []);

  const challengeUserFinish =
    allChallenges &&
    allChallenges[user.type] &&
    allChallenges[user.type].find((challenge) => challenge.validate !== 1);

  const handleMessageToBuy = () => {
    if (user.type !== challenge.id_type) {
      if (challengeUserFinish) {
        return <p>Terminer le parcours {nameType}</p>;
      } else {
        return <p>{handlePoints()} coeurs pour débloquer</p>;
      }
    } else {
      return <p>{points} coeurs pour débloquer</p>;
    }
  };

  const handlePoints = () => {
    if (index == '0') {
      return 2;
    } else {
      return 2 + index * 2;
    }
  };

  const unLockChallenge = () => {
    if (challenge.points <= user.points) {
      axios
        .post(
          `${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}/challenges/${challenge.id}`
        )
        .then((response) => {
          const newPoints = user.points - challenge.points;
          axios
            .get(
              `${process.env.REACT_APP_URL_SERVER}/api/types/${type}/challenges`
            )
            .then((response) => {
              axios
                .get(
                  `${process.env.REACT_APP_URL_SERVER}/api/usersChallenges/${user.id}`
                )
                .then((response2) => {
                  const newData = response.data.map((challenge) => {
                    var haveEqualId = (userChallenge) =>
                      userChallenge.challenge_id === challenge.id;
                    var userWithEqualId = response2.data.find(haveEqualId);
                    return Object.assign({}, challenge, userWithEqualId);
                  });
                  fetchAllChallenges();
                  addAllChallenges(type, newData);
                })
                .catch((error) => errorAllChallenges(error));
            });
          axios
            .put(`${process.env.REACT_APP_URL_SERVER}/api/users/${user.id}`, {
              points: newPoints,
            })
            .then((response) => updateUser(response.data.user));
        });
    }
  };

  const handleClickChallenge = () => {    if (buy == '1') {
      return chooseChallenge();
    } else {
      if (!challengeUserFinish || user.type === type) {
        return unLockChallenge();
      }
    }
  };

  return (
    <button
      className="Challenge"
      style={{
        backgroundColor: buy == '1' ? color : 'rgba(0, 0, 0, 60%)',
      }}
      onClick={() => handleClickChallenge()}
      type="button"
      key={index}
    >
      {(buy == '0' || buy === undefined) && (
        <div className="Challenge_lock">
          <img src={lockImage} alt="challenge bloqué" />
          {handleMessageToBuy()}
        </div>
      )}

      <div
        style={{ opacity: buy == '0' || buy === undefined ? 0.3 : 1 }}
        className="Challenge_title"
      >
        <strong>{name}</strong>
      </div>
      <div
        style={{ opacity: buy == '0' || buy === undefined ? 0.3 : 1 }}
        className="Challenge_hearts"
      >
        {arrayOfHearts.map((heart) => (
          <img
            className="Challenge_heart"
            src={handleHeartsImage(heart)}
            alt="score"
          />
        ))}
      </div>
    </button>
  );
};

Challenge.propTypes = {
  challenge: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
    buy: PropTypes.bool,
    score: PropTypes.number,
    points: PropTypes.number,
  }).isRequired,
  currentChallenge: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
  return {
    allChallenges: state.challenges.allChallenges,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    currentChallenge: (data) => {
      dispatch(currentChallengeAction(data));
    },
    fetchAllChallenges: (idType, idChallenge, data) => {
      dispatch(fetchAllChallenges(idType, idChallenge, data));
    },
    addAllChallenges: (idType, data) => {
      dispatch(addAllChallenges(idType, data));
    },
    errorAllChallenges: (data) => {
      dispatch(errorAllChallenges(data));
    },
    updateUser: (data) => {
      dispatch(updateUser(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Challenge);
