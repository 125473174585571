import React from 'react';
import { connect } from 'react-redux';

import joie from '../../styles/images/joie.svg';
import temperature from '../../styles/images/emotionsDebrief/trust/temperature.svg';
import clap from '../../styles/images/emotionsDebrief/trust/clap.svg';
import question from '../../styles/images/emotionsDebrief/trust/question.svg';
import elio from '../../styles/images/emotionsDebrief/trust/elio.png';
import man from '../../styles/images/emotionsDebrief/trust/man.svg';
import theater from '../../styles/images/emotionsDebrief/trust/theater.svg';
import skills from '../../styles/images/emotionsDebrief/trust/jungle.svg';

import '../../styles/css/Emotions/Trust.css';

const Trust = ({ currentEmotion }) => {
  return (
    <div className="Trust">
      <div className="Trust_left">
        <img src={joie} alt="logoTricky" className="Trust_left-tricky" />
        <div className="Trust_left-description">
          <h3>LA CONFIANCE</h3>
          <p>{currentEmotion.what_first} </p>
        </div>
        <img className="Trust_left-image" src={elio} alt="Elio tristesse" />
      </div>
      <div className="Trust_right">
        <h3 className="Trust_right-title">CULTIVER SA CONFIANCE</h3>
        <div className="Trust_right-temperature">
          <img
            className="Trust_right-temperature-image"
            src={temperature}
            alt="temperature"
          />
          <div className="Trust_right-temperature-right">
            <p>Quelle est l'intensité de ta confiance ?</p>
            <p className="Trust_right-temperature-numbers">0 1 2 3 4 5</p>
          </div>
        </div>
        <div className="Trust_right-softskill">
          <div className="Trust_right-softskill-description">
            <p className="Trust_right-softskill-description-title">
              Mettre en avant ses soft skills
            </p>
            <p>Tu n'es pas arrivé.e là par hasard.</p>
            <p>Tu as des ressources cachées.</p>
            <p>Montre-les à tes collègues.</p>
          </div>
          <img src={skills} alt="Soft skills" />
        </div>
        <div className="Trust_right-clap-question">
          <div className="Trust_right-clap">
            <img src={clap} alt="clap" />
            <p className="Trust_right-clap-text">
              La confiance en soi n'a rien à voir avec la frime.
            </p>
          </div>
          <div className="Trust-right-question">
            <p className="Trust_right-question-text">
              Je ne suis pas confiant.e parce que...
            </p>
            <img src={question} alt="question" />
          </div>
        </div>
        <div className="Trust-right-trust">
          <img src={man} alt="Confiance" />
          <div className="Trust-right-trust-description">
            <p className="Trust-right-trust-description-title">
              Cultiver sa confiance
            </p>
            <p>Prendre du recul et laisser à l'autre ce qui lui appartient :</p>
            <p className="Trust-right-trust-description-lorem">
              L'autre n'est pas moi.
            </p>
          </div>
        </div>
        <div className="Trust-right-signal">
          <p className="Trust-right-signal-title">
            Envoyer un signal de confiance à son cerveau
          </p>
          <p>
            Si votre cerveau influe sur notre corps, l'inverse est aussi vrai.
          </p>
          <div className="Trust-right-signal-content">
            <div className="Trust-right-signal-content-left">
              <p>Sourire et baisser les épaules, respirer.</p>
              <p>
                Debout, menton relevé, les mains sur les hanches, jambes
                écartées, les pieds bien au sol.
              </p>
              <p>
                Debout ou assis, sourire, regard vers le haut et bras en l'air
                formant un V (V de victoire).
              </p>
            </div>
            <img src={theater} alt="Théatre" />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentEmotion: state.emotions.currentEmotion,
  };
};

export default connect(mapStateToProps, null)(Trust);
